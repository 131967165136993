/*
 * Heaer Messages
 *
 * This contains all the text for the Heaer container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Heaer';

export default defineMessages({
  welcomeText: {
    id: `${scope}.welcomeText`,
    defaultMessage: 'Welcome to Actively',
  },
  eventHome: {
    id: `${scope}.eventHome`,
    defaultMessage: 'Events',
  },
  eventTeamList: {
    id: `${scope}.eventTeamList`,
    defaultMessage: 'Team list',
  },
  eventVolunteerList: {
    id: `${scope}.eventVolunteerList`,
    defaultMessage: 'Volunteer list',
  },
  eventMemberList: {
    id: `${scope}.eventMemberList`,
    defaultMessage: 'Walker list',
  },
  eventSupporterList: {
    id: `${scope}.eventSupporterList`,
    defaultMessage: 'Supporter list',
  },
  eventVolunteerDetail: {
    id: `${scope}.eventVolunteerDetail`,
    defaultMessage: 'Volunteer detail',
  },
  memberDetail: {
    id: `${scope}.memberDetail`,
    defaultMessage: 'Member detail',
  },
  profile: {
    id: `${scope}.profile`,
    defaultMessage: 'Profile',
  },
  logout: {
    id: `${scope}.logout`,
    defaultMessage: 'Logout',
  },
  logoutWarn: {
    id: `${scope}.logoutWarn`,
    defaultMessage: 'Do you want to logout?',
  },
  manageUser: {
    id: `${scope}.manageUser`,
    defaultMessage: 'Manage users',
  },
  bib: {
    id: `${scope}.bib`,
    defaultMessage: 'BIB',
  },
  confirmed: {
    id: `${scope}.confirmed`,
    defaultMessage: 'Confirmed',
  },
  dropped: {
    id: `${scope}.dropped`,
    defaultMessage: 'Dropped',
  },
  postponed: {
    id: `${scope}.postponed`,
    defaultMessage: 'Postponed',
  },
  updateTeamStatusSuccess: {
    id: `${scope}.updateTeamStatusSuccess`,
    defaultMessage: 'Team status is updated successfully',
  },
  switchRoleToAdmin: {
    id: `${scope}.switchRoleToAdmin`,
    defaultMessage: 'Switch to admin',
  },
  switchRoleToOnboarder: {
    id: `${scope}.switchRoleToOnboarder`,
    defaultMessage: 'Switch to onboarder',
  },
  only_male_participants: {
    id: `${scope}.only_male_participants`,
    defaultMessage: 'Only male participants',
  },
  age_under_12: {
    id: `${scope}.age_under_12`,
    defaultMessage: 'Under 12 years old',
  },
  age_above_90: {
    id: `${scope}.age_above_90`,
    defaultMessage: 'Over 90 years old',
  },
  company_project_without_coupon: {
    id: `${scope}.company_project_without_coupon`,
    defaultMessage: 'Company project but without registration coupon',
  },
  participants_with_duplicate_email: {
    id: `${scope}.participants_with_duplicate_email`,
    defaultMessage: 'Duplicate email address',
  },
  notifications: {
    id: `${scope}.notifications`,
    defaultMessage: 'Notifications',
  },
  globalDashboard: {
    id: `${scope}.globalDashboard`,
    defaultMessage: 'Annual Dashboard',
  },
});
