import { takeLatest, takeEvery, call, put } from 'redux-saga/effects';
import {
  createTeamGroupApi,
  deleteTeamGroupApi,
  getTeamGroupsApi,
  updateTeamGroupApi,
} from '../../api/team-group.api';

import {
  getTeamGroupsListError,
  getTeamGroupsListSuccess,
  deleteTeamGroupsError,
  deleteTeamGroupsSuccess,
  updateTeamGroupsSuccess,
  updateTeamGroupsError,
  createTeamGroupsError,
  createTeamGroupsSuccess,
  getTeamGroupsListAct,
  deleteTeamGroupsAct,
  updateTeamGroupsAct,
  createTeamGroupsAct,
} from './actions';
import { getErrorMessage } from '../../utils/error.util';
import { addMessage } from '../ToastMessagesManager/actions';
import messages from '../../components/TeamGroupList/messages';

function* fetchVolunteerGroups({ payload }) {
  try {
    const { data } = yield call(getTeamGroupsApi, payload.params);
    if (data) {
      yield put(getTeamGroupsListSuccess(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(getTeamGroupsListError(errMessage));
    yield put(addMessage({ message: errMessage, variant: 'error' }));
  }
}

function* deleteVolunteerGroups({ payload }) {
  const { id } = payload;

  try {
    const { data } = yield call(deleteTeamGroupApi, id);
    if (data) {
      yield put(deleteTeamGroupsSuccess({ id }));
      yield put(
        addMessage({ message: messages.deleteSuccess, variant: 'success' }),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(deleteTeamGroupsError(errMessage));
    yield put(addMessage({ message: errMessage, variant: 'error' }));
  }
}

function* updateVolunteerGroups({ payload }) {
  const { id } = payload;

  try {
    const { data } = yield call(updateTeamGroupApi, id, payload.data);
    if (data) {
      yield put(
        updateTeamGroupsSuccess({
          id,
          name: data.name,
          description: data.description,
          updatedAt: data.updatedAt,
        }),
      );
      yield put(
        addMessage({ message: messages.updateSuccess, variant: 'success' }),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(updateTeamGroupsError(errMessage));
    yield put(addMessage({ message: errMessage, variant: 'error' }));
  }
}

function* createVolunteerGroups({ payload }) {
  try {
    const { data } = yield call(createTeamGroupApi, payload.data);
    if (data) {
      yield put(createTeamGroupsSuccess(data));
      yield put(
        addMessage({ message: messages.createSuccess, variant: 'success' }),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(createTeamGroupsError(errMessage));
    yield put(addMessage({ message: errMessage, variant: 'error' }));
  }
}

// Individual exports for testing
export default function* teamGroupSaga() {
  //  uncomment this to setp default action
  yield takeLatest(getTeamGroupsListAct.toString(), fetchVolunteerGroups);
  yield takeEvery(deleteTeamGroupsAct.toString(), deleteVolunteerGroups);
  yield takeEvery(updateTeamGroupsAct.toString(), updateVolunteerGroups);
  yield takeEvery(createTeamGroupsAct.toString(), createVolunteerGroups);
}
