import BaseApiInstance from './base';
const BASE_URL = '/team-group';

export const getTeamGroupsApi = async params => {
  const response = await BaseApiInstance.get(`${BASE_URL}`, {
    params,
  });
  return response;
};

export const createTeamGroupApi = async data => {
  const response = await BaseApiInstance.post(`${BASE_URL}`, data);
  return response;
};

export const updateTeamGroupApi = async (id, data) => {
  const response = await BaseApiInstance.patch(`${BASE_URL}/${id}`, data);
  return response;
};

export const deleteTeamGroupApi = async id => {
  const response = await BaseApiInstance.delete(`${BASE_URL}/${id}`);
  return response;
};
