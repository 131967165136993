import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  assignGroupAct,
  assignGroupError,
  assignGroupSuccess,
  assignUserAct,
  assignUserErrorAct,
  assignUserSuccess,
  deleteVolunteerAct,
  deleteVolunteerErrorAct,
  deleteVolunteerSuccessAct,
  getVolunteerDetailAct,
  getVolunteerDetailError,
  getVolunteerDetailSuccess,
  updateLinkedVolunteerAct,
  updateLinkedVolunteerError,
  updateLinkedVolunteerSuccess,
  updateMissionStateAct,
  updateMissionStateError,
  updateMissionStateSuccess,
  updateParentalConsentStateAct,
  updateParentalConsentStateError,
  updateParentalConsentStateSuccess,
  updateTagStateAct,
  updateTagStateError,
  updateTagStateSuccess,
  updateVerifyLicenseStateAct,
  updateVerifyLicenseStateError,
  updateVerifyLicenseStateSuccess,
  updateVerifyStateAct,
  updateVerifyStateError,
  updateVerifyStateSuccess,
  updateVolunteerAct,
  updateVolunteerError,
  updateVolunteerSuccess,
} from './actions';
import {
  assignGroup,
  getVolunteerDetail,
  updateLicense,
  updateMission,
  updateTag,
  verifyVolunteer,
  assignUser,
  updateVolunteerDetail,
  updateParentalConsent,
  linkVolunteerApi,
  deleteVolunteerApi,
} from '../../api/volunteer.api';
import { addMessage } from '../ToastMessagesManager/actions';
import messages from '../../components/VolunteerDetail/messages';
import { getErrorMessage } from '../../utils/error.util';

// import { DEFAULT_ACTION } from './constants';

function* fetchVolunteerDetail({ payload }) {
  try {
    const { data } = yield call(getVolunteerDetail, payload);
    if (data) {
      yield put(getVolunteerDetailSuccess(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(addMessage({ message: errMessage, variant: 'error' }));
    yield put(getVolunteerDetailError(errMessage));
  }
}

function* updateMissionState({ payload }) {
  const { data: payloadData } = payload;
  try {
    const { data } = yield call(updateMission, payload);
    if (data) {
      yield put(updateMissionStateSuccess(data));
      yield put(
        addMessage({ message: messages.updateMisStateSuc, variant: 'success' }),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(addMessage({ message: errMessage, variant: 'error' }));
    yield put(
      updateMissionStateError({
        missionId: payloadData.missionId,
        isAssigned: !payloadData.isAssigned,
        error: errMessage,
      }),
    );
  }
}

function* updateTagState({ payload }) {
  const { data: payloadData } = payload;
  try {
    const { data } = yield call(updateTag, payload);
    if (data) {
      yield put(updateTagStateSuccess(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(addMessage({ message: errMessage, variant: 'error' }));
    yield put(
      updateTagStateError({
        tagId: payloadData.tagId,
        active: !payloadData.active,
        error: errMessage,
      }),
    );
  }
}

function* updateAssignedUser({ payload }) {
  const { metadata, id, data } = payload;
  try {
    const { data: result } = yield call(assignUser, id, data);
    if (result) {
      yield put(assignUserSuccess(result));
      yield put(
        addMessage({ message: messages.updateUserSuc, variant: 'success' }),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(addMessage({ message: errMessage, variant: 'error' }));
    yield put(assignUserErrorAct(metadata));
  }
}

function* updateAssignedGroup({ payload }) {
  const { metadata, id, data } = payload;
  try {
    const { data: result } = yield call(assignGroup, id, data);
    if (result) {
      yield put(assignGroupSuccess(result));
      // need to determine the assign or remove removeGrpSuc
      yield put(
        addMessage({ message: messages.updateGrpSuc, variant: 'success' }),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(addMessage({ message: errMessage, variant: 'error' }));
    yield put(assignGroupError(metadata));
  }
}

function* updateLicenseVerifyState({ payload }) {
  const { metadata, id, data } = payload;
  try {
    const { data: result } = yield call(updateLicense, id, data);
    if (result) {
      yield put(updateVerifyLicenseStateSuccess(result));
      yield put(
        addMessage({ message: messages.updateLicVerSuc, variant: 'success' }),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(addMessage({ message: errMessage, variant: 'error' }));
    yield put(updateVerifyLicenseStateError(metadata));
  }
}

function* updateParentalConsentState({ payload }) {
  const { metadata, id, data } = payload;

  try {
    const { data: result } = yield call(updateParentalConsent, id, data);
    if (result) {
      yield put(updateParentalConsentStateSuccess(result));
      yield put(
        addMessage({
          message: messages.updateParentConsentSuc,
          variant: 'success',
        }),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(addMessage({ message: errMessage, variant: 'error' }));
    yield put(updateParentalConsentStateError(metadata));
  }
}

function* updateVolunteerVerifyState({ payload }) {
  const { data, id, metadata } = payload;
  try {
    const { data: result } = yield call(verifyVolunteer, id, data);
    if (result) {
      yield put(updateVerifyStateSuccess(result));
      yield put(
        addMessage({
          message: messages.updateStatusSuc,
          variant: 'success',
        }),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(addMessage({ message: errMessage, variant: 'error' }));
    yield put(updateVerifyStateError(metadata));
  }
}

function* updateVolunteer({ payload }) {
  try {
    const { data } = yield call(updateVolunteerDetail, payload);
    if (data) {
      yield put(updateVolunteerSuccess(data));
      yield put(
        addMessage({ message: messages.updateVolSuc, variant: 'success' }),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(addMessage({ message: errMessage, variant: 'error' }));
    yield put(updateVolunteerError(errMessage));
  }
}

function* linkVolunteer({ payload }) {
  try {
    const { data } = yield call(linkVolunteerApi, payload);
    if (data) {
      yield put(updateLinkedVolunteerSuccess(data));
      yield put(
        addMessage({ message: messages.updateLinkVolSuc, variant: 'success' }),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(addMessage({ message: errMessage, variant: 'error' }));
    yield put(updateLinkedVolunteerError(errMessage));
  }
}

function* deleteVolunteer({ payload }) {
  try {
    const { data } = yield call(deleteVolunteerApi, payload);
    if (data) {
      yield put(deleteVolunteerSuccessAct(data));
      yield put(
        addMessage({
          message: messages.deleteVolunteerSuc,
          variant: 'success',
        }),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(addMessage({ message: errMessage, variant: 'error' }));
    yield put(deleteVolunteerErrorAct(errMessage));
  }
}

// Individual exports for testing
export default function* volunteerDetailSaga() {
  yield takeLatest(getVolunteerDetailAct.toString(), fetchVolunteerDetail);
  yield takeEvery(updateMissionStateAct.toString(), updateMissionState);
  yield takeEvery(updateTagStateAct.toString(), updateTagState);
  yield takeEvery(assignUserAct.toString(), updateAssignedUser);
  yield takeEvery(assignGroupAct.toString(), updateAssignedGroup);
  yield takeEvery(
    updateVerifyLicenseStateAct.toString(),
    updateLicenseVerifyState,
  );
  yield takeEvery(updateVerifyStateAct.toString(), updateVolunteerVerifyState);
  yield takeEvery(updateVolunteerAct.toString(), updateVolunteer);
  yield takeEvery(
    updateParentalConsentStateAct.toString(),
    updateParentalConsentState,
  );
  yield takeEvery(updateLinkedVolunteerAct.toString(), linkVolunteer);
  yield takeLatest(deleteVolunteerAct.toString(), deleteVolunteer);
}
