/*
 *
 * VolunteerDetail reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';

import {
  assignGroupAct,
  assignGroupError,
  assignGroupSuccess,
  assignUserAct,
  assignUserErrorAct,
  assignUserSuccess,
  getVolunteerDetailAct,
  getVolunteerDetailError,
  getVolunteerDetailSuccess,
  resetVolunteerDetail,
  updateMissionStateAct,
  updateMissionStateError,
  updateMissionStateSuccess,
  updateTagStateAct,
  updateTagStateError,
  updateTagStateSuccess,
  updateVerifyLicenseStateAct,
  updateVerifyLicenseStateError,
  updateVerifyLicenseStateSuccess,
  updateVerifyStateAct,
  updateVerifyStateError,
  updateVerifyStateSuccess,
  updateVolunteerAct,
  updateVolunteerError,
  updateVolunteerSuccess,
  updateParentalConsentStateAct,
  updateParentalConsentStateError,
  updateParentalConsentStateSuccess,
  updateLinkedVolunteerAct,
  updateLinkedVolunteerError,
  updateLinkedVolunteerSuccess,
  deleteVolunteerAct,
  deleteVolunteerErrorAct,
  deleteVolunteerSuccessAct,
} from './actions';

export const initialState = {
  loadingState: 0,
  volunteer: null,
  missionState: 0,
  linkedState: 0,
  tagState: 0,
  canDriveState: 0,
  userAssign: 0,
  groupAssign: 0,
  verifyState: 0,
  updateState: 0,
  parentalConsentState: 0,
  linkVolunteerState: 0,
  deleteState: 0,
};

/* eslint-disable default-case, no-param-reassign */
const volunteerDetailSlice = createSlice({
  name: 'VolunteerDetail',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getVolunteerDetailAct, state => {
        state.loadingState = 1;
      })
      .addCase(getVolunteerDetailError, state => {
        state.loadingState = 3;
      })
      .addCase(getVolunteerDetailSuccess, (state, { payload }) => {
        state.loadingState = 2;
        state.volunteer = payload;
      })
      .addCase(updateMissionStateAct, (state, { payload }) => {
        const { data } = payload;
        const { missionId } = data;
        const { volunteer } = state;
        if (volunteer.missions) {
          const mission = volunteer.missions.find(mis => mis.id === missionId);
          if (mission) {
            mission.loadingState = 1;
          }
        }
        state.missionState = 1;
      })
      .addCase(updateMissionStateError, (state, { payload }) => {
        const { missionId, isAssigned } = payload;
        const { volunteer } = state;
        if (volunteer.missions) {
          const mission = volunteer.missions.find(mis => mis.id === missionId);
          if (mission) {
            mission.loadingState = 3;
            mission.status.isAssigned = isAssigned;
          }
        }
        state.missionState = 3;
      })
      .addCase(updateMissionStateSuccess, (state, { payload }) => {
        const { volunteerMissionId: missionId, isAssigned } = payload;
        const { volunteer } = state;
        if (volunteer.missions) {
          const mission = volunteer.missions.find(mis => mis.id === missionId);
          if (mission) {
            mission.loadingState = 0;
            mission.status.isAssigned = isAssigned;
          }
        }
        state.missionState = 2;
      })
      .addCase(updateTagStateAct, (state, { payload }) => {
        const { data } = payload;
        const { tagId } = data;
        const { volunteer } = state;
        if (volunteer.tags) {
          const tag = volunteer.tags.find(tg => tg.id === tagId);
          if (tag) {
            tag.loadingState = 1;
          }
        }
        state.tagState = 1;
      })
      .addCase(updateTagStateError, (state, { payload }) => {
        const { tagId, isAssigned } = payload;
        const { volunteer } = state;
        if (volunteer.tags) {
          const tag = volunteer.tags.find(tg => tg.id === tagId);
          if (tag) {
            tag.loadingState = 3;
            tag.status.active = isAssigned;
          }
        }
        state.tagState = 3;
      })
      .addCase(updateTagStateSuccess, (state, { payload }) => {
        const { volunteerTagId: tagId, active } = payload;
        const { volunteer } = state;
        if (volunteer.tags) {
          const tag = volunteer.tags.find(tg => tg.id === tagId);
          if (tag) {
            tag.loadingState = 0;
            tag.status.active = active;
          }
        }
        state.tagState = 2;
      })
      .addCase(assignUserAct, (state, { payload }) => {
        state.userAssign = 1;
        const { metadata } = payload;
        const { currentValue } = metadata;
        state.volunteer = { ...state.volunteer, assignedUser: currentValue };
      })
      .addCase(assignUserErrorAct, (state, { payload }) => {
        const { previousValue } = payload;
        state.volunteer = { ...state.volunteer, assignedUser: previousValue };
        state.userAssign = 3;
      })
      .addCase(assignUserSuccess, (state, { payload }) => {
        state.volunteer = { ...state.volunteer, ...payload };
        state.userAssign = 2;
      })
      .addCase(assignGroupAct, (state, { payload }) => {
        state.groupAssign = 1;
        const { metadata } = payload;
        const { currentValue } = metadata;
        state.volunteer = { ...state.volunteer, group: currentValue };
      })
      .addCase(assignGroupError, (state, { payload }) => {
        const { previousValue } = payload;
        state.volunteer = { ...state.volunteer, group: previousValue };
        state.groupAssign = 3;
      })
      .addCase(assignGroupSuccess, (state, { payload }) => {
        state.groupAssign = 2;
        state.volunteer = { ...state.volunteer, ...payload };
      })
      .addCase(updateVerifyLicenseStateAct, (state, { payload }) => {
        state.canDriveState = 1;
        const { metadata } = payload;
        const { currentValue } = metadata;
        state.volunteer = {
          ...state.volunteer,
          licenseVerification: currentValue,
        };
      })
      .addCase(updateVerifyLicenseStateError, (state, { payload }) => {
        state.canDriveState = 3;
        const { previousValue } = payload;
        state.volunteer = {
          ...state.volunteer,
          licenseVerification: previousValue,
        };
      })
      .addCase(updateVerifyLicenseStateSuccess, (state, { payload }) => {
        state.canDriveState = 2;
        state.volunteer = { ...state.volunteer, ...payload };
      })
      .addCase(updateVerifyStateAct, (state, { payload }) => {
        state.verifyState = 1;
        const { metadata } = payload;
        const { currentValue } = metadata;
        state.volunteer = {
          ...state.volunteer,
          verificationState: currentValue,
        };
      })
      .addCase(updateVerifyStateError, (state, { payload }) => {
        state.verifyState = 3;
        const { previousValue } = payload;
        state.volunteer = {
          ...state.volunteer,
          verificationState: previousValue,
        };
      })
      .addCase(updateVerifyStateSuccess, (state, { payload }) => {
        state.verifyState = 2;
        state.volunteer = { ...state.volunteer, ...payload };
      })
      .addCase(updateVolunteerAct, state => {
        state.updateState = 1;
      })
      .addCase(updateVolunteerError, state => {
        state.updateState = 3;
      })
      .addCase(updateVolunteerSuccess, (state, { payload }) => {
        state.updateState = 2;
        state.volunteer = { ...state.volunteer, ...payload };
      })
      .addCase(updateParentalConsentStateAct, (state, { payload }) => {
        state.parentalConsentState = 1;
        const { metadata } = payload;
        const { currentValue } = metadata;
        state.volunteer = {
          ...state.volunteer,
          parentalConsentState: currentValue,
        };
      })
      .addCase(updateParentalConsentStateError, (state, { payload }) => {
        state.parentalConsentState = 3;
        const { previousValue } = payload;
        state.volunteer = {
          ...state.volunteer,
          parentalConsentState: previousValue,
        };
      })
      .addCase(updateParentalConsentStateSuccess, (state, { payload }) => {
        state.parentalConsentState = 2;
        state.volunteer = { ...state.volunteer, ...payload };
      })
      .addCase(updateLinkedVolunteerAct, (state, { payload }) => {
        state.linkVolunteerState = 1;
        const { metadata } = payload;
        const { currentValue } = metadata;
        state.volunteer = {
          ...state.volunteer,
          relatedTo: currentValue,
          relatedFrom: [],
        };
      })
      .addCase(updateLinkedVolunteerError, (state, { payload }) => {
        state.linkVolunteerState = 3;
        const { previousValue } = payload;
        state.volunteer = {
          ...state.volunteer,
          relatedTo: previousValue,
          relatedFrom: [],
        };
      })
      .addCase(updateLinkedVolunteerSuccess, (state, { payload }) => {
        state.linkVolunteerState = 2;
        state.volunteer = {
          ...state.volunteer,
          ...payload,
        };
      })
      .addCase(deleteVolunteerAct, state => {
        state.deleteState = 1;
      })
      .addCase(deleteVolunteerErrorAct, state => {
        state.deleteState = 3;
      })
      .addCase(deleteVolunteerSuccessAct, state => {
        state.deleteState = 2;
      })
      .addCase(resetVolunteerDetail, () => initialState);
  },
});

export default volunteerDetailSlice.reducer;
