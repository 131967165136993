/*
 *
 * VolunteerDetail actions
 *
 */

import { createAction } from '@reduxjs/toolkit';
import {
  ASSIGN_GROUP,
  ASSIGN_GROUP_ERROR,
  ASSIGN_GROUP_SUCCESS,
  ASSIGN_USER,
  ASSIGN_USER_ERROR,
  ASSIGN_USER_SUCCESS,
  GET_VOLUNTEER_DETAIL,
  GET_VOLUNTEER_DETAIL_ERROR,
  GET_VOLUNTEER_DETAIL_SUCCESS,
  RESET_VOLUNTEER_DETAIL,
  UPDATE_MISSION_STATE,
  UPDATE_MISSION_STATE_ERROR,
  UPDATE_MISSION_STATE_SUCCESS,
  UPDATE_TAG_STATE,
  UPDATE_TAG_STATE_ERROR,
  UPDATE_TAG_STATE_SUCCESS,
  UPDATE_VERIFY_LICENSE_STATE,
  UPDATE_VERIFY_LICENSE_STATE_ERROR,
  UPDATE_VERIFY_LICENSE_STATE_SUCCESS,
  UPDATE_VERIFY_STATE,
  UPDATE_VERIFY_STATE_ERROR,
  UPDATE_VERIFY_STATE_SUCCESS,
  UPDATE_VOLUNTEER,
  UPDATE_VOLUNTEER_ERROR,
  UPDATE_VOLUNTEER_SUCCESS,
  UPDATE_PARENTAL_CONSENT_STATE,
  UPDATE_PARENTAL_CONSENT_STATE_ERROR,
  UPDATE_PARENTAL_CONSENT_STATE_SUCCESS,
  UPDATE_LINKED_VOLUNTEER,
  UPDATE_LINKED_VOLUNTEER_ERROR,
  UPDATE_LINKED_VOLUNTEER_SUCCESS,
  DELETE_VOLUNTEER,
  DELETE_VOLUNTEER_ERROR,
  DELETE_VOLUNTEER_SUCCESS,
} from './constants';

export const getVolunteerDetailAct = createAction(GET_VOLUNTEER_DETAIL);
export const getVolunteerDetailError = createAction(GET_VOLUNTEER_DETAIL_ERROR);
export const getVolunteerDetailSuccess = createAction(
  GET_VOLUNTEER_DETAIL_SUCCESS,
);

export const updateMissionStateAct = createAction(UPDATE_MISSION_STATE);
export const updateMissionStateError = createAction(UPDATE_MISSION_STATE_ERROR);
export const updateMissionStateSuccess = createAction(
  UPDATE_MISSION_STATE_SUCCESS,
);

export const updateTagStateAct = createAction(UPDATE_TAG_STATE);
export const updateTagStateError = createAction(UPDATE_TAG_STATE_ERROR);
export const updateTagStateSuccess = createAction(UPDATE_TAG_STATE_SUCCESS);

export const updateVerifyStateAct = createAction(UPDATE_VERIFY_STATE);
export const updateVerifyStateError = createAction(UPDATE_VERIFY_STATE_ERROR);
export const updateVerifyStateSuccess = createAction(
  UPDATE_VERIFY_STATE_SUCCESS,
);

export const updateVerifyLicenseStateAct = createAction(
  UPDATE_VERIFY_LICENSE_STATE,
);
export const updateVerifyLicenseStateError = createAction(
  UPDATE_VERIFY_LICENSE_STATE_ERROR,
);
export const updateVerifyLicenseStateSuccess = createAction(
  UPDATE_VERIFY_LICENSE_STATE_SUCCESS,
);

export const assignUserAct = createAction(ASSIGN_USER);
export const assignUserErrorAct = createAction(ASSIGN_USER_ERROR);
export const assignUserSuccess = createAction(ASSIGN_USER_SUCCESS);

export const assignGroupAct = createAction(ASSIGN_GROUP);
export const assignGroupError = createAction(ASSIGN_GROUP_ERROR);
export const assignGroupSuccess = createAction(ASSIGN_GROUP_SUCCESS);

export const updateVolunteerAct = createAction(UPDATE_VOLUNTEER);
export const updateVolunteerError = createAction(UPDATE_VOLUNTEER_ERROR);
export const updateVolunteerSuccess = createAction(UPDATE_VOLUNTEER_SUCCESS);

export const updateParentalConsentStateAct = createAction(
  UPDATE_PARENTAL_CONSENT_STATE,
);
export const updateParentalConsentStateError = createAction(
  UPDATE_PARENTAL_CONSENT_STATE_ERROR,
);
export const updateParentalConsentStateSuccess = createAction(
  UPDATE_PARENTAL_CONSENT_STATE_SUCCESS,
);

export const updateLinkedVolunteerAct = createAction(UPDATE_LINKED_VOLUNTEER);
export const updateLinkedVolunteerError = createAction(
  UPDATE_LINKED_VOLUNTEER_ERROR,
);
export const updateLinkedVolunteerSuccess = createAction(
  UPDATE_LINKED_VOLUNTEER_SUCCESS,
);

export const deleteVolunteerAct = createAction(DELETE_VOLUNTEER);
export const deleteVolunteerErrorAct = createAction(DELETE_VOLUNTEER_ERROR);
export const deleteVolunteerSuccessAct = createAction(DELETE_VOLUNTEER_SUCCESS);

export const resetVolunteerDetail = createAction(RESET_VOLUNTEER_DETAIL);
