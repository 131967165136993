/*
 *
 * HubSpotLogs reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';

import {
  getLogsAct,
  getLogsErrorAct,
  getLogsSuccessAct,
  resetHubSpotLogsAct,
} from './actions';

export const initialState = {
  loadingState: 0,
  totalPages: 0,
  count: 0,
  logs: [],
};

/* eslint-disable default-case, no-param-reassign */
const hubSpotLogsSlice = createSlice({
  name: 'HubSpotLogs',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getLogsAct, state => {
        state.loadingState = 1;
      })
      .addCase(getLogsErrorAct, state => {
        state.loadingState = 3;
      })
      .addCase(getLogsSuccessAct, (state, { payload }) => {
        state.loadingState = 2;
        state.count = payload.count;
        state.totalPages = payload.totalPages;
        state.logs = payload.rows;
        state.totalPages = payload.totalPages;
      })
      .addCase(resetHubSpotLogsAct, () => initialState);
  },
});

export default hubSpotLogsSlice.reducer;
