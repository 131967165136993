/*
 *
 * VolunteerGroups reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';

import {
  createVolunteerGroupsAct,
  createVolunteerGroupsError,
  createVolunteerGroupsSuccess,
  deleteVolunteerGroupsAct,
  deleteVolunteerGroupsError,
  deleteVolunteerGroupsSuccess,
  getVolunteerGroupsListAct,
  getVolunteerGroupsListError,
  getVolunteerGroupsListSuccess,
  resetGetVolunteerGroupsList,
  updateActionStatusAct,
  updateVolunteerGroupsAct,
  updateVolunteerGroupsError,
  updateVolunteerGroupsSuccess,
} from './actions';

import groupsEntityAdapter from './entity-adapter';

export const initialState = groupsEntityAdapter.getInitialState({
  loadingState: 0,
  error: '',
  totalPages: 0,
  count: 0,
  actionStatus: {
    status: 0,
    type: null,
    id: null,
  },
});

/* eslint-disable default-case, no-param-reassign */
const volunteerGroupsSlice = createSlice({
  name: 'VolunteerGroups',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getVolunteerGroupsListAct, (state, { payload }) => {
        state.loadingState = 1;
        state.error = '';
        if (payload.params && payload.params.page === 1) {
          groupsEntityAdapter.removeAll(state);
        }
      })
      .addCase(getVolunteerGroupsListError, (state, { payload }) => {
        state.loadingState = 3;
        state.error = payload;
      })
      .addCase(getVolunteerGroupsListSuccess, (state, { payload }) => {
        state.loadingState = 2;
        state.error = '';
        groupsEntityAdapter.upsertMany(state, payload.rows);
        state.totalPages = payload.totalPages;
        state.count = payload.count;
      })
      .addCase(deleteVolunteerGroupsAct, state => {
        state.actionStatus.status = 1;
      })
      .addCase(deleteVolunteerGroupsError, state => {
        state.actionStatus.status = 3;
      })
      .addCase(deleteVolunteerGroupsSuccess, (state, { payload }) => {
        const { id } = payload;
        state.actionStatus.status = 2;
        groupsEntityAdapter.removeOne(state, id);
      })
      .addCase(updateVolunteerGroupsAct, state => {
        state.actionStatus.status = 1;
      })
      .addCase(updateVolunteerGroupsError, state => {
        state.actionStatus.status = 3;
      })
      .addCase(updateVolunteerGroupsSuccess, (state, { payload }) => {
        const { id, name, updatedAt, description } = payload;
        state.actionStatus.status = 2;
        if (id && state.entities[id]) {
          groupsEntityAdapter.updateOne(state, {
            id,
            changes: {
              name,
              description,
              updatedAt,
            },
          });
        }
      })
      .addCase(createVolunteerGroupsAct, state => {
        state.actionStatus.status = 1;
      })
      .addCase(createVolunteerGroupsError, state => {
        state.actionStatus.status = 3;
      })
      .addCase(createVolunteerGroupsSuccess, (state, { payload }) => {
        state.actionStatus.status = 2;
        groupsEntityAdapter.addOne(state, payload);
      })
      .addCase(updateActionStatusAct, (state, { payload }) => {
        state.actionStatus = payload;
      })
      .addCase(resetGetVolunteerGroupsList, () => initialState);
  },
});

export default volunteerGroupsSlice.reducer;
