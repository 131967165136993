/*
 *
 * UserPreferences reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';
import {
  resetFilterAct,
  updateMemberFilterAct,
  updateTeamFilterAct,
} from './actions';
import { TEAM_STATUS_OPTIONS } from '../../utils/options.util';

export const initialState = {
  teamFilters: {
    search: '',
    ts: [TEAM_STATUS_OPTIONS[0]],
    isIncompleteTeam: '',
    hasNoSupporter: '',
    isMedicalCertificateCompleted: '',
    selectedDonationRanges: [],
    selectedUsers: [],
    selectedCompanyNames: [],
    selectedTag: null,
    startTimeValue: null,
    selectedWEWCStatuses: [],
    hasStartTime: '',
    hasBookedMeal: '',
    ob: '',
    eventIds: [],
    teamTypes: [],
    gender: '',
    memberCount: '',
    registrationMonths: [],
    selectedRaisedPercentages: [],
    sortOrder: {
      name: 'registrationDate',
      direction: 'desc',
    },
    teamGroup: null,
  },
  memberFilters: {
    search: '',
    selectedGender: '',
    selectedMedicalCertificateStates: [],
    selectedType: '',
    startTimeValue: null,
    selectedZipCodes: [],
    selectedCompanies: [],
    selectedCountries: [],
    selectedSources: [],
    selectedTag: null,
    selectedTshirt: '',
    selectedTshirtSizes: [],
    selectedMealTypes: [],
    hasStartTime: '',
    isBoarded: '',
    ts: [TEAM_STATUS_OPTIONS[0]],
    hf: '',
    eventIds: [],
    registrationMonth: '',
    teamTypes: [],
    ageRanges: [],
    needCap: '',
    hasFTTRI: '',
    teamGroup: null,
    sortOrder: {
      name: 'displayName',
      direction: 'asc',
    },
  },
};

/* eslint-disable default-case, no-param-reassign */
const userPreferencesSlice = createSlice({
  name: 'UserPreferences',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(updateTeamFilterAct, (state, { payload }) => {
        const { filters, reset } = payload;
        if (reset) {
          state.teamFilters = { ...initialState.teamFilters, ts: [] };
        } else {
          state.teamFilters = { ...state.teamFilters, ...filters };
        }
      })
      .addCase(updateMemberFilterAct, (state, { payload }) => {
        const { filters, reset } = payload;
        if (reset) {
          state.memberFilters = { ...initialState.memberFilters, ts: [] };
        } else {
          state.memberFilters = { ...state.memberFilters, ...filters };
        }
      })
      .addCase(resetFilterAct, state => {
        state.teamFilters = {
          ...initialState.teamFilters,
        };
        state.memberFilters = {
          ...initialState.memberFilters,
        };
      });
  },
});

export default userPreferencesSlice.reducer;
