/*
 *
 * MemberTags reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';

import {
  createMemberTagsAct,
  createMemberTagsErrorAct,
  createMemberTagsSuccessAct,
  deleteMemberTagsAct,
  deleteMemberTagsErrorAct,
  deleteMemberTagsSuccessAct,
  getMemberTagsListAct,
  getMemberTagsListErrorAct,
  getMemberTagsListSuccessAct,
  resetGetMemberTagsListAct,
  updateActionStatusAct,
  updateMemberTagsAct,
  updateMemberTagsErrorAct,
  updateMemberTagsSuccessAct,
} from './actions';

import tagsEntityAdapter from './entity-adapter';

export const initialState = tagsEntityAdapter.getInitialState({
  loadingState: 0,
  error: '',
  totalPages: 0,
  count: 0,
  actionStatus: {
    status: 0,
    type: null,
    id: null,
  },
});

/* eslint-disable default-case, no-param-reassign */
const memberTagsSlice = createSlice({
  name: 'MemberTags',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getMemberTagsListAct, (state, { payload }) => {
        state.loadingState = 1;
        state.error = '';
        if (payload.params && payload.params.page === 1) {
          tagsEntityAdapter.removeAll(state);
        }
      })
      .addCase(getMemberTagsListErrorAct, (state, { payload }) => {
        state.loadingState = 3;
        state.error = payload;
      })
      .addCase(getMemberTagsListSuccessAct, (state, { payload }) => {
        state.loadingState = 2;
        state.error = '';
        tagsEntityAdapter.upsertMany(state, payload.rows);
        state.totalPages = payload.totalPages;
        state.count = payload.count;
      })
      .addCase(deleteMemberTagsAct, state => {
        state.actionStatus.status = 1;
      })
      .addCase(deleteMemberTagsErrorAct, state => {
        state.actionStatus.status = 3;
      })
      .addCase(deleteMemberTagsSuccessAct, (state, { payload }) => {
        const { id } = payload;
        state.actionStatus.status = 2;
        tagsEntityAdapter.removeOne(state, id);
      })
      .addCase(updateMemberTagsAct, state => {
        state.actionStatus.status = 1;
      })
      .addCase(updateMemberTagsErrorAct, state => {
        state.actionStatus.status = 3;
      })
      .addCase(updateMemberTagsSuccessAct, (state, { payload }) => {
        const { id, name, updatedAt } = payload;
        state.actionStatus.status = 2;
        if (id && state.entities[id]) {
          tagsEntityAdapter.updateOne(state, {
            id,
            changes: {
              name,
              updatedAt,
            },
          });
        }
      })
      .addCase(createMemberTagsAct, state => {
        state.actionStatus.status = 1;
      })
      .addCase(createMemberTagsErrorAct, state => {
        state.actionStatus.status = 3;
      })
      .addCase(createMemberTagsSuccessAct, (state, { payload }) => {
        state.actionStatus.status = 2;
        tagsEntityAdapter.addOne(state, payload);
      })
      .addCase(updateActionStatusAct, (state, { payload }) => {
        state.actionStatus = payload;
      })
      .addCase(resetGetMemberTagsListAct, () => initialState);
  },
});

export default memberTagsSlice.reducer;
