import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './reducer';
import { REDUCER_KEY } from './constants';

/**
 * Direct selector to the memberDetail state domain
 */

const selectMemberDetailDomain = state => state[REDUCER_KEY] || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by MemberDetail
 */

const makeSelectMemberDetail = () =>
  createSelector(selectMemberDetailDomain, substate => substate);

const makeSelectMember = () =>
  createSelector(selectMemberDetailDomain, substate => substate.member);

const makeSelectLoadingState = () =>
  createSelector(selectMemberDetailDomain, substate => substate.loadingState);

const makeSelectMemberCertState = () =>
  createSelector(
    selectMemberDetailDomain,
    substate => substate.memberCertState,
  );

const makeSelectMedicalCertificate = () =>
  createSelector([makeSelectMember()], member =>
    member ? member.medicalCertificateState : 'Not verified',
  );

const makeSelectParentalConsent = () =>
  createSelector([makeSelectMember()], member =>
    member ? member.parentalConsentState : 'Not verified',
  );
const makeSelectTshirtSize = () =>
  createSelector([makeSelectMember()], member =>
    member ? member.tshirtSize : 'Not verified',
  );

const makeSelectTags = () =>
  createSelector([makeSelectMember()], member => (member ? member.tags : []));

const makeSelectAdditionalInfo = () =>
  createSelector([makeSelectMember()], member =>
    member
      ? {
          gender: member.gender,
          birthday: member.birthday,
          registeredDate: member.registeredDate,
          type: member.type,
          teamBibNumber: member.team?.bibNumber,
          bibNumber: member.bibNumber,
          meal: member.meal,
          hostingEntryId: member.hostingEntryId,
          boardingStatus: member.boardingStatus,
          source: member.source,
          previousParticipation: member.previousParticipation,
          capNeeded: member.capNeeded,
          bikeNeeded: member.bikeNeeded,
          hasFTTRILicense: member.hasFTTRILicense,
          group: member.team?.group,
          teamId: member.team?.id,
        }
      : {},
  );

const makeSelectMemberParentConsentState = () =>
  createSelector(
    selectMemberDetailDomain,
    substate => substate.memberParentConsentState,
  );

const makeSelectMemberTshirtState = () =>
  createSelector(
    selectMemberDetailDomain,
    substate => substate.memberTshirtState,
  );
const makeSelectMealUpdateState = () =>
  createSelector(
    selectMemberDetailDomain,
    substate => substate.mealUpdateState,
  );

export const selectAssignGroupState = createSelector(
  selectMemberDetailDomain,
  substate => substate.assignGroup,
);

const makeSelectMealAction = () =>
  createSelector(selectMemberDetailDomain, substate => substate.mealAction);
export default makeSelectMemberDetail;
export {
  selectMemberDetailDomain,
  makeSelectMember,
  makeSelectLoadingState,
  makeSelectMemberCertState,
  makeSelectMemberParentConsentState,
  makeSelectMemberTshirtState,
  makeSelectMedicalCertificate,
  makeSelectAdditionalInfo,
  makeSelectParentalConsent,
  makeSelectTshirtSize,
  makeSelectTags,
  makeSelectMealUpdateState,
  makeSelectMealAction,
};
