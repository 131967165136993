import { call, put, takeLatest } from 'redux-saga/effects';
import { getErrorMessage } from '../../utils/error.util';
import { getJobsApi } from '../../api/hub-spot-sync-jobs.api';
import { getLogsAct, getLogsErrorAct, getLogsSuccessAct } from './actions';
import { addMessage } from '../ToastMessagesManager/actions';

function* fetchLogs({ payload }) {
  try {
    const { data } = yield call(getJobsApi, payload);
    if (data) {
      yield put(getLogsSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(
      addMessage({
        message: errMessage,
        variant: 'error',
      }),
    );
    yield put(getLogsErrorAct());
  }
}
// Individual exports for testing
export default function* hubSpotLogsSaga() {
  //  uncomment this to setp default action
  yield takeLatest(getLogsAct.toString(), fetchLogs);
}
