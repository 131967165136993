/*
 *
 * HubSpotLogs actions
 *
 */

import { createAction } from '@reduxjs/toolkit';
import {
  GET_LOGS,
  GET_LOGS_ERROR,
  GET_LOGS_SUCCESS,
  RESET_HUB_SPOT_LOGS,
} from './constants';

export const getLogsAct = createAction(GET_LOGS);
export const getLogsSuccessAct = createAction(GET_LOGS_SUCCESS);
export const getLogsErrorAct = createAction(GET_LOGS_ERROR);
export const resetHubSpotLogsAct = createAction(RESET_HUB_SPOT_LOGS);
