import BaseApiInstance from './base';
const BASE_URL = '/volunteers';

export const getVolunteerDetail = async id => {
  const response = await BaseApiInstance.get(`${BASE_URL}/${id}`);
  return response;
};

export const updateVolunteerDetail = async ({ volunteerId, data }) => {
  const response = await BaseApiInstance.put(
    `${BASE_URL}/${volunteerId}`,
    data,
  );
  return response;
};

export const deleteVolunteerApi = async id => {
  const response = await BaseApiInstance.delete(`${BASE_URL}/${id}`);
  return response;
};

export const updateMission = async ({ id, data }) => {
  const response = await BaseApiInstance.put(
    `${BASE_URL}/${id}/update-mission`,
    data,
  );
  return response;
};

export const updateTag = async ({ id, data }) => {
  const response = await BaseApiInstance.put(
    `${BASE_URL}/${id}/update-tag`,
    data,
  );
  return response;
};

export const assignUser = async (id, data) => {
  const response = await BaseApiInstance.put(
    `${BASE_URL}/${id}/assign-user`,
    data,
  );
  return response;
};

export const assignGroup = async (id, data) => {
  const response = await BaseApiInstance.put(
    `${BASE_URL}/${id}/assign-group`,
    data,
  );
  return response;
};

export const updateLicense = async (id, data) => {
  const response = await BaseApiInstance.put(
    `${BASE_URL}/${id}/verify-license`,
    data,
  );
  return response;
};

export const updateParentalConsent = async (id, data) => {
  const response = await BaseApiInstance.put(
    `${BASE_URL}/${id}/parental-consent`,
    data,
  );
  return response;
};

export const verifyVolunteer = async (id, data) => {
  const response = await BaseApiInstance.put(`${BASE_URL}/${id}/verify`, data);
  return response;
};

export const getNotes = async ({ volunteerId, params }) => {
  const response = await BaseApiInstance.get(
    `${BASE_URL}/${volunteerId}/notes`,
    {
      params,
    },
  );
  return response;
};

export const createNote = async ({ volunteerId, data }) => {
  const response = await BaseApiInstance.post(
    `${BASE_URL}/${volunteerId}/notes`,
    data,
  );
  return response;
};

export const updateNote = async ({ volunteerId, id, data }) => {
  const response = await BaseApiInstance.put(
    `${BASE_URL}/${volunteerId}/notes/${id}`,
    data,
  );
  return response;
};

export const updatePinStatus = async ({ volunteerId, id, data }) => {
  const response = await BaseApiInstance.put(
    `${BASE_URL}/${volunteerId}/notes/${id}/update-pin`,
    data,
  );
  return response;
};

export const deleteNote = async ({ volunteerId, id }) => {
  const response = await BaseApiInstance.delete(
    `${BASE_URL}/${volunteerId}/notes/${id}`,
  );
  return response;
};

export const linkVolunteerApi = async ({ volunteerId, data }) => {
  const response = await BaseApiInstance.put(
    `${BASE_URL}/${volunteerId}/link-volunteer`,
    data,
  );
  return response;
};

export const getMissionsApi = async () => {
  const response = await BaseApiInstance.get(`${BASE_URL}/missions`);
  return response;
};
