/*
 *
 * TeamGroupList constants
 *
 */
export const REDUCER_KEY = 'TeamGroupList';

export const GET_TEAM_GROUPS_LIST = 'app/TeamGroupList/GET_TEAM_GROUPS_LIST';
export const GET_TEAM_GROUPS_LIST_ERROR =
  'app/TeamGroupList/GET_TEAM_GROUPS_LIST_ERROR';
export const GET_TEAM_GROUPS_LIST_SUCCESS =
  'app/TeamGroupList/GET_TEAM_GROUPS_LIST_SUCCESS';

export const DELETE_TEAM_GROUPS = 'app/TeamGroupList/DELETE_TEAM_GROUPS';
export const DELETE_TEAM_GROUPS_ERROR =
  'app/TeamGroupList/DELETE_TEAM_GROUPS_ERROR';
export const DELETE_TEAM_GROUPS_SUCCESS =
  'app/TeamGroupList/DELETE_TEAM_GROUPS_SUCCESS';

export const UPDATE_TEAM_GROUPS = 'app/TeamGroupList/UPDATE_TEAM_GROUPS';
export const UPDATE_TEAM_GROUPS_ERROR =
  'app/TeamGroupList/UPDATE_TEAM_GROUPS_ERROR';
export const UPDATE_TEAM_GROUPS_SUCCESS =
  'app/TeamGroupList/UPDATE_TEAM_GROUPS_SUCCESS';

export const UPDATE_ACTION_STATUS = 'app/TeamGroupList/UPDATE_ACTION_STATUS';

export const CREATE_TEAM_GROUPS = 'app/TeamGroupList/CREATE_TEAM_GROUPS';
export const CREATE_TEAM_GROUPS_ERROR =
  'app/TeamGroupList/CREATE_TEAM_GROUPS_ERROR';
export const CREATE_TEAM_GROUPS_SUCCESS =
  'app/TeamGroupList/CREATE_TEAM_GROUPS_SUCCESS';

export const RESET_GET_TEAM_GROUPS_LIST =
  'app/TeamGroupList/RESET_GET_TEAM_GROUPS_LIST';
