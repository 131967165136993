/*
 *
 * ToastMessages reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';

import { addMessage, removeMessage } from './actions';

export const initialState = {
  messages: [],
};

/* eslint-disable default-case, no-param-reassign */
const toastMessagesSlice = createSlice({
  name: 'ToastMessages',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(addMessage, (state, { payload }) => {
        state.messages.unshift({
          ...payload,
          id: payload.id || Date.now(),
        });
      })
      .addCase(removeMessage, (state, { payload }) => {
        state.messages = state.messages.filter(
          message => message.id !== payload,
        );
      });
  },
});

export default toastMessagesSlice.reducer;
