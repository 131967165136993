import { createManager } from 'redux-injectors';

import reducer from './reducer';

import { REDUCER_KEY } from './constants';

const ToastMessagesManager = createManager({
  name: 'ToastMessagesManager',
  key: REDUCER_KEY,
  reducer,
});

export default ToastMessagesManager;
