/*
 *
 * VolunteerGroups constants
 *
 */
export const REDUCER_KEY = 'VolunteerGroups';

export const GET_VOLUNTEER_GROUPS_LIST =
  'app/VolunteerGroups/GET_VOLUNTEER_GROUPS_LIST';
export const GET_VOLUNTEER_GROUPS_LIST_ERROR =
  'app/VolunteerGroups/GET_VOLUNTEER_GROUPS_LIST_ERROR';
export const GET_VOLUNTEER_GROUPS_LIST_SUCCESS =
  'app/VolunteerGroups/GET_VOLUNTEER_GROUPS_LIST_SUCCESS';
export const RESET_GET_VOLUNTEER_GROUPS_LIST =
  'app/VolunteerGroups/RESET_GET_VOLUNTEER_GROUPS_LIST';
export const DELETE_VOLUNTEER_GROUPS =
  'app/VolunteerGroups/DELETE_VOLUNTEER_GROUPS';
export const DELETE_VOLUNTEER_GROUPS_ERROR =
  'app/VolunteerGroups/DELETE_VOLUNTEER_GROUPS_ERROR';
export const DELETE_VOLUNTEER_GROUPS_SUCCESS =
  'app/VolunteerGroups/DELETE_VOLUNTEER_GROUPS_SUCCESS';
export const UPDATE_VOLUNTEER_GROUPS =
  'app/VolunteerGroups/UPDATE_VOLUNTEER_GROUPS';
export const UPDATE_VOLUNTEER_GROUPS_ERROR =
  'app/VolunteerGroups/UPDATE_VOLUNTEER_GROUPS_ERROR';
export const UPDATE_VOLUNTEER_GROUPS_SUCCESS =
  'app/VolunteerGroups/UPDATE_VOLUNTEER_GROUPS_SUCCESS';
export const UPDATE_ACTION_STATUS = 'app/VolunteerGroups/UPDATE_ACTION_STATUS';
export const CREATE_VOLUNTEER_GROUPS =
  'app/VolunteerGroups/CREATE_VOLUNTEER_GROUPS';
export const CREATE_VOLUNTEER_GROUPS_ERROR =
  'app/VolunteerGroups/CREATE_VOLUNTEER_GROUPS_ERROR';
export const CREATE_VOLUNTEER_GROUPS_SUCCESS =
  'app/VolunteerGroups/CREATE_VOLUNTEER_GROUPS_SUCCESS';
