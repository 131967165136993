/*
 *
 * TeamTags reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';

import {
  createTeamTagsAct,
  createTeamTagsError,
  createTeamTagsSuccess,
  deleteTeamTagsAct,
  deleteTeamTagsError,
  deleteTeamTagsSuccess,
  getTeamTagsListAct,
  getTeamTagsListError,
  getTeamTagsListSuccess,
  updateActionStatusAct,
  resetGetTeamTagsList,
  updateTeamTagsAct,
  updateTeamTagsError,
  updateTeamTagsSuccess,
} from './actions';

import teamTagsEntityAdapter from './entity-adapter';

export const initialState = teamTagsEntityAdapter.getInitialState({
  loadingState: 0,
  totalPages: 0,
  count: 0,
  actionStatus: {
    status: 0,
    type: null,
    id: null,
  },
});

/* eslint-disable default-case, no-param-reassign */
const TeamTagsSlice = createSlice({
  name: 'TeamTags',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getTeamTagsListAct, (state, { payload }) => {
        state.loadingState = 1;
        if (payload.params && payload.params.page === 1) {
          teamTagsEntityAdapter.removeAll(state);
        }
      })
      .addCase(getTeamTagsListError, state => {
        state.loadingState = 3;
      })
      .addCase(getTeamTagsListSuccess, (state, { payload }) => {
        state.loadingState = 2;
        teamTagsEntityAdapter.upsertMany(state, payload.rows);
        state.totalPages = payload.totalPages;
        state.count = payload.count;
      })
      .addCase(deleteTeamTagsAct, state => {
        state.actionStatus.status = 1;
      })
      .addCase(deleteTeamTagsError, state => {
        state.actionStatus.status = 3;
      })
      .addCase(deleteTeamTagsSuccess, (state, { payload }) => {
        const { id } = payload;
        teamTagsEntityAdapter.removeOne(state, id);
        state.actionStatus.status = 2;
      })
      .addCase(updateTeamTagsAct, state => {
        state.actionStatus.status = 1;
      })
      .addCase(updateTeamTagsError, state => {
        state.actionStatus.status = 3;
      })
      .addCase(updateTeamTagsSuccess, (state, { payload }) => {
        const { id, name, updatedAt } = payload;
        state.actionStatus.status = 2;
        if (id && state.entities[id]) {
          teamTagsEntityAdapter.updateOne(state, {
            id,
            changes: {
              name,
              updatedAt,
            },
          });
        }
      })
      .addCase(createTeamTagsAct, state => {
        state.actionStatus.status = 1;
      })
      .addCase(createTeamTagsError, state => {
        state.actionStatus.status = 3;
      })
      .addCase(createTeamTagsSuccess, (state, { payload }) => {
        state.actionStatus.status = 2;
        teamTagsEntityAdapter.addOne(state, payload);
      })
      .addCase(updateActionStatusAct, (state, { payload }) => {
        state.actionStatus = payload;
      })
      .addCase(resetGetTeamTagsList, () => initialState);
  },
});

export default TeamTagsSlice.reducer;
