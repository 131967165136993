import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './reducer';
import { REDUCER_KEY } from './constants';
import notificationEntityAdapter from './entity-adapter';

/**
 * Direct selector to the notificationList state domain
 */

const selectNotificationListDomain = state =>
  state[REDUCER_KEY] || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by NotificationList
 */

const makeSelectNotificationList = () =>
  createSelector(selectNotificationListDomain, substate => substate);

// create entity adapter selector

export const {
  selectAll: selectAllNotifications,
  selectById: selectNotificationById,
  selectIds: selectNotificationIds,
  selectTotal: selectTotalNotifications,
  selectEntities: selectNotificationEntities,
} = notificationEntityAdapter.getSelectors(selectNotificationListDomain);

const selectLoadingState = createSelector(
  selectNotificationListDomain,
  substate => substate.loading,
);

const selectNotificationCount = createSelector(
  selectNotificationListDomain,
  substate => substate.notificationCount,
);

const selectCount = createSelector(
  selectNotificationListDomain,
  substate => substate.count,
);

const selectActiveNotificationIds = createSelector(
  selectNotificationListDomain,
  substate => substate.notificationIds,
);

const selectNotificationByCategory = createSelector(
  selectNotificationListDomain,
  substate => substate.summary,
);

const selectNotificationSummaryLoadingState = createSelector(
  selectNotificationListDomain,
  substate => substate.summaryLoading,
);
export default makeSelectNotificationList;
export {
  selectNotificationListDomain,
  selectLoadingState,
  selectNotificationCount,
  selectNotificationByCategory,
  selectNotificationSummaryLoadingState,
  selectActiveNotificationIds,
  selectCount,
};
