import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  createEventGroupApi,
  deleteEventGroupApi,
  getEventGroupListApi,
  updateEventGroupApi,
} from '../../api/event-group.api';
import { getErrorMessage } from '../../utils/error.util';
import {
  createEventGroupAct,
  createEventGroupErrorAct,
  createEventGroupSuccessAct,
  deleteEventGroupAct,
  deleteEventGroupErrorAct,
  deleteEventGroupSuccessAct,
  getEventGroupsAct,
  getEventGroupsErrorAct,
  getEventGroupsSuccessAct,
  getUnlinkedEventsAct,
  getUnlinkedEventsErrorAct,
  getUnlinkedEventsSuccessAct,
  resetUnlinkedEventsStateAct,
  updateEventGroupAct,
  updateEventGroupErrorAct,
  updateEventGroupSuccessAct,
} from './actions';
import { getUnlinkedEvents } from '../../api/event.api';
import { addMessage } from '../ToastMessagesManager/actions';
import messages from '../../components/EventGroup/messages';

function* fetchEventGroups({ payload }) {
  try {
    const { data } = yield call(getEventGroupListApi, payload);
    if (data) {
      yield put(getEventGroupsSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(addMessage({ message: errMessage, variant: 'error' }));
    yield put(getEventGroupsErrorAct());
  }
}

function* fetchUnlinkedEvents({ payload }) {
  try {
    const { data } = yield call(getUnlinkedEvents, payload);
    if (data) {
      yield put(getUnlinkedEventsSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(addMessage({ message: errMessage, variant: 'error' }));
    yield put(getUnlinkedEventsErrorAct());
  }
}

function* deleteEventGroup({ payload }) {
  const { id } = payload;

  try {
    const { data } = yield call(deleteEventGroupApi, payload);
    if (data) {
      yield put(deleteEventGroupSuccessAct({ id }));
      yield put(
        addMessage({ message: messages.deleteSuc, variant: 'success' }),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(addMessage({ message: errMessage, variant: 'error' }));
    yield put(deleteEventGroupErrorAct());
  }
}

function* updateEventGroup({ payload }) {
  const { id } = payload;

  try {
    const { data } = yield call(updateEventGroupApi, payload);
    if (data) {
      yield put(
        addMessage({ message: messages.updateSuc, variant: 'success' }),
      );
      yield put(
        updateEventGroupSuccessAct({
          id,
          name: data.name,
          updatedAt: data.updatedAt,
        }),
      );
      yield put(resetUnlinkedEventsStateAct());
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(addMessage({ message: errMessage, variant: 'error' }));
    yield put(updateEventGroupErrorAct());
  }
}

function* createEventGroup({ payload }) {
  try {
    const { data } = yield call(createEventGroupApi, payload);
    if (data) {
      yield put(
        addMessage({ message: messages.createSuc, variant: 'success' }),
      );
      yield put(createEventGroupSuccessAct());
      yield put(resetUnlinkedEventsStateAct());
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(addMessage({ message: errMessage, variant: 'error' }));
    yield put(createEventGroupErrorAct());
  }
}

// Individual exports for testing
export default function* TeamTagsSaga() {
  yield takeLatest(getEventGroupsAct.toString(), fetchEventGroups);
  yield takeLatest(getUnlinkedEventsAct.toString(), fetchUnlinkedEvents);
  yield takeEvery(deleteEventGroupAct.toString(), deleteEventGroup);
  yield takeEvery(updateEventGroupAct.toString(), updateEventGroup);
  yield takeEvery(createEventGroupAct.toString(), createEventGroup);
}
