import { Box, Typography } from '@mui/material';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { getRelativeDateFromNow } from '../../utils/date.util';
import messages from './messages';
import NotificationIgnoreButton from './NotificationIgnoreButton';

function DuplicateEmailNotification({
  email,
  createdAt,
  updatedAt,
  status,
  disableIgnore,
  onIgnore,
}) {
  const relativeDateInSecond = getRelativeDateFromNow(updatedAt || createdAt);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Typography variant="subtitle">
        <FormattedMessage
          id={messages.duplicateEmail.id}
          defaultMessage={messages.duplicateEmail.defaultMessage}
          values={{
            // eslint-disable-next-line react/no-unstable-nested-components
            emailEle: chunks => (
              <Typography component="span" fontWeight="bold">
                {chunks}
              </Typography>
            ),
            email,
          }}
        />
      </Typography>
      <Typography variant="caption">{relativeDateInSecond}</Typography>
      {status === 'active' && (
        <NotificationIgnoreButton
          onClickHandler={onIgnore}
          loading={disableIgnore}
        ></NotificationIgnoreButton>
      )}
    </Box>
  );
}

export default memo(DuplicateEmailNotification);
