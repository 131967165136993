/*
 *
 * TeamTags constants
 *
 */
export const REDUCER_KEY = 'TeamTags';

export const GET_TEAM_TAGS_LIST = 'app/TeamTags/GET_TEAM_TAGS_LIST';
export const GET_TEAM_TAGS_LIST_ERROR = 'app/TeamTags/GET_TEAM_TAGS_LIST_ERROR';
export const GET_TEAM_TAGS_LIST_SUCCESS =
  'app/TeamTags/GET_TEAM_TAGS_LIST_SUCCESS';
export const RESET_GET_TEAM_TAGS_LIST = 'app/TeamTags/RESET_GET_TEAM_TAGS_LIST';
export const DELETE_TEAM_TAGS = 'app/TeamTags/DELETE_TEAM_TAGS';
export const DELETE_TEAM_TAGS_ERROR = 'app/TeamTags/DELETE_TEAM_TAGS_ERROR';
export const DELETE_TEAM_TAGS_SUCCESS = 'app/TeamTags/DELETE_TEAM_TAGS_SUCCESS';
export const UPDATE_TEAM_TAGS = 'app/TeamTags/UPDATE_TEAM_TAGS';
export const UPDATE_TEAM_TAGS_ERROR = 'app/TeamTags/UPDATE_TEAM_TAGS_ERROR';
export const UPDATE_TEAM_TAGS_SUCCESS = 'app/TeamTags/UPDATE_TEAM_TAGS_SUCCESS';
export const CREATE_TEAM_TAGS = 'app/TeamTags/CREATE_TEAM_TAGS';
export const CREATE_TEAM_TAGS_ERROR = 'app/TeamTags/CREATE_TEAM_TAGS_ERROR';
export const CREATE_TEAM_TAGS_SUCCESS = 'app/TeamTags/CREATE_TEAM_TAGS_SUCCESS';
export const UPDATE_ACTION_STATUS = 'app/TeamTags/UPDATE_ACTION_STATUS';
