/**
 *
 * App
 *
 */

import { Alert, AppBar, CssBaseline } from '@mui/material';
import React, { memo } from 'react';
import { useRoutes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material/styles';
import { useInjectSaga } from 'redux-injectors';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useIntl } from 'react-intl';
import { ConfirmProvider } from 'material-ui-confirm';
import LoginManager from '../../managers/LoginManager';
import theme from '../../themes';
import { REDUCER_KEY } from '../../managers/AuthManager/constants';
import authSaga from '../../managers/AuthManager/saga';
import commonMessages from '../common/messages';

import roleRoutes from './role.routes';
import ToastMessagesManager from '../../managers/ToastMessagesManager';
import ToastMessages from '../ToastMessages/Loadable';

export function App() {
  useInjectSaga({ key: REDUCER_KEY, saga: authSaga });
  const intl = useIntl();
  const isProduction = process.env.REACT_APP_IS_PROD_BUILD === 'true';
  const routesElement = useRoutes(roleRoutes);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ConfirmProvider
          defaultOptions={{
            confirmationButtonProps: {
              autoFocus: true,
              color: 'primary',
              variant: 'contained',
            },
            cancellationButtonProps: {
              color: 'primary',
              variant: 'outlined',
            },
          }}
        >
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            preventDuplicate
          >
            <ToastMessagesManager>
              <ToastMessages />
            </ToastMessagesManager>
            {!isProduction && (
              <AppBar
                color="primary"
                sx={{
                  top: '0',
                  zIndex: theme.zIndex.drawer + 1,
                }}
                position="sticky"
              >
                <Alert
                  severity="warning"
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 0,
                  }}
                  variant="filled"
                >
                  {intl.formatMessage(commonMessages.sandboxWarn)}
                </Alert>
              </AppBar>
            )}
            <LoginManager>{routesElement}</LoginManager>
          </SnackbarProvider>
        </ConfirmProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default memo(App);
