import { Avatar, Box, Skeleton, Typography } from '@mui/material';
import React, { memo } from 'react';

function NotificationItemSkeleton() {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Skeleton variant="circular">
        <Avatar />
      </Skeleton>
      <Typography variant="subtitle" sx={{ flexGrow: 1 }}>
        <Skeleton variant="text" />
      </Typography>
    </Box>
  );
}

export default memo(NotificationItemSkeleton);
