export const ThemeData = {
  components: {
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(15, 32, 108, 0.10)',
          color: '#3A3541',
        },
      },
    },
    MUIDataTableSelectCell: {
      styleOverrides: {
        headerCell: {
          backgroundColor: 'rgba(15, 32, 108, 0.10)',
          color: '#3A3541',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#1976d2',
        },
        button: {
          color: '#1976d2',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Manrope, Helvetica, Arial, sans-serif',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          fontFamily: 'Manrope, Helvetica, Arial, sans-serif',
          backgroundColor: '#F4F4F4',
        },
      },
    },
  },
  palette: {
    text: {
      primary: '#424447',
      secondary: '#7D7A7A',
    },
    success: {
      main: '#61A534',
      light: 'rgba(97, 165, 52, 0.10)',
      contrastText: 'rgba(255,255,255,1)',
    },
    error: {
      main: '#d32f2f',
      light: 'rgb(239 83 80 / 21%)',
    },
    primary: {
      main: '#0F206C',
      light: 'rgba(15, 32, 108, 0.10)',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#E71C77',
      light: 'rgb(231 28 119 / 20%)',
      contrastText: 'rgba(255,255,255,1)',
    },
    background: {
      default: '#F4F4F4',
    },
    brandSecondary1: {
      main: '#ED874D',
      light: '#f9e5d9',
      contrastText: 'rgba(255,255,255,1)',
    },
    brandSecondary2: {
      main: '#E71C77',
      light: 'rgb(231 28 119 / 20%)',
      contrastText: 'rgba(255,255,255,1)',
    },
    brandSecondary3: {
      main: '#523790',
      light: '#dad5e6',
      contrastText: 'rgba(255,255,255,1)',
    },
    brandSecondary4: {
      main: '#EBC336',
      light: '#f9f1d4',
      contrastText: 'rgba(255,255,255,1)',
    },
    brandLightGrey: {
      light: '#DDDDDD',
      main: '#7D7A7A',
      contrastText: 'rgba(255,255,255,1)',
    },
    brandDarkGrey: {
      main: '#424447',
      contrastText: 'rgba(255,255,255,1)',
    },
    brandColor: {
      main: '#143C7D',
      light: '#436397',
      contrastText: '#ffffff',
      menuGradient: 'rgba(15, 32, 108, 0.08)',
    },
  },
};
