import { createManager } from 'redux-injectors';

import reducer from './reducer';

import { REDUCER_KEY } from './constants';

const UserPreferencesManager = createManager({
  name: 'UserPreferencesManager',
  key: REDUCER_KEY,
  reducer,
});

export default UserPreferencesManager;
