import { call, put, takeLatest } from 'redux-saga/effects';
import { getAnnualStatsAct } from './actions';
import { getErrorMessage } from '../../utils/error.util';
import { addMessage } from '../ToastMessagesManager/actions';
import { getAnnualStatisticsApi } from '../../api/event.api';

function* fetchAnnualStats({ payload }) {
  const { loading, params } = payload;
  if (loading === 1) {
    try {
      const { data } = yield call(getAnnualStatisticsApi, params);
      yield put(getAnnualStatsAct(2, params, data));
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      yield put(addMessage({ message: errorMessage, variant: 'error' }));
      yield put(getAnnualStatsAct(3, params));
    }
  }
}

// Individual exports for testing
export default function* annualStatsSaga() {
  yield takeLatest(getAnnualStatsAct.toString(), fetchAnnualStats);
}
