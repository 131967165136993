/*
 *
 * MemberDetail actions
 *
 */

import { createAction } from '@reduxjs/toolkit';
import {
  ASSIGN_GROUP,
  ASSIGN_GROUP_ERROR,
  ASSIGN_GROUP_SUCCESS,
  GET_MEMBER_DETAIL,
  GET_MEMBER_DETAIL_ERROR,
  GET_MEMBER_DETAIL_SUCCESS,
  REMOVE_MEAL,
  REMOVE_MEAL_ERROR,
  REMOVE_MEAL_SUCCESS,
  RESET_MEMBER_DETAIL,
  TRANSFER_MEAL,
  TRANSFER_MEAL_ERROR,
  TRANSFER_MEAL_SUCCESS,
  UPDATE_MEDICAL_CERT,
  UPDATE_MEDICAL_CERT_ERROR,
  UPDATE_MEDICAL_CERT_SUCCESS,
  UPDATE_PARENTAL_CONSENT,
  UPDATE_PARENTAL_CONSENT_ERROR,
  UPDATE_PARENTAL_CONSENT_SUCCESS,
  UPDATE_TAG_STATE,
  UPDATE_TAG_STATE_ERROR,
  UPDATE_TAG_STATE_SUCCESS,
  UPDATE_TSHIRT_SIZE,
  UPDATE_TSHIRT_SIZE_ERROR,
  UPDATE_TSHIRT_SIZE_SUCCESS,
} from './constants';

export const getMemberDetailAct = createAction(GET_MEMBER_DETAIL);
export const getMemberDetailErrorAct = createAction(GET_MEMBER_DETAIL_ERROR);
export const getMemberDetailSuccessAct = createAction(
  GET_MEMBER_DETAIL_SUCCESS,
);

export const updateMedCertAct = createAction(UPDATE_MEDICAL_CERT);
export const updateMedCertErrorAct = createAction(UPDATE_MEDICAL_CERT_ERROR);
export const updateMedCertSuccessAct = createAction(
  UPDATE_MEDICAL_CERT_SUCCESS,
);

export const updateParentalConsentAct = createAction(UPDATE_PARENTAL_CONSENT);
export const updateParentalConsentErrorAct = createAction(
  UPDATE_PARENTAL_CONSENT_ERROR,
);
export const updateParentalConsentSuccessAct = createAction(
  UPDATE_PARENTAL_CONSENT_SUCCESS,
);

export const updateTshirtSizeAct = createAction(UPDATE_TSHIRT_SIZE);
export const updateTshirtSizeErrorAct = createAction(UPDATE_TSHIRT_SIZE_ERROR);
export const updateTshirtSizeSuccessAct = createAction(
  UPDATE_TSHIRT_SIZE_SUCCESS,
);

export const updateTagStateAct = createAction(UPDATE_TAG_STATE);
export const updateTagStateErrorAct = createAction(UPDATE_TAG_STATE_ERROR);
export const updateTagStateSuccessAct = createAction(UPDATE_TAG_STATE_SUCCESS);

export const transferMealAct = createAction(TRANSFER_MEAL);
export const transferMealErrorAct = createAction(TRANSFER_MEAL_ERROR);
export const transferMealSuccessAct = createAction(TRANSFER_MEAL_SUCCESS);

export const removeMealAct = createAction(REMOVE_MEAL);
export const removeMealErrorAct = createAction(REMOVE_MEAL_ERROR);
export const removeMealSuccessAct = createAction(REMOVE_MEAL_SUCCESS);

export const assignGroupAct = createAction(ASSIGN_GROUP);
export const assignGroupErrorAct = createAction(ASSIGN_GROUP_ERROR);
export const assignGroupSuccessAct = createAction(ASSIGN_GROUP_SUCCESS);

export const resetMemberDetail = createAction(RESET_MEMBER_DETAIL);
