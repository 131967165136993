/* eslint-disable react/prop-types */
import React, { memo } from 'react';

import { Link, Link as RouterLink } from 'react-router-dom';

export function TeamLink({ eventId, id, displayName, eventGroupId }) {
  if (id) {
    const path = eventGroupId
      ? `/event-group/${eventGroupId}/events/${eventId}/teams/${id}`
      : `/event/${eventId}/teams/${id}`;
    return (
      <Link component={RouterLink} to={path}>
        {displayName}
      </Link>
    );
  }
  return null;
}

export default memo(TeamLink);
