/* eslint-disable no-param-reassign */
/*
 *
 * MemberDetail reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';
import {
  assignGroupAct,
  assignGroupErrorAct,
  assignGroupSuccessAct,
  getMemberDetailAct,
  getMemberDetailErrorAct,
  getMemberDetailSuccessAct,
  removeMealAct,
  removeMealErrorAct,
  removeMealSuccessAct,
  resetMemberDetail,
  transferMealAct,
  transferMealErrorAct,
  transferMealSuccessAct,
  updateMedCertAct,
  updateMedCertErrorAct,
  updateMedCertSuccessAct,
  updateParentalConsentAct,
  updateParentalConsentErrorAct,
  updateParentalConsentSuccessAct,
  updateTagStateAct,
  updateTagStateErrorAct,
  updateTagStateSuccessAct,
  updateTshirtSizeAct,
  updateTshirtSizeErrorAct,
  updateTshirtSizeSuccessAct,
} from './actions';

export const initialState = {
  loadingState: 0,
  member: null,
  memberCertState: 0,
  memberParentConsentState: 0,
  memberTshirtState: 0,
  mealUpdateState: 0,
  mealAction: '',
  groupChangeState: '',
};

const onMealUpdateStart = action => state => {
  state.mealAction = action;
  state.mealUpdateState = 1;
};

const onMealUpdateError = state => {
  state.mealUpdateState = 3;
  state.mealAction = '';
  // update state of the meal by id to 0
};

const removeMealSuccess = state => {
  state.member.meal = null;
  state.mealUpdateState = 2;
};

/* eslint-disable default-case, no-param-reassign */
const memberDetailSlice = createSlice({
  name: 'MemberDetail',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getMemberDetailAct, state => {
        state.loadingState = 1;
      })
      .addCase(getMemberDetailErrorAct, state => {
        state.loadingState = 3;
      })
      .addCase(getMemberDetailSuccessAct, (state, { payload }) => {
        state.loadingState = 2;
        state.member = payload;
      })
      .addCase(updateMedCertAct, (state, { payload }) => {
        const { data } = payload;
        state.member.medicalCertificateState = data.value;
        state.memberCertState = 1;
      })
      .addCase(updateMedCertErrorAct, (state, { payload }) => {
        const { oldValue } = payload;
        state.memberCertState = 3;
        state.member.medicalCertificateState = oldValue;
      })
      .addCase(updateMedCertSuccessAct, (state, { payload }) => {
        state.member = { ...state.member, ...payload };
        state.memberCertState = 2;
      })
      .addCase(updateParentalConsentAct, (state, { payload }) => {
        const { data } = payload;
        state.memberParentConsentState = 1;
        state.member.parentalConsentState = data.value;
      })
      .addCase(updateParentalConsentErrorAct, (state, { payload }) => {
        const { oldValue } = payload;
        state.memberParentConsentState = 3;
        state.member.parentalConsentState = oldValue;
      })
      .addCase(updateParentalConsentSuccessAct, (state, { payload }) => {
        state.member = { ...state.member, ...payload };
        state.memberParentConsentState = 2;
      })
      .addCase(updateTshirtSizeAct, (state, { payload }) => {
        const { data } = payload;
        state.memberTshirtState = 1;
        state.member.tshirtSize = data.value;
      })
      .addCase(updateTshirtSizeErrorAct, (state, { payload }) => {
        const { oldValue } = payload;
        state.memberTshirtState = 3;
        state.member.tshirtSize = oldValue;
      })
      .addCase(updateTshirtSizeSuccessAct, (state, { payload }) => {
        state.member = { ...state.member, ...payload };
        state.memberTshirtState = 2;
      })
      .addCase(updateTagStateAct, (state, { payload }) => {
        const { data } = payload;
        const { tagId } = data;
        const { member } = state;
        if (member.tags) {
          const tag = member.tags.find(tg => tg.id === tagId);
          if (tag) {
            tag.loadingState = 1;
          }
        }
        state.tagState = 1;
      })
      .addCase(updateTagStateErrorAct, (state, { payload }) => {
        const { tagId, isAssigned } = payload;
        const { member } = state;
        if (member.tags) {
          const tag = member.tags.find(tg => tg.id === tagId);
          if (tag) {
            tag.loadingState = 3;
            tag.status.active = isAssigned;
          }
        }
        state.tagState = 3;
      })
      .addCase(updateTagStateSuccessAct, (state, { payload }) => {
        const { memberTagId: tagId, active } = payload;
        const { member } = state;
        if (member.tags) {
          const tag = member.tags.find(tg => tg.id === tagId);
          if (tag) {
            tag.loadingState = 0;
            tag.status.active = active;
          }
        }
        state.tagState = 2;
      })
      .addCase(transferMealAct, onMealUpdateStart('transfer'))
      .addCase(transferMealErrorAct, onMealUpdateError)
      .addCase(transferMealSuccessAct, removeMealSuccess)
      .addCase(removeMealAct, onMealUpdateStart('delete'))
      .addCase(removeMealErrorAct, onMealUpdateError)
      .addCase(removeMealSuccessAct, removeMealSuccess)
      .addCase(assignGroupAct, (state, { payload }) => {
        state.groupAssign = 1;
        const { metadata } = payload;
        const { currentValue } = metadata;
        state.member = {
          ...state.member,
          team: {
            ...state.member.team,
            group: currentValue,
          },
        };
      })
      .addCase(assignGroupErrorAct, (state, { payload }) => {
        const { previousValue } = payload;
        state.member = {
          ...state.member,
          team: {
            ...state.member.team,
            group: previousValue,
          },
        };
        state.groupAssign = 3;
      })
      .addCase(assignGroupSuccessAct, (state, { payload }) => {
        state.groupAssign = 2;
        state.member = {
          ...state.member,
          team: {
            ...state.member.team,
            group: payload.group,
          },
        };
      })
      .addCase(resetMemberDetail, () => initialState);
  },
});

export default memberDetailSlice.reducer;
