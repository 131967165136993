/*
 * VolunteerDetail Messages
 *
 * This contains all the text for the VolunteerDetail container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.VolunteerDetail';

export default defineMessages({
  updateStatusSuc: {
    id: `${scope}.updateStatusSuc`,
    defaultMessage: 'Volunteer status is updated successfully',
  },
  resp: {
    id: `${scope}.resp`,
    defaultMessage: 'Responsible',
  },
  selectUser: {
    id: `${scope}.selectUser`,
    defaultMessage: 'Select a user',
  },
  selectGroup: {
    id: `${scope}.selectGroup`,
    defaultMessage: 'Select a group',
  },
  setRes: {
    id: `${scope}.setRes`,
    defaultMessage: 'Set the responsible',
  },
  noUser: {
    id: `${scope}.noUser`,
    defaultMessage: 'No users',
  },
  searchUsr: {
    id: `${scope}.searchUsr`,
    defaultMessage: 'Search users',
  },
  contact: {
    id: `${scope}.contact`,
    defaultMessage: 'Contact',
  },
  firstName: {
    id: `${scope}.firstName`,
    defaultMessage: 'First name',
  },
  lastName: {
    id: `${scope}.lastName`,
    defaultMessage: 'Last name',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email',
  },
  telephone: {
    id: `${scope}.telephone`,
    defaultMessage: 'Telephone',
  },
  dob: {
    id: `${scope}.dob`,
    defaultMessage: 'Date of birth',
  },
  age: {
    id: `${scope}.age`,
    defaultMessage: 'Age',
  },
  gender: {
    id: `${scope}.gender`,
    defaultMessage: 'Gender',
  },
  address: {
    id: `${scope}.address`,
    defaultMessage: 'Address',
  },
  address1: {
    id: `${scope}.address1`,
    defaultMessage: 'Additional address',
  },
  emContact: {
    id: `${scope}.emContact`,
    defaultMessage: 'Emergency contact',
  },
  postalCode: {
    id: `${scope}.postalCode`,
    defaultMessage: 'Postal code',
  },
  city: {
    id: `${scope}.city`,
    defaultMessage: 'City',
  },
  addInfo: {
    id: `${scope}.addInfo`,
    defaultMessage: 'Additional information',
  },
  furtherInfo: {
    id: `${scope}.furtherInfo`,
    defaultMessage: 'Further information',
  },
  regDate: {
    id: `${scope}.regDate`,
    defaultMessage: 'Registered date',
  },
  brief: {
    id: `${scope}.brief`,
    defaultMessage: 'Brief',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Status',
  },
  group: {
    id: `${scope}.group`,
    defaultMessage: 'Group',
  },
  parentConsent: {
    id: `${scope}.parentConsent`,
    defaultMessage: 'Parental consent',
  },
  linkVol: {
    id: `${scope}.linkVol`,
    defaultMessage: 'Linked volunteers',
  },
  assignGroup: {
    id: `${scope}.assignGroup`,
    defaultMessage: 'Assign group to the volunteer',
  },
  noGrp: {
    id: `${scope}.noGrp`,
    defaultMessage: 'No groups',
  },
  searchGrp: {
    id: `${scope}.searchGrp`,
    defaultMessage: 'Search group',
  },
  mission: {
    id: `${scope}.mission`,
    defaultMessage: 'Mission',
  },
  prefer: {
    id: `${scope}.prefer`,
    defaultMessage: 'Prefer',
  },
  assigned: {
    id: `${scope}.assigned`,
    defaultMessage: 'Assigned',
  },
  noVol: {
    id: `${scope}.noVol`,
    defaultMessage: 'No volunteers',
  },
  tags: {
    id: `${scope}.tags`,
    defaultMessage: 'Tags',
  },
  hintFrmGravity: {
    id: `${scope}.hintFrmGravity`,
    defaultMessage: 'Hint from gravity form',
  },
  updateMisStateSuc: {
    id: `${scope}.updateMisStateSuc`,
    defaultMessage: 'Mission state is updated successfully',
  },
  updateGrpSuc: {
    id: `${scope}.updateGrpSuc`,
    defaultMessage: 'A volunteer is assigned to group successfully',
  },
  removeGrpSuc: {
    id: `${scope}.removeGrpSuc`,
    defaultMessage: 'A volunteer is removed from group successfully',
  },
  updateUserSuc: {
    id: `${scope}.updateUserSuc`,
    defaultMessage: 'A volunteer is assigned to user successfully',
  },
  updateLicVerSuc: {
    id: `${scope}.updateLicVerSuc`,
    defaultMessage: 'License verification state is updated successfully',
  },
  updateParentConsentSuc: {
    id: `${scope}.updateParentConsentSuc`,
    defaultMessage: 'Parental consent state is updated successfully',
  },
  updateLinkVolSuc: {
    id: `${scope}.updateLinkVolSuc`,
    defaultMessage: 'Linked volunteers is updated successfully',
  },
  updateVolSuc: {
    id: `${scope}.updateVolSuc`,
    defaultMessage: 'Volunteer detail is updated successfully',
  },
  history: {
    id: `${scope}.history`,
    defaultMessage: 'History',
  },
  walkers: {
    id: `${scope}.walkers`,
    defaultMessage: 'Walkers',
  },
  volunteers: {
    id: `${scope}.volunteers`,
    defaultMessage: 'Volunteers',
  },
  supporters: {
    id: `${scope}.supporters`,
    defaultMessage: 'Supporters',
  },
  updateVolTitle: {
    id: `${scope}.updateVolTitle`,
    defaultMessage: 'Update volunteer',
  },
  emName: {
    id: `${scope}.emName`,
    defaultMessage: 'Emergency contact name',
  },
  emPhone: {
    id: `${scope}.emPhone`,
    defaultMessage: 'Emergency contact number',
  },
  source: {
    id: `${scope}.source`,
    defaultMessage: 'How did you find out about the Trailwalker?',
  },
  deleteVolunteerSuc: {
    id: `${scope}.deleteVolunteerSuc`,
    defaultMessage: 'Volunteer is deleted successfully',
  },
  confirmDelete: {
    id: `${scope}.confirmDelete`,
    defaultMessage: 'Delete volunteer?',
  },
  deleteInfo: {
    id: `${scope}.deleteInfo`,
    defaultMessage: 'Volunteer will be deleted permanently.',
  },
});
