/*
 *
 * UserPreferences actions
 *
 */

import { createAction } from '@reduxjs/toolkit';
import {
  RESET_FILTER,
  UPDATE_MEMBER_FILTER,
  UPDATE_TEAM_FILTER,
} from './constants';

export const updateTeamFilterAct = createAction(
  UPDATE_TEAM_FILTER,
  (filters, reset = false) => ({
    payload: {
      filters,
      reset,
    },
  }),
);
export const updateMemberFilterAct = createAction(
  UPDATE_MEMBER_FILTER,
  (filters, reset = false) => ({
    payload: {
      filters,
      reset,
    },
  }),
);

export const resetFilterAct = createAction(RESET_FILTER);
