import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  getNotificationAction,
  getNotificationSummaryAction,
  ignoreNotificationAction,
} from './actions';
import { addMessage } from '../ToastMessagesManager/actions';
import { getErrorMessage } from '../../utils/error.util';
import {
  getNotificationListApi,
  getNotificationsSummaryApi,
  ignoreNotificationApi,
} from '../../api/notification.api';
import messages from '../../components/NotificationList/messages';

function* fetchNotifications({ payload }) {
  const { params, loading } = payload;
  if (loading === 1) {
    try {
      const data = yield call(getNotificationListApi, params);
      yield put(getNotificationAction(2, params, data));
    } catch (error) {
      const errMessage = getErrorMessage(error);
      yield put(addMessage({ message: errMessage, variant: 'error' }));
      yield put(getNotificationAction(3));
    }
  }
}
function* fetchNotificationsByCategory({ payload }) {
  const { loading } = payload;
  if (loading === 1) {
    try {
      const data = yield call(getNotificationsSummaryApi);
      yield put(getNotificationSummaryAction(2, data));
    } catch (error) {
      yield put(getNotificationSummaryAction(3));
    }
  }
}

function* ignoreNotification({ payload }) {
  const { id, loading } = payload;
  if (loading === 1) {
    try {
      yield call(ignoreNotificationApi, id);
      yield put(
        addMessage({ message: messages.ignoreSuccess, variant: 'success' }),
      );
      yield put(ignoreNotificationAction(2, id));
    } catch (error) {
      const errMessage = getErrorMessage(error);
      yield put(addMessage({ message: errMessage, variant: 'error' }));
      yield put(ignoreNotificationAction(3, id));
    }
  }
}

// Individual exports for testing
export default function* notificationListSaga() {
  yield takeLatest(getNotificationAction.toString(), fetchNotifications);
  yield takeLatest(
    getNotificationSummaryAction.toString(),
    fetchNotificationsByCategory,
  );
  yield takeEvery(ignoreNotificationAction.toString(), ignoreNotification);
}
