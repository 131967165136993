import { Avatar, Box, Typography } from '@mui/material';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { stringAvatar } from '../../utils/common.util';
import { getRelativeDateFromNow } from '../../utils/date.util';
import messages from './messages';
import NotificationIgnoreButton from './NotificationIgnoreButton';
import TeamLink from '../common/TeamLink';

function OnlyMaleNotification({
  team,
  status,
  createdAt,
  updatedAt,
  onIgnore,
  disableIgnore,
}) {
  const { name, avatarUrl, id } = team;
  const eventId = team.event?.id;
  const eventGroupId = team.event?.eventGroupId;
  const teamAvatar = stringAvatar(name);
  const relativeDateInSecond = getRelativeDateFromNow(updatedAt || createdAt);
  //   const notificationText =
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Avatar sx={teamAvatar.sx} src={avatarUrl}>
        {teamAvatar.children}
      </Avatar>
      <Typography variant="subtitle">
        <FormattedMessage
          id={messages.onlyMale.id}
          defaultMessage={messages.onlyMale.defaultMessage}
          values={{
            // eslint-disable-next-line react/no-unstable-nested-components
            team: () => (
              <Typography component="span">
                <TeamLink
                  displayName={name}
                  id={id}
                  eventGroupId={eventGroupId}
                  eventId={eventId}
                />
              </Typography>
            ),
            name,
          }}
        />
      </Typography>
      <Typography variant="caption">{relativeDateInSecond}</Typography>
      {status === 'active' && (
        <NotificationIgnoreButton
          onClickHandler={onIgnore}
          loading={disableIgnore}
        ></NotificationIgnoreButton>
      )}
    </Box>
  );
}

export default memo(OnlyMaleNotification);
