/*
 *
 * VolunteerGroups actions
 *
 */

import { createAction } from '@reduxjs/toolkit';

import {
  CREATE_VOLUNTEER_GROUPS,
  CREATE_VOLUNTEER_GROUPS_ERROR,
  CREATE_VOLUNTEER_GROUPS_SUCCESS,
  DELETE_VOLUNTEER_GROUPS,
  DELETE_VOLUNTEER_GROUPS_ERROR,
  DELETE_VOLUNTEER_GROUPS_SUCCESS,
  GET_VOLUNTEER_GROUPS_LIST,
  GET_VOLUNTEER_GROUPS_LIST_ERROR,
  GET_VOLUNTEER_GROUPS_LIST_SUCCESS,
  RESET_GET_VOLUNTEER_GROUPS_LIST,
  UPDATE_ACTION_STATUS,
  UPDATE_VOLUNTEER_GROUPS,
  UPDATE_VOLUNTEER_GROUPS_ERROR,
  UPDATE_VOLUNTEER_GROUPS_SUCCESS,
} from './constants';

export const getVolunteerGroupsListAct = createAction(
  GET_VOLUNTEER_GROUPS_LIST,
);
export const getVolunteerGroupsListError = createAction(
  GET_VOLUNTEER_GROUPS_LIST_ERROR,
);
export const getVolunteerGroupsListSuccess = createAction(
  GET_VOLUNTEER_GROUPS_LIST_SUCCESS,
);
export const resetGetVolunteerGroupsList = createAction(
  RESET_GET_VOLUNTEER_GROUPS_LIST,
);
export const deleteVolunteerGroupsAct = createAction(DELETE_VOLUNTEER_GROUPS);
export const deleteVolunteerGroupsError = createAction(
  DELETE_VOLUNTEER_GROUPS_ERROR,
);
export const deleteVolunteerGroupsSuccess = createAction(
  DELETE_VOLUNTEER_GROUPS_SUCCESS,
);
export const updateVolunteerGroupsAct = createAction(UPDATE_VOLUNTEER_GROUPS);
export const updateVolunteerGroupsError = createAction(
  UPDATE_VOLUNTEER_GROUPS_ERROR,
);
export const updateVolunteerGroupsSuccess = createAction(
  UPDATE_VOLUNTEER_GROUPS_SUCCESS,
);
export const updateActionStatusAct = createAction(UPDATE_ACTION_STATUS);
export const createVolunteerGroupsAct = createAction(CREATE_VOLUNTEER_GROUPS);
export const createVolunteerGroupsError = createAction(
  CREATE_VOLUNTEER_GROUPS_ERROR,
);
export const createVolunteerGroupsSuccess = createAction(
  CREATE_VOLUNTEER_GROUPS_SUCCESS,
);
