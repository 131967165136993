import BaseApiInstance from './base';
const BASE_URL = '/notifications';

export const getNotificationListApi = async params => {
  const response = await BaseApiInstance.get(`${BASE_URL}`, { params });
  return response.data;
};

export const getNotificationsSummaryApi = async () => {
  const response = await BaseApiInstance.get(`${BASE_URL}/summary`);
  return response.data;
};

export const ignoreNotificationApi = async notificationId => {
  const response = await BaseApiInstance.patch(
    `${BASE_URL}/${notificationId}/ignore`,
  );
  return response.data;
};
