import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  ASSIGN_USER,
  GET_TEAM_DETAIL,
  SET_TEAM_BIB_NUMBER,
  UPDATE_MEDICAL_CERT,
  UPDATE_PARENTAL_CONSENT,
  UPDATE_START_TIME,
  UPDATE_TAG_STATE,
  UPDATE_TEAM_STATUS,
  UPDATE_WELCOME_STATUS,
} from './constants';
import {
  assignGroupApi,
  assignUserApi,
  getTeamDetail,
  setBibNumber,
  updateStartTimeApi,
  updateStatusApi,
  updateTagApi,
  updateWelcomeStatusApi,
} from '../../api/team.api';
import {
  getTeamDetailError,
  getTeamDetailSuccess,
  setTeamBibNumberError,
  setTeamBibNumberSuccess,
  updateTagStateSuccess,
  updateTagStateError,
  updateStartTimeSuccess,
  updateStartTimeError,
  updateTeamStatusSuccess,
  updateTeamStatusError,
  updateMedCertSuccessAct,
  updateMedCertErrorAct,
  updateParentalConsentSuccessAct,
  updateParentalConsentErrorAct,
  assignUserSuccessAct,
  assignUserErrorAct,
  updateWelcomeStatusSuccessAct,
  updateWelcomeStatusErrorAct,
  assignGroupAct,
  assignGroupErrorAct,
  assignGroupSuccessAct,
} from './actions';
import {
  updateMedicalCertificateApi,
  updateParentalConsentApi,
} from '../../api/member.api';
import { addMessage } from '../ToastMessagesManager/actions';
import { getErrorMessage } from '../../utils/error.util';
import messages from '../../components/EventTeamDetail/messages';

function* fetchEventDetail({ payload }) {
  try {
    const { data } = yield call(getTeamDetail, payload);
    if (data) {
      yield put(getTeamDetailSuccess(data));
    } else {
      yield put(getTeamDetailError({ message: 'Something went wrong' }));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(getTeamDetailError(errMessage));
  }
}

function* updateTeamBibNumber({ payload }) {
  try {
    const { data } = yield call(setBibNumber, payload);
    if (data) {
      yield put(setTeamBibNumberSuccess(data));
    } else {
      yield put(setTeamBibNumberError({ message: 'Something went wrong' }));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(setTeamBibNumberError(errMessage));
  }
}

function* updateStartTime({ payload }) {
  try {
    const { data } = yield call(updateStartTimeApi, payload);
    if (data) {
      yield put(updateStartTimeSuccess(data));
    } else {
      yield put(updateStartTimeError({ message: 'Something went wrong' }));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(updateStartTimeError(errMessage));
  }
}

function* updateWelcomeStatus({ payload }) {
  try {
    const { data } = yield call(updateWelcomeStatusApi, payload);
    if (data) {
      yield put(updateWelcomeStatusSuccessAct(data));
    } else {
      yield put(
        updateWelcomeStatusErrorAct({ message: 'Something went wrong' }),
      );
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(updateWelcomeStatusErrorAct(errMessage));
  }
}

function* updateStatus({ payload }) {
  try {
    const { data } = yield call(updateStatusApi, payload);
    if (data) {
      yield put(updateTeamStatusSuccess(data));
    } else {
      yield put(updateTeamStatusError({ message: 'Something went wrong' }));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(updateTeamStatusError(errMessage));
  }
}

function* updateTagState({ payload }) {
  const { data: payloadData } = payload;
  try {
    const { data } = yield call(updateTagApi, payload);
    if (data) {
      yield put(updateTagStateSuccess(data));
    } else {
      yield put(
        updateTagStateError({
          tagId: payloadData.missionId,
          active: !payloadData.active,
          error: 'Something went wrong',
        }),
      );
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(
      updateTagStateError({
        tagId: payloadData.missionId,
        active: !payloadData.active,
        error: errMessage,
      }),
    );
  }
}

function* updateMedCertificateState({ payload }) {
  const { id } = payload;
  try {
    const { data } = yield call(updateMedicalCertificateApi, payload);
    if (data) {
      yield put(updateMedCertSuccessAct({ member: data }));
    } else {
      yield put(
        updateMedCertErrorAct({
          id,
          error: 'Something went wrong',
        }),
      );
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(
      updateMedCertErrorAct({
        id,
        error: errMessage,
      }),
    );
  }
}

function* updateParentalConsentState({ payload }) {
  const { id } = payload;
  try {
    const { data } = yield call(updateParentalConsentApi, payload);
    if (data) {
      yield put(updateParentalConsentSuccessAct({ member: data }));
    } else {
      yield put(
        updateParentalConsentErrorAct({
          id,
          error: 'Something went wrong',
        }),
      );
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(
      updateParentalConsentErrorAct({
        id,
        error: errMessage,
      }),
    );
  }
}

function* updateAssignedUser({ payload }) {
  try {
    const { data } = yield call(assignUserApi, payload);
    if (data) {
      yield put(assignUserSuccessAct(data));
    } else {
      yield put(assignUserErrorAct('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(assignUserErrorAct(errMessage));
  }
}

function* updateAssignedGroup({ payload }) {
  const { metadata, id, data } = payload;
  try {
    const { data: result } = yield call(assignGroupApi, id, data);
    if (result) {
      yield put(assignGroupSuccessAct(result));
      // need to determine the assign or remove removeGrpSuc
      yield put(
        addMessage({ message: messages.updateGrpSuc, variant: 'success' }),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(addMessage({ message: errMessage, variant: 'error' }));
    yield put(assignGroupErrorAct(metadata));
  }
}

// Individual exports for testing
export default function* eventTeamDetailSaga() {
  yield takeLatest(GET_TEAM_DETAIL, fetchEventDetail);
  yield takeEvery(SET_TEAM_BIB_NUMBER, updateTeamBibNumber);
  yield takeEvery(UPDATE_TAG_STATE, updateTagState);
  yield takeLatest(UPDATE_MEDICAL_CERT, updateMedCertificateState);
  yield takeLatest(UPDATE_PARENTAL_CONSENT, updateParentalConsentState);
  yield takeLatest(UPDATE_START_TIME, updateStartTime);
  yield takeLatest(UPDATE_TEAM_STATUS, updateStatus);
  yield takeLatest(ASSIGN_USER, updateAssignedUser);
  yield takeLatest(UPDATE_WELCOME_STATUS, updateWelcomeStatus);
  yield takeEvery(assignGroupAct.toString(), updateAssignedGroup);
}
