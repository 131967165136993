import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  deleteMealApi,
  getMemberDetailApi,
  transferMealApi,
  updateMedicalCertificateApi,
  updateParentalConsentApi,
  updateTagApi,
  updateTshirtSizeApi,
} from '../../api/member.api';
import {
  getMemberDetailErrorAct,
  getMemberDetailSuccessAct,
  updateMedCertSuccessAct,
  updateMedCertErrorAct,
  updateParentalConsentSuccessAct,
  updateParentalConsentErrorAct,
  updateTshirtSizeSuccessAct,
  updateTshirtSizeErrorAct,
  updateTagStateSuccessAct,
  updateTagStateErrorAct,
  removeMealSuccessAct,
  removeMealErrorAct,
  transferMealSuccessAct,
  transferMealErrorAct,
  assignGroupAct,
  assignGroupSuccessAct,
  assignGroupErrorAct,
  getMemberDetailAct,
  updateMedCertAct,
  updateParentalConsentAct,
  updateTshirtSizeAct,
  updateTagStateAct,
  transferMealAct,
  removeMealAct,
} from './actions';
import { getErrorMessage } from '../../utils/error.util';
import { addMessage } from '../ToastMessagesManager/actions';
import { assignGroupApi } from '../../api/team.api';
import TeamMessages from '../../components/EventTeamDetail/messages';
import messages from '../../components/MemberDetail/messages';

function* fetchMemberDetail({ payload }) {
  try {
    const { data } = yield call(getMemberDetailApi, payload);
    if (data) {
      yield put(getMemberDetailSuccessAct(data));
    } else {
      yield put(getMemberDetailErrorAct({ message: 'Something went wrong' }));
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(addMessage({ message: errMessage, variant: 'error' }));
    yield put(getMemberDetailErrorAct(errMessage));
  }
}

function* updateMedCertificateState({ payload }) {
  try {
    const { data } = yield call(updateMedicalCertificateApi, payload);
    if (data) {
      yield put(
        addMessage({ message: messages.updateMedSuc, variant: 'success' }),
      );
      yield put(updateMedCertSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(addMessage({ message: errMessage, variant: 'error' }));
    yield put(
      updateMedCertErrorAct({ error: errMessage, oldValue: payload.oldValue }),
    );
  }
}

function* updateParentalConsentState({ payload }) {
  try {
    const { data } = yield call(updateParentalConsentApi, payload);
    if (data) {
      yield put(
        addMessage({
          message: messages.updateParentalConsentSuc,
          variant: 'success',
        }),
      );

      yield put(updateParentalConsentSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(addMessage({ message: errMessage, variant: 'error' }));
    yield put(
      updateParentalConsentErrorAct({
        error: errMessage,
        oldValue: payload.oldValue,
      }),
    );
  }
}

function* updateTshirtSize({ payload }) {
  try {
    const { data } = yield call(updateTshirtSizeApi, payload);
    if (data) {
      yield put(
        addMessage({
          message: messages.updateTshirtSuc,
          variant: 'success',
        }),
      );
      yield put(updateTshirtSizeSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(addMessage({ message: errMessage, variant: 'error' }));
    yield put(
      updateTshirtSizeErrorAct({
        error: errMessage,
        oldValue: payload.oldValue,
      }),
    );
  }
}

function* updateTagState({ payload }) {
  const { data: payloadData } = payload;
  try {
    const { data } = yield call(updateTagApi, payload);
    if (data) {
      yield put(updateTagStateSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(addMessage({ message: errMessage, variant: 'error' }));
    yield put(
      updateTagStateErrorAct({
        tagId: payloadData.tagId,
        active: !payloadData.active,
        error: errMessage,
      }),
    );
  }
}

function* transferMeal({ payload }) {
  try {
    const { data } = yield call(transferMealApi, payload);
    if (data) {
      // transfer message
      yield put(
        addMessage({ message: messages.transferMealSuc, variant: 'success' }),
      );
      yield put(transferMealSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(addMessage({ message: errMessage, variant: 'error' }));
    yield put(
      transferMealErrorAct({
        error: errMessage,
      }),
    );
  }
}

function* deleteMeal({ payload }) {
  const { id } = payload;
  try {
    const { data } = yield call(deleteMealApi, id);
    if (data) {
      yield put(
        addMessage({ message: messages.deleteMealSuc, variant: 'success' }),
      );
      yield put(removeMealSuccessAct());
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(addMessage({ message: errMessage, variant: 'error' }));
    yield put(
      removeMealErrorAct({
        error: errMessage,
      }),
    );
  }
}

function* updateAssignedGroup({ payload }) {
  const { metadata, id, data } = payload;
  try {
    const { data: result } = yield call(assignGroupApi, id, data);
    if (result) {
      yield put(assignGroupSuccessAct(result));
      // need to determine the assign or remove removeGrpSuc
      yield put(
        addMessage({ message: TeamMessages.updateGrpSuc, variant: 'success' }),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(addMessage({ message: errMessage, variant: 'error' }));
    yield put(assignGroupErrorAct(metadata));
  }
}

// Individual exports for testing
export default function* memberDetailSaga() {
  //  uncomment this to setp default action
  yield takeLatest(getMemberDetailAct.toString(), fetchMemberDetail);
  yield takeLatest(updateMedCertAct.toString(), updateMedCertificateState);
  yield takeLatest(
    updateParentalConsentAct.toString(),
    updateParentalConsentState,
  );
  yield takeLatest(updateTshirtSizeAct.toString(), updateTshirtSize);
  yield takeEvery(updateTagStateAct.toString(), updateTagState);
  yield takeLatest(transferMealAct.toString(), transferMeal);
  yield takeLatest(removeMealAct.toString(), deleteMeal);
  yield takeEvery(assignGroupAct.toString(), updateAssignedGroup);
}
