/*
 *
 * VolunteerTags constants
 *
 */
export const REDUCER_KEY = 'VolunteerTags';

export const GET_VOLUNTEER_TAGS_LIST =
  'app/VolunteerTags/GET_VOLUNTEER_TAGS_LIST';
export const GET_VOLUNTEER_TAGS_LIST_ERROR =
  'app/VolunteerTags/GET_VOLUNTEER_TAGS_LIST_ERROR';
export const GET_VOLUNTEER_TAGS_LIST_SUCCESS =
  'app/VolunteerTags/GET_VOLUNTEER_TAGS_LIST_SUCCESS';
export const RESET_GET_VOLUNTEER_TAGS_LIST =
  'app/VolunteerTags/RESET_GET_VOLUNTEER_TAGS_LIST';
export const DELETE_VOLUNTEER_TAGS = 'app/VolunteerTags/DELETE_VOLUNTEER_TAGS';
export const DELETE_VOLUNTEER_TAGS_ERROR =
  'app/VolunteerTags/DELETE_VOLUNTEER_TAGS_ERROR';
export const DELETE_VOLUNTEER_TAGS_SUCCESS =
  'app/VolunteerTags/DELETE_VOLUNTEER_TAGS_SUCCESS';
export const UPDATE_VOLUNTEER_TAGS = 'app/VolunteerTags/UPDATE_VOLUNTEER_TAGS';
export const UPDATE_VOLUNTEER_TAGS_ERROR =
  'app/VolunteerTags/UPDATE_VOLUNTEER_TAGS_ERROR';
export const UPDATE_VOLUNTEER_TAGS_SUCCESS =
  'app/VolunteerTags/UPDATE_VOLUNTEER_TAGS_SUCCESS';
export const UPDATE_ACTION_STATUS = 'app/VolunteerTags/UPDATE_ACTION_STATUS';
export const CREATE_VOLUNTEER_TAGS = 'app/VolunteerTags/CREATE_VOLUNTEER_TAGS';
export const CREATE_VOLUNTEER_TAGS_ERROR =
  'app/VolunteerTags/CREATE_VOLUNTEER_TAGS_ERROR';
export const CREATE_VOLUNTEER_TAGS_SUCCESS =
  'app/VolunteerTags/CREATE_VOLUNTEER_TAGS_SUCCESS';
