import { ExpandMore, Notifications, OpenInNew } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  IconButton,
  List,
  Menu,
  MenuItem,
  MenuList,
  styled,
  Typography,
} from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  selectNotificationByCategory,
  selectNotificationCount,
  selectNotificationSummaryLoadingState,
} from '../../managers/NotificationListManager/selectors';
import NotificationItem from '../NotificationList/NotificationItem';
import { getNotificationSummaryAction } from '../../managers/NotificationListManager/actions';
import messages from './messages';
import NotificationItemSkeleton from '../NotificationList/NotificationItemSkeleton';

const CustomAccordionSummary = styled(AccordionSummary)({
  '&.MuiAccordionSummary-root': {
    minHeight: '35px',
    justifyContent: 'flex-start',
  },
  '& .MuiAccordionSummary-content': {
    flexGrow: 0,
    '&.Mui-expanded': {
      margin: '0',
    },
  },
});

function NotificationDropdown() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const notificationCount = useSelector(selectNotificationCount);
  const notificationsByCategory = useSelector(selectNotificationByCategory);
  const loadingState = useSelector(selectNotificationSummaryLoadingState);

  const [anchorElNotification, setAnchorElNotification] = useState(null);
  const open = Boolean(anchorElNotification);

  const handleClickNotification = event => {
    setAnchorElNotification(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElNotification(null);
  };
  useEffect(() => {
    dispatch(getNotificationSummaryAction(1));
  }, [dispatch]);
  return (
    <>
      <IconButton
        size="large"
        aria-label={`show ${{ notificationCount }} new notifications`}
        color={notificationCount > 0 ? 'primary' : 'inherit'}
        onClick={handleClickNotification}
        aria-controls={open ? 'notifications-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        id="notification-icon"
      >
        <Badge badgeContent={notificationCount} color="error">
          <Notifications />
        </Badge>
      </IconButton>
      <Menu
        id="notifications-menu"
        anchorEl={anchorElNotification}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'notification-icon',
        }}
        sx={{ mt: '45px' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '8px 16px',
          }}
        >
          <Typography variant="h6">Notifications</Typography>
          <IconButton
            size="small"
            onClick={() => window.open('/notifications', '_blank')}
            sx={{ ml: 1 }}
          >
            <OpenInNew fontSize="small" />
          </IconButton>
        </div>
        <MenuList>
          {loadingState === 1 &&
            [1, 2, 3, 4].map(key => (
              <MenuItem key={key}>
                <NotificationItemSkeleton />
              </MenuItem>
            ))}
          {notificationsByCategory.map(category => (
            <Accordion
              key={category.ruleId}
              defaultExpanded
              elevation={0}
              sx={{ my: 0 }}
            >
              <CustomAccordionSummary
                expandIcon={<ExpandMore />}
                sx={{ justifyContent: 'flex-start' }}
              >
                <Typography variant="subtitle2">
                  {messages[category.ruleName]
                    ? intl.formatMessage(messages[category.ruleName])
                    : category.ruleName}
                </Typography>
              </CustomAccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <List>
                  {category.latest.map(notificationId => (
                    <MenuItem key={notificationId} onClick={handleClose}>
                      <NotificationItem id={notificationId} />
                    </MenuItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </MenuList>
      </Menu>
    </>
  );
}

export default memo(NotificationDropdown);
