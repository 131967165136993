/*
 *
 * AnnualStats actions
 *
 */

import { createAction } from '@reduxjs/toolkit';
import { GET_ANNUAL_STATS, RESET_ANNUAL_STATS } from './constants';

export const getAnnualStatsAct = createAction(
  GET_ANNUAL_STATS,
  (loading, params, data) => ({
    payload: { loading, data, params },
  }),
);
export const resetAnnualStatsAct = createAction(RESET_ANNUAL_STATS);
