/*
 *
 * EventTeamDetail reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';

import {
  getTeamDetailError,
  getTeamDetailSuccess,
  resetGetTeamDetail,
  getTeamDetail,
  setTeamBibNumberSuccess,
  setTeamBibNumber,
  setTeamBibNumberError,
  updateTagState,
  updateTagStateError,
  updateTagStateSuccess,
  updateStartTimeSuccess,
  updateStartTimeError,
  updateStartTime,
  updateTeamStatus,
  updateTeamStatusError,
  updateTeamStatusSuccess,
  updateMedCertAct,
  updateMedCertErrorAct,
  updateMedCertSuccessAct,
  updateParentalConsentAct,
  updateParentalConsentErrorAct,
  updateParentalConsentSuccessAct,
  assignUserAct,
  assignUserErrorAct,
  assignUserSuccessAct,
  updateWelcomeStatusAct,
  updateWelcomeStatusErrorAct,
  updateWelcomeStatusSuccessAct,
  assignGroupAct,
  assignGroupErrorAct,
  assignGroupSuccessAct,
} from './actions';

export const initialState = {
  loadingState: 0,
  error: '',
  team: null,
  setBibState: 0,
  setBibError: '',
  startTimeState: 0,
  tagState: 0,
  statusState: 0,
  memberCertState: 0,
  memberParentConsentState: 0,
  userAssign: 0,
  welcomeStatusState: 0,
  groupAssign: 0,
};

/* eslint-disable default-case, no-param-reassign */
const eventTeamDetailSlice = createSlice({
  name: 'EventTeamDetail',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getTeamDetail, state => {
        state.loadingState = 1;
        state.error = '';
      })
      .addCase(getTeamDetailError, (state, { payload }) => {
        state.loadingState = 3;
        state.error = payload;
      })
      .addCase(getTeamDetailSuccess, (state, { payload }) => {
        state.loadingState = 2;
        state.error = '';
        state.team = payload;
      })
      .addCase(setTeamBibNumber, state => {
        state.setBibState = 1;
        state.error = '';
      })
      .addCase(setTeamBibNumberError, (state, { payload }) => {
        state.setBibState = 3;
        state.error = payload;
      })
      .addCase(setTeamBibNumberSuccess, (state, { payload }) => {
        state.setBibState = 2;
        state.error = '';
        state.team.bibNumber = payload.bibNumber;
      })
      .addCase(updateStartTime, state => {
        state.startTimeState = 1;
        state.error = '';
      })
      .addCase(updateStartTimeError, (state, { payload }) => {
        state.startTimeState = 3;
        state.error = payload;
      })
      .addCase(updateStartTimeSuccess, (state, { payload }) => {
        state.startTimeState = 2;
        state.error = '';
        state.team.startTime = payload.startTime;
      })
      .addCase(updateTeamStatus, state => {
        state.statusState = 1;
        state.error = '';
      })
      .addCase(updateTeamStatusError, (state, { payload }) => {
        state.statusState = 3;
        state.error = payload;
      })
      .addCase(updateTeamStatusSuccess, (state, { payload }) => {
        state.statusState = 2;
        state.error = '';
        state.team.status = payload.status;
      })
      .addCase(assignUserAct, state => {
        state.error = '';
        state.userAssign = 1;
      })
      .addCase(assignUserErrorAct, (state, { payload }) => {
        state.error = payload;
        state.userAssign = 3;
      })
      .addCase(assignUserSuccessAct, (state, { payload }) => {
        state.team = { ...state.team, ...payload };
        state.userAssign = 2;
        state.error = '';
      })
      .addCase(updateWelcomeStatusAct, state => {
        state.error = '';
        state.welcomeStatusState = 1;
      })
      .addCase(updateWelcomeStatusErrorAct, (state, { payload }) => {
        state.error = payload;
        state.welcomeStatusState = 3;
      })
      .addCase(updateWelcomeStatusSuccessAct, (state, { payload }) => {
        state.team = { ...state.team, ...payload };
        state.welcomeStatusState = 2;
        state.error = '';
      })
      .addCase(updateTagState, (state, { payload }) => {
        const { data } = payload;
        const { tagId } = data;
        const { team } = state;
        if (team.tags) {
          const tag = team.tags.find(tg => tg.id === tagId);
          if (tag) {
            tag.loadingState = 1;
          }
        }
        state.error = '';
        state.tagState = 1;
      })
      .addCase(updateTagStateError, (state, { payload }) => {
        const { tagId, error, isAssigned } = payload;
        const { team } = state;
        if (team.tags) {
          const tag = team.tags.find(tg => tg.id === tagId);
          if (tag) {
            tag.loadingState = 3;
            tag.status.active = isAssigned;
          }
        }
        state.tagState = 3;
        state.error = error;
      })
      .addCase(updateTagStateSuccess, (state, { payload }) => {
        const { teamTagId: tagId, active } = payload;
        const { team } = state;
        if (team.tags) {
          const tag = team.tags.find(tg => tg.id === tagId);
          if (tag) {
            tag.loadingState = 0;
            tag.status.active = active;
          }
        }
        state.tagState = 2;
        state.error = '';
      })
      .addCase(updateMedCertAct, (state, { payload }) => {
        const { id } = payload;
        const { team } = state;
        if (team.members) {
          const memb = team.members.find(mem => mem.id === id);
          if (memb) {
            memb.loadingState = 1;
          }
        }
        state.memberCertState = 1;
        state.error = '';
      })
      .addCase(updateMedCertErrorAct, (state, { payload }) => {
        const { id, error } = payload;
        const { team } = state;
        if (team.members) {
          const member = team.members.find(memb => memb.id === id);
          if (member) {
            member.loadingState = 3;
          }
        }
        state.memberCertState = 3;
        state.error = error;
      })
      .addCase(updateMedCertSuccessAct, (state, { payload }) => {
        const { member: dbMember } = payload;
        const { team } = state;
        if (team.members) {
          team.members = team.members.map(memb => {
            if (memb.id === dbMember.id) {
              memb.loadingState = 0;
              return { ...memb, ...dbMember };
            }
            return memb;
          });
        }
        state.memberCertState = 2;
        state.error = '';
      })
      .addCase(updateParentalConsentAct, (state, { payload }) => {
        const { id } = payload;
        const { team } = state;
        if (team.members) {
          const memb = team.members.find(mem => mem.id === id);
          if (memb) {
            memb.parentalLoadingState = 1;
          }
        }
        state.memberParentConsentState = 1;
        state.error = '';
      })
      .addCase(updateParentalConsentErrorAct, (state, { payload }) => {
        const { id, error } = payload;
        const { team } = state;
        if (team.members) {
          const member = team.members.find(memb => memb.id === id);
          if (member) {
            member.parentalLoadingState = 3;
          }
        }
        state.memberParentConsentState = 3;
        state.error = error;
      })
      .addCase(updateParentalConsentSuccessAct, (state, { payload }) => {
        const { member: dbMember } = payload;
        const { team } = state;
        if (team.members) {
          team.members = team.members.map(memb => {
            if (memb.id === dbMember.id) {
              memb.parentalLoadingState = 0;
              return { ...memb, ...dbMember };
            }
            return memb;
          });
        }
        state.memberParentConsentState = 2;
        state.error = '';
      })
      .addCase(assignGroupAct, (state, { payload }) => {
        state.groupAssign = 1;
        const { metadata } = payload;
        const { currentValue } = metadata;
        state.team = { ...state.team, group: currentValue };
      })
      .addCase(assignGroupErrorAct, (state, { payload }) => {
        const { previousValue } = payload;
        state.team = { ...state.team, group: previousValue };
        state.groupAssign = 3;
      })
      .addCase(assignGroupSuccessAct, (state, { payload }) => {
        state.groupAssign = 2;
        state.team = { ...state.team, ...payload };
      })
      .addCase(resetGetTeamDetail, () => initialState);
  },
});

export default eventTeamDetailSlice.reducer;
