/*
 *
 * TeamGroupList reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';
import groupsEntityAdapter from './entity-adapter';

import {
  createTeamGroupsAct,
  createTeamGroupsError,
  createTeamGroupsSuccess,
  deleteTeamGroupsAct,
  deleteTeamGroupsError,
  deleteTeamGroupsSuccess,
  getTeamGroupsListAct,
  getTeamGroupsListError,
  getTeamGroupsListSuccess,
  resetGetTeamGroupsList,
  updateActionStatusAct,
  updateTeamGroupsAct,
  updateTeamGroupsError,
  updateTeamGroupsSuccess,
} from './actions';

export const initialState = groupsEntityAdapter.getInitialState({
  loadingState: 0,
  totalPages: 0,
  count: 0,
  actionStatus: {
    status: 0,
    type: null,
    id: null,
  },
});

/* eslint-disable default-case, no-param-reassign */
const teamGroupListSlice = createSlice({
  name: 'TeamGroupList',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getTeamGroupsListAct, (state, { payload }) => {
        state.loadingState = 1;
        if (payload.params && payload.params.page === 1) {
          groupsEntityAdapter.removeAll(state);
        }
      })
      .addCase(getTeamGroupsListError, (state, { payload }) => {
        state.loadingState = 3;
        state.error = payload;
      })
      .addCase(getTeamGroupsListSuccess, (state, { payload }) => {
        state.loadingState = 2;
        groupsEntityAdapter.upsertMany(state, payload.rows);
        state.totalPages = payload.totalPages;
        state.count = payload.count;
      })
      .addCase(deleteTeamGroupsAct, state => {
        state.actionStatus.status = 1;
      })
      .addCase(deleteTeamGroupsError, state => {
        state.actionStatus.status = 3;
      })
      .addCase(deleteTeamGroupsSuccess, (state, { payload }) => {
        const { id } = payload;
        state.actionStatus.status = 2;
        groupsEntityAdapter.removeOne(state, id);
      })
      .addCase(updateTeamGroupsAct, state => {
        state.actionStatus.status = 1;
      })
      .addCase(updateTeamGroupsError, state => {
        state.actionStatus.status = 3;
      })
      .addCase(updateTeamGroupsSuccess, (state, { payload }) => {
        const { id, name, updatedAt, description } = payload;
        state.actionStatus.status = 2;
        if (id && state.entities[id]) {
          groupsEntityAdapter.updateOne(state, {
            id,
            changes: {
              name,
              description,
              updatedAt,
            },
          });
        }
      })
      .addCase(createTeamGroupsAct, state => {
        state.actionStatus.status = 1;
      })
      .addCase(createTeamGroupsError, state => {
        state.actionStatus.status = 3;
      })
      .addCase(createTeamGroupsSuccess, (state, { payload }) => {
        state.actionStatus.status = 2;
        groupsEntityAdapter.addOne(state, payload);
      })
      .addCase(updateActionStatusAct, (state, { payload }) => {
        state.actionStatus = payload;
      })
      .addCase(resetGetTeamGroupsList, () => initialState);
  },
});

export default teamGroupListSlice.reducer;
