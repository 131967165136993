import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  createMemberTagApi,
  deleteMemberTagApi,
  getMemberTagsApi,
  updateMemberTagApi,
} from '../../api/member-tag.api';

import {
  getMemberTagsListErrorAct,
  getMemberTagsListSuccessAct,
  deleteMemberTagsErrorAct,
  deleteMemberTagsSuccessAct,
  updateMemberTagsSuccessAct,
  updateMemberTagsErrorAct,
  createMemberTagsErrorAct,
  createMemberTagsSuccessAct,
  getMemberTagsListAct,
  updateMemberTagsAct,
  createMemberTagsAct,
  deleteMemberTagsAct,
} from './actions';
import { getErrorMessage } from '../../utils/error.util';
import { addMessage } from '../ToastMessagesManager/actions';
import messages from '../../components/MemberTags/messages';

function* fetchMemberTags({ payload }) {
  try {
    const { data } = yield call(getMemberTagsApi, payload.params);
    if (data) {
      yield put(getMemberTagsListSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(getMemberTagsListErrorAct(errMessage));
    yield put(addMessage({ message: errMessage, variant: 'error' }));
  }
}

function* deleteMemberTags({ payload }) {
  const { id } = payload;

  try {
    const { data } = yield call(deleteMemberTagApi, id);
    if (data) {
      yield put(deleteMemberTagsSuccessAct({ id }));
      yield put(
        addMessage({ message: messages.deleteSuccess, variant: 'success' }),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(deleteMemberTagsErrorAct(errMessage));
    yield put(addMessage({ message: errMessage, variant: 'error' }));
  }
}

function* updateMemberTags({ payload }) {
  const { id } = payload;

  try {
    const { data } = yield call(updateMemberTagApi, id, payload.data);
    if (data) {
      yield put(
        updateMemberTagsSuccessAct({
          id,
          name: data.name,
          updatedAt: data.updatedAt,
        }),
      );
      yield put(
        addMessage({ message: messages.updateSuccess, variant: 'success' }),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(updateMemberTagsErrorAct(errMessage));
    yield put(addMessage({ message: errMessage, variant: 'error' }));
  }
}

function* createMemberTags({ payload }) {
  try {
    const { data } = yield call(createMemberTagApi, payload.data);
    if (data) {
      yield put(createMemberTagsSuccessAct(data));
      yield put(
        addMessage({ message: messages.createSuccess, variant: 'success' }),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(createMemberTagsErrorAct(errMessage));
    yield put(addMessage({ message: errMessage, variant: 'error' }));
  }
}

// Individual exports for testing
export default function* memberTagsSaga() {
  yield takeLatest(getMemberTagsListAct.toString(), fetchMemberTags);
  yield takeEvery(deleteMemberTagsAct.toString(), deleteMemberTags);
  yield takeEvery(updateMemberTagsAct.toString(), updateMemberTags);
  yield takeEvery(createMemberTagsAct.toString(), createMemberTags);
}
