import { Avatar, Box, Typography } from '@mui/material';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { stringAvatar } from '../../utils/common.util';
import { getRelativeDateFromNow } from '../../utils/date.util';
import messages from './messages';
import NotificationIgnoreButton from './NotificationIgnoreButton';
import TeamMemberLink from '../common/TeamMemberLink';

function WithoutCoupon({
  member,
  createdAt,
  updatedAt,
  status,
  disableIgnore,
  onIgnore,
}) {
  const { displayName, avatarUrl, team, id } = member;
  const eventId = team?.event?.id;
  const eventGroupId = team?.event?.eventGroupId;
  const memberAvatar = stringAvatar(displayName);
  const relativeDateInSecond = getRelativeDateFromNow(updatedAt || createdAt);
  //   const notificationText =
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Avatar sx={memberAvatar.sx} src={avatarUrl}>
        {memberAvatar.children}
      </Avatar>
      <Typography variant="subtitle">
        <FormattedMessage
          id={messages.withoutCoupon.id}
          defaultMessage={messages.withoutCoupon.defaultMessage}
          values={{
            // eslint-disable-next-line react/no-unstable-nested-components
            member: () => (
              <Typography component="span">
                <TeamMemberLink
                  displayName={displayName}
                  id={id}
                  eventId={eventId}
                  eventGroupId={eventGroupId}
                ></TeamMemberLink>
              </Typography>
            ),
            name: displayName,
          }}
        />
      </Typography>
      <Typography variant="caption">{relativeDateInSecond}</Typography>
      {status === 'active' && (
        <NotificationIgnoreButton
          onClickHandler={onIgnore}
          loading={disableIgnore}
        ></NotificationIgnoreButton>
      )}
    </Box>
  );
}

export default memo(WithoutCoupon);
