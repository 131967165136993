import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { FormattedDate, useIntl } from 'react-intl';
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Link,
  Menu,
  MenuItem,
  Typography,
  Hidden,
} from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { makeSelectPageTitle } from '../../managers/AuthManager/selectors';
import messages from './messages';
import commonMessages from '../common/messages';
import { makeSelectEvent } from '../../managers/EventDetailManager/selectors';
import {
  makeSelectTeamBibNumber,
  makeSelectTeamId,
  makeSelectTeamName,
  makeSelectTeamRegistrationDate,
  makeSelectTeamStatus,
  makeSelectTeamStatusState,
} from '../../managers/EventTeamDetailManager/selectors';
import { updateTeamStatus } from '../../managers/EventTeamDetailManager/actions';
import {
  formDisplayName,
  getColorBasedOnStatus,
} from '../../utils/common.util';
import { makeSelectVolunteer } from '../../managers/VolunteerDetailManager/selectors';
import { makeSelectMember } from '../../managers/MemberDetailManager/selectors';

import {
  TEAM_STATUS_OPTIONS as teamStatusOptions,
  getLabelOfSelectedTeamStatus,
} from '../../utils/options.util';
import { ActiveEventInfo } from './ActiveEventInfo';
import {
  makeSelectSupporterDisplayName,
  makeSelectSupporterTeamDetail,
} from '../../managers/SupporterDetailManager/selectors';
import ActiveEventGroupInfo from './ActiveEventGroupInfo';

export function DynamicHeaderTitle() {
  const pageKey = useSelector(makeSelectPageTitle());
  const intl = useIntl();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { id, eventId, supporterId } = useParams();
  const { pathname } = useLocation();
  const isEventGroupRoute = pathname.includes('event-group');
  const actualEventId = isEventGroupRoute ? eventId : id;
  const eventResourcePathPrefix = isEventGroupRoute
    ? `/event-group/${id}/events/${actualEventId}`
    : `/events/${actualEventId}`;
  const eventDetail = useSelector(makeSelectEvent());
  const teamName = useSelector(makeSelectTeamName());
  const teamId = useSelector(makeSelectTeamId());
  const teamRegistrationDate = useSelector(makeSelectTeamRegistrationDate());
  const teamBibNumber = useSelector(makeSelectTeamBibNumber());
  const teamActualStatus = useSelector(makeSelectTeamStatus());
  const verifyState = useSelector(makeSelectTeamStatusState());

  const [teamStatus, setTeamStatus] = useState('');
  const [verificationAnchorEl, setVerificationAnchorEl] = useState(null);
  const openVerificationMenu = Boolean(verificationAnchorEl);

  const volunteerDetail = useSelector(makeSelectVolunteer);
  const supporterDisplayName = useSelector(
    makeSelectSupporterDisplayName(supporterId),
  );
  const supporterTeamDetail = useSelector(
    makeSelectSupporterTeamDetail(supporterId),
  );
  const memberDetail = useSelector(makeSelectMember());

  const openVerificationSelection = event => {
    setVerificationAnchorEl(event.currentTarget);
  };

  const handleVerificationItemClick = state => {
    setTeamStatus(state);
    setVerificationAnchorEl(null);
    dispatch(
      updateTeamStatus({
        id: teamId,
        data: {
          value: state,
        },
      }),
    );
  };

  const handleVerificationClose = () => {
    setVerificationAnchorEl(null);
  };

  useEffect(() => {
    if (verifyState === 2) {
      enqueueSnackbar(intl.formatMessage(messages.updateTeamStatusSuccess), {
        variant: 'success',
      });
    } else if (verifyState === 3) {
      setTeamStatus(teamActualStatus);
    }
  }, [verifyState, teamActualStatus, enqueueSnackbar, intl]);

  useEffect(() => {
    setTeamStatus(teamActualStatus);
  }, [teamActualStatus]);

  switch (pageKey) {
    case 'event.list':
      return (
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
          fontWeight="bold"
        >
          {intl.formatMessage(messages.welcomeText)}
        </Typography>
      );
    case 'notifications':
      return (
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
          fontWeight="bold"
        >
          {intl.formatMessage(messages.notifications)}
        </Typography>
      );
    case 'event.home':
      return (
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
          fontWeight="bold"
        >
          {intl.formatMessage(messages.eventHome)}
        </Typography>
      );
    case 'event.teamlisting':
      return (
        <>
          <Hidden mdDown>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              fontWeight="bold"
            >
              {intl.formatMessage(messages.eventTeamList)}
            </Typography>
          </Hidden>
          <ActiveEventInfo></ActiveEventInfo>
        </>
      );
    case 'event.volunteerlisting':
      return (
        <>
          <Hidden mdDown>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              fontWeight="bold"
            >
              {intl.formatMessage(messages.eventVolunteerList)}
            </Typography>
          </Hidden>
          <ActiveEventInfo></ActiveEventInfo>
        </>
      );
    case 'event.memberlisting':
      return (
        <>
          <Hidden mdDown>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              fontWeight="bold"
            >
              {intl.formatMessage(messages.eventMemberList)}
            </Typography>
          </Hidden>
          <ActiveEventInfo></ActiveEventInfo>
        </>
      );
    case 'event.supporterListing':
      return (
        <>
          <Hidden mdDown>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              fontWeight="bold"
            >
              {intl.formatMessage(messages.eventSupporterList)}
            </Typography>
          </Hidden>
          <ActiveEventInfo></ActiveEventInfo>
        </>
      );
    case 'event.volunteer.detail':
      return (
        <>
          {volunteerDetail && eventDetail && (
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                component={RouterLink}
                to={`${eventResourcePathPrefix}/volunteers`}
                color="inherit"
              >
                {intl.formatMessage(messages.eventVolunteerList)}
              </Link>
              <Typography
                component="h1"
                variant="h6"
                noWrap
                fontWeight="bold"
                color="text.primary"
              >
                {volunteerDetail &&
                  formDisplayName(
                    volunteerDetail.firstName,
                    '',
                    volunteerDetail.lastName,
                  )}
              </Typography>
            </Breadcrumbs>
          )}
          <ActiveEventInfo></ActiveEventInfo>
        </>
      );
    case 'event.supporter.detail':
      return (
        <>
          {supporterDisplayName && supporterTeamDetail && eventDetail && (
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                component={RouterLink}
                to={`${eventResourcePathPrefix}/teams/${supporterTeamDetail.id}`}
                color="inherit"
              >
                {supporterTeamDetail.name}
              </Link>
              <Typography
                component="h1"
                variant="h6"
                noWrap
                fontWeight="bold"
                color="text.primary"
              >
                {supporterDisplayName}
              </Typography>
            </Breadcrumbs>
          )}
          <ActiveEventInfo></ActiveEventInfo>
        </>
      );
    case 'event.member.detail':
      return (
        <>
          {memberDetail && (
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                component={RouterLink}
                to={`${eventResourcePathPrefix}/teams/${memberDetail.team.id}`}
                color="inherit"
              >
                {memberDetail.team.name}
              </Link>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Avatar src={memberDetail.avatarUrl}></Avatar>
                <Typography
                  component="h1"
                  variant="h6"
                  noWrap
                  fontWeight="bold"
                  color="text.primary"
                >
                  {formDisplayName(
                    memberDetail.firstName,
                    '',
                    memberDetail.lastName,
                  )}
                </Typography>
              </Box>
            </Breadcrumbs>
          )}
          <ActiveEventInfo></ActiveEventInfo>
        </>
      );
    case 'event.team.detail':
      return (
        <>
          <div>
            {pageKey === 'event.team.detail' && teamRegistrationDate ? (
              <Typography
                variant="caption"
                color="primary.main"
                noWrap
                sx={{ letterSpacing: '0.04em' }}
              >
                <FormattedDate
                  value={teamRegistrationDate}
                  day="numeric"
                  month="long"
                  year="numeric"
                />
              </Typography>
            ) : null}
            <Box sx={{ display: 'flex' }}>
              <Typography
                component="span"
                variant="h6"
                color="inherit"
                noWrap
                fontWeight="bold"
              >
                {teamName}
              </Typography>
              {pageKey === 'event.team.detail' && teamBibNumber ? (
                <Hidden mdDown>
                  <Typography
                    component="span"
                    variant="h6"
                    color="inherit"
                    noWrap
                  >
                    / {intl.formatMessage(messages.bib)} {teamBibNumber}
                  </Typography>
                </Hidden>
              ) : null}
              {/* team detail status change */}
              {teamStatus ? (
                <Box sx={{ ml: 1 }}>
                  <Button
                    disableRipple
                    onClick={openVerificationSelection}
                    color={getColorBasedOnStatus(teamStatus)}
                    variant="contained"
                    size="small"
                    aria-haspopup="listbox"
                    aria-controls="team-verify-menu"
                    aria-label="Team verification workflow"
                    aria-expanded={openVerificationMenu ? 'true' : undefined}
                    disabled={verifyState === 1}
                  >
                    <span>
                      {messages[getLabelOfSelectedTeamStatus(teamStatus)]
                        ? intl.formatMessage(
                            messages[getLabelOfSelectedTeamStatus(teamStatus)],
                          )
                        : teamStatus}
                    </span>
                    {verifyState === 1 ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <EditOutlined fontSize="small" />
                    )}
                  </Button>
                  <Menu
                    id="team-verify-menu"
                    anchorEl={verificationAnchorEl}
                    open={openVerificationMenu}
                    onClose={handleVerificationClose}
                    MenuListProps={{
                      role: 'listbox',
                    }}
                  >
                    {teamStatusOptions.map(option => (
                      <MenuItem
                        key={option.val}
                        selected={teamStatus === option.val}
                        onClick={() => handleVerificationItemClick(option.val)}
                        color="danger"
                      >
                        {intl.formatMessage(commonMessages[option.label])}
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              ) : null}
            </Box>
          </div>

          <ActiveEventInfo></ActiveEventInfo>
        </>
      );
    default:
      if (pageKey.startsWith('eventgroup')) {
        return (
          <>
            <Hidden mdDown>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                fontWeight="bold"
              >
                {intl.formatMessage(messages.eventHome)}
              </Typography>
            </Hidden>
            <ActiveEventGroupInfo></ActiveEventGroupInfo>
          </>
        );
      }
      return (
        <Hidden mdDown>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
            fontWeight="bold"
          >
            {intl.formatMessage(messages.welcomeText)}
          </Typography>
        </Hidden>
      );
  }
}
