import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  getTeamTagsListError,
  getTeamTagsListSuccess,
  deleteTeamTagsError,
  deleteTeamTagsSuccess,
  updateTeamTagsSuccess,
  updateTeamTagsError,
  createTeamTagsError,
  createTeamTagsSuccess,
  getTeamTagsListAct,
  deleteTeamTagsAct,
  updateTeamTagsAct,
  createTeamTagsAct,
} from './actions';
import {
  createTeamTagApi,
  deleteTeamTagApi,
  getTeamTagsApi,
  updateTeamTagApi,
} from '../../api/team-tag.api';
import { getErrorMessage } from '../../utils/error.util';
import { addMessage } from '../ToastMessagesManager/actions';
import messages from '../../components/TeamTags/messages';
function* fetchTeamTags({ payload }) {
  try {
    const { data } = yield call(getTeamTagsApi, payload.params);
    if (data) {
      yield put(getTeamTagsListSuccess(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(getTeamTagsListError(errMessage));
    yield put(addMessage({ message: errMessage, variant: 'error' }));
  }
}

function* deleteTeamTags({ payload }) {
  const { id } = payload;

  try {
    const { data } = yield call(deleteTeamTagApi, id);
    if (data) {
      yield put(deleteTeamTagsSuccess({ id }));
      yield put(
        addMessage({ message: messages.deleteSuccess, variant: 'success' }),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(deleteTeamTagsError(errMessage));
    yield put(addMessage({ message: errMessage, variant: 'error' }));
  }
}

function* updateTeamTags({ payload }) {
  const { id } = payload;

  try {
    const { data } = yield call(updateTeamTagApi, id, payload.data);
    if (data) {
      yield put(
        updateTeamTagsSuccess({
          id,
          name: data.name,
          updatedAt: data.updatedAt,
        }),
      );
      yield put(
        addMessage({ message: messages.updateSuccess, variant: 'success' }),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(updateTeamTagsError(errMessage));
    yield put(addMessage({ message: errMessage, variant: 'error' }));
  }
}

function* createTeamTags({ payload }) {
  try {
    const { data } = yield call(createTeamTagApi, payload.data);
    if (data) {
      yield put(createTeamTagsSuccess(data));
      yield put(
        addMessage({ message: messages.createSuccess, variant: 'success' }),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(createTeamTagsError(errMessage));
    yield put(addMessage({ message: errMessage, variant: 'error' }));
  }
}

// Individual exports for testing
export default function* TeamTagsSaga() {
  //  uncomment this to setp default action
  yield takeLatest(getTeamTagsListAct.toString(), fetchTeamTags);
  yield takeEvery(deleteTeamTagsAct.toString(), deleteTeamTags);
  yield takeEvery(updateTeamTagsAct.toString(), updateTeamTags);
  yield takeEvery(createTeamTagsAct.toString(), createTeamTags);
}
