/*
 * VolunteerGroups Messages
 *
 * This contains all the text for the VolunteerGroups container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.VolunteerGroups';

export default defineMessages({
  createSuccess: {
    id: `${scope}.createSuccess`,
    defaultMessage: 'Group is created successfully',
  },
  updateSuccess: {
    id: `${scope}.updateSuccess`,
    defaultMessage: 'Group is updated successfully',
  },
  deleteSuccess: {
    id: `${scope}.deleteSuccess`,
    defaultMessage: 'Group is deleted successfully',
  },
  firstToAdd: {
    id: `${scope}.firstToAdd`,
    defaultMessage: 'Be first to add volunteer group',
  },
  create: {
    id: `${scope}.create`,
    defaultMessage: 'Create group',
  },
  update: {
    id: `${scope}.update`,
    defaultMessage: 'Update group',
  },
});
