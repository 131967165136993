/*
 *
 * NotificationList constants
 *
 */
export const REDUCER_KEY = 'NotificationList';
export const GET_NOTIFICATION_LIST =
  'app/NotificationList/GET_NOTIFICATION_LIST';
export const GET_NOTIFICATION_SUMMARY =
  'app/NotificationList/GET_NOTIFICATION_SUMMARY';
export const IGNORE_NOTIFICATION_ITEM =
  'app/NotificationList/IGNORE_NOTIFICATION_ITEM';
export const RESET_NOTIFICATION = 'app/NotificationList/RESET_NOTIFICATION';
