import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './reducer';
import { REDUCER_KEY } from './constants';

/**
 * Direct selector to the auth state domain
 */

const selectAuthDomain = state => state[REDUCER_KEY] || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by Auth
 */

const makeSelectAuth = () =>
  createSelector(selectAuthDomain, substate => substate);

const makeSelectAuthToken = () =>
  createSelector(selectAuthDomain, substate => substate.token);

const makeSelectLoggedInState = () =>
  createSelector(selectAuthDomain, substate => substate.loggedInState);

const makeSelectUser = () =>
  createSelector(selectAuthDomain, substate => substate.user);

const makeSelectPageTitle = () =>
  createSelector(selectAuthDomain, substate => substate.pageTitle);

const makeSelectViewRole = createSelector(
  selectAuthDomain,
  substate => substate.viewRole,
);

const makeSelectHasSupporterModule = createSelector(
  selectAuthDomain,
  substate => substate.hasSupporterModule,
);

const makeSelectHasVolunteerModule = createSelector(
  selectAuthDomain,
  substate => substate.hasVolunteerModule,
);

export const selectHasMealModule = createSelector(
  selectAuthDomain,
  substate => substate.hasMealModule,
);

export const selectHasHostingModule = createSelector(
  selectAuthDomain,
  substate => substate.hasHostingModule,
);

export const selectHasHubSpotModule = createSelector(
  selectAuthDomain,
  substate => substate.hasHubSpotModule,
);

export const selectHasTeamGroupModule = createSelector(
  selectAuthDomain,
  substate => substate.hasTeamGroupModule,
);

export const selectAllowDepartureTime = createSelector(
  selectAuthDomain,
  substate => substate.allowDepartureTime,
);
export const selectClient = createSelector(
  selectAuthDomain,
  substate => substate.client,
);
export const selectHasBikeModule = createSelector(
  selectAuthDomain,
  substate => substate.hasBikeModule,
);
export const selectHasCapModule = createSelector(
  selectAuthDomain,
  substate => substate.hasCapModule,
);
export const selectHasFTTRILicenseModule = createSelector(
  selectAuthDomain,
  substate => substate.hasFTTRILicenseModule,
);
export const selectHasNotificationModule = createSelector(
  selectAuthDomain,
  substate => substate.hasNotification,
);
export const selectHasGlobalDashboard = createSelector(
  selectAuthDomain,
  substate => substate.hasGlobalDashboard,
);
const makeSelectRoleId = () =>
  createSelector(makeSelectUser(), user => {
    if (user && user.role) {
      return user.role.id;
    }
    return null;
  });

export default makeSelectAuth;
export {
  selectAuthDomain,
  makeSelectAuthToken,
  makeSelectLoggedInState,
  makeSelectUser,
  makeSelectPageTitle,
  makeSelectRoleId,
  makeSelectViewRole,
  makeSelectHasSupporterModule,
  makeSelectHasVolunteerModule,
};
