import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  createVolunteerTagApi,
  deleteVolunteerTagApi,
  getVolunteerTagsApi,
  updateVolunteerTagApi,
} from '../../api/volunteer-tag.api';

import {
  getVolunteerTagsListError,
  getVolunteerTagsListSuccess,
  deleteVolunteerTagsError,
  deleteVolunteerTagsSuccess,
  updateVolunteerTagsSuccess,
  updateVolunteerTagsError,
  createVolunteerTagsError,
  createVolunteerTagsSuccess,
  getVolunteerTagsList,
  createVolunteerTagsAct,
  updateVolunteerTagsAct,
  deleteVolunteerTagsAct,
} from './actions';
import { getErrorMessage } from '../../utils/error.util';
import { addMessage } from '../ToastMessagesManager/actions';
import messages from '../../components/VolunteerTags/messages';
function* fetchVolunteerTags({ payload }) {
  try {
    const { data } = yield call(getVolunteerTagsApi, payload.params);
    if (data) {
      yield put(getVolunteerTagsListSuccess(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(getVolunteerTagsListError(errMessage));
    yield put(addMessage({ message: errMessage, variant: 'error' }));
  }
}

function* deleteVolunteerTags({ payload }) {
  const { id } = payload;

  try {
    const { data } = yield call(deleteVolunteerTagApi, id);
    if (data) {
      yield put(deleteVolunteerTagsSuccess({ id }));
      yield put(
        addMessage({ message: messages.deleteSuccess, variant: 'success' }),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(deleteVolunteerTagsError(errMessage));
    yield put(addMessage({ message: errMessage, variant: 'error' }));
  }
}

function* updateVolunteerTags({ payload }) {
  const { id } = payload;

  try {
    const { data } = yield call(updateVolunteerTagApi, id, payload.data);
    if (data) {
      yield put(
        updateVolunteerTagsSuccess({
          id,
          name: data.name,
          updatedAt: data.updatedAt,
        }),
      );
      yield put(
        addMessage({ message: messages.updateSuccess, variant: 'success' }),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(updateVolunteerTagsError(errMessage));
    yield put(addMessage({ message: errMessage, variant: 'error' }));
  }
}

function* createVolunteerTags({ payload }) {
  try {
    const { data } = yield call(createVolunteerTagApi, payload.data);
    if (data) {
      yield put(createVolunteerTagsSuccess(data));
      yield put(
        addMessage({ message: messages.createSuccess, variant: 'success' }),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(createVolunteerTagsError(errMessage));
    yield put(addMessage({ message: errMessage, variant: 'error' }));
  }
}

// Individual exports for testing
export default function* volunteerTagsSaga() {
  yield takeLatest(getVolunteerTagsList.toString(), fetchVolunteerTags);
  yield takeEvery(deleteVolunteerTagsAct.toString(), deleteVolunteerTags);
  yield takeEvery(updateVolunteerTagsAct.toString(), updateVolunteerTags);
  yield takeEvery(createVolunteerTagsAct.toString(), createVolunteerTags);
}
