/*
 *
 * HubSpotLogs constants
 *
 */
export const REDUCER_KEY = 'HubSpotLogs';
export const GET_LOGS = 'app/HubSpotLogs/GET_LOGS';
export const GET_LOGS_SUCCESS = 'app/HubSpotLogs/GET_LOGS_SUCCESS';
export const GET_LOGS_ERROR = 'app/HubSpotLogs/GET_LOGS_ERROR';

export const RESET_HUB_SPOT_LOGS = 'app/HubSpotLogs/RESET_HUB_SPOT_LOGS';
