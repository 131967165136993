// for arc no need of Postponed

export const TEAM_STATUS_OPTIONS = [
  {
    label: 'confirmed',
    val: 'Confirmed',
  },
  {
    label: 'dropped',
    val: 'Dropped',
  },
  ...(process.env.CLIENT !== 'arc'
    ? [
        {
          label: 'postponed',
          val: 'Postponed',
        },
      ]
    : []),
];

export const WELCOME_CALL_STATUS_OPTIONS = [
  {
    label: 'unknown',
    val: 'Unknown',
  },
  {
    label: 'wctodo',
    val: 'WC to do',
  },
  {
    label: 'inprogress',
    val: 'In progress',
  },
  {
    label: 'wcdone',
    val: 'WC done',
  },
  {
    label: 'wetodo',
    val: 'WE to do',
  },
  {
    label: 'wedone',
    val: 'WE done',
  },
  {
    label: 'notneeded',
    val: 'Not needed',
  },
];

export const VERIFY_OPTIONS = [
  {
    label: 'notVer',
    val: 'Not verified',
  },
  {
    label: 'ver',
    val: 'Verified',
  },
];

export const MED_CERT_OPTIONS = [
  {
    label: 'notVer',
    val: 'Not verified',
  },
  {
    label: 'inprogress',
    val: 'In progress',
  },
  {
    label: 'pending',
    val: 'Pending',
  },
  {
    label: 'refused',
    val: 'Refused',
  },
  {
    label: 'ver',
    val: 'Verified',
  },
  {
    label: 'verOxfam',
    val: 'Verified Oxfam',
  },
];

export const TSHIRT_SIZE_OPTIONS = [
  {
    label: 'sizeXS',
    val: 'XS',
  },
  {
    label: 'sizeS',
    val: 'S',
  },
  {
    label: 'sizeM',
    val: 'M',
  },
  {
    label: 'sizeL',
    val: 'L',
  },
  {
    label: 'sizeXL',
    val: 'XL',
  },
  {
    label: 'sizeNC',
    val: 'NC',
  },
];

export const WORKFLOW_OPTIONS = [
  {
    label: 'pending',
    val: 'Pending',
  },
  {
    label: 'confirmed',
    val: 'Confirmed',
  },
  {
    label: 'canceled',
    val: 'Canceled',
  },
  {
    label: 'onboarded',
    val: 'Onboarded',
  },
];

export const GENDER_OPTIONS = [
  {
    label: 'male',
    val: 'male',
  },
  {
    label: 'female',
    val: 'female',
  },
  {
    label: 'neutral',
    val: 'neutral',
  },
];

export const USER_ROLES = [
  {
    label: 'GlobalAdmin',
    val: 1,
  },
  {
    label: 'Admin',
    val: 2,
  },
  {
    label: 'AllOnboarder',
    val: 3,
  },
  {
    label: 'WalkerOnboarder',
    val: 4,
  },
  {
    label: 'SupporterOnboarder',
    val: 5,
  },
];
export const MEAL_TYPES = [
  {
    label: 'nonveg',
    val: 'Non vegetarian',
    id: 1,
  },
  {
    label: 'veg',
    val: 'Vegetarian',
    id: 2,
  },
  {
    label: 'aperitif',
    val: 'Solidarity aperitif',
    id: 4,
  },
  {
    label: 'nomeal',
    val: 'None',
    id: 3,
  },
  {
    label: 'mealNotBooked',
    val: 'not booked',
    id: -1,
  },
];

export const getLabelOfSelectedTeamStatus = val => {
  const teamStatus = TEAM_STATUS_OPTIONS.find(status => status.val === val);
  if (teamStatus) {
    return teamStatus.label;
  }
  return val;
};

export const getLabelOfWelcomeStatus = val => {
  const welcomeStatus = WELCOME_CALL_STATUS_OPTIONS.find(
    status => status.val === val,
  );
  if (welcomeStatus) {
    return welcomeStatus.label;
  }
  return val;
};

export const getLabelOfVerifyState = val => {
  const verifyState = VERIFY_OPTIONS.find(status => status.val === val);
  if (verifyState) {
    return verifyState.label;
  }
  return val;
};

export const getLabelOfTshirtSize = val => {
  const certState = TSHIRT_SIZE_OPTIONS.find(status => status.val === val);
  if (certState) {
    return certState.label;
  }
  return val;
};

export const getLabelOfWorkflowState = val => {
  const workflow = WORKFLOW_OPTIONS.find(status => status.val === val);
  if (workflow) {
    return workflow.label;
  }
  return val;
};

export const getLabelOfMedCertState = val => {
  const certState = MED_CERT_OPTIONS.find(status => status.val === val);
  if (certState) {
    return certState.label;
  }
  return val;
};

export const getLabelOfUserRole = val => {
  const roleMap = USER_ROLES.find(role => role.val === val);
  if (roleMap) {
    return roleMap.label;
  }
  return val;
};

export const getLabelOfMeal = val => {
  const mealType = MEAL_TYPES.find(role => role.val === val);
  if (mealType) {
    return mealType.label;
  }
  return val;
};
