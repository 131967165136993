import { takeLatest, takeEvery, call, put } from 'redux-saga/effects';
import {
  createVolunteerGroupApi,
  deleteVolunteerGroupApi,
  getVolunteerGroupsApi,
  updateVolunteerGroupApi,
} from '../../api/volunteer-group.api';

import {
  getVolunteerGroupsListError,
  getVolunteerGroupsListSuccess,
  deleteVolunteerGroupsError,
  deleteVolunteerGroupsSuccess,
  updateVolunteerGroupsSuccess,
  updateVolunteerGroupsError,
  createVolunteerGroupsError,
  createVolunteerGroupsSuccess,
  getVolunteerGroupsListAct,
  deleteVolunteerGroupsAct,
  updateVolunteerGroupsAct,
  createVolunteerGroupsAct,
} from './actions';
import { getErrorMessage } from '../../utils/error.util';
import { addMessage } from '../ToastMessagesManager/actions';
import messages from '../../components/VolunteerGroups/messages';

function* fetchVolunteerGroups({ payload }) {
  try {
    const { data } = yield call(getVolunteerGroupsApi, payload.params);
    if (data) {
      yield put(getVolunteerGroupsListSuccess(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(getVolunteerGroupsListError(errMessage));
    yield put(addMessage({ message: errMessage, variant: 'error' }));
  }
}

function* deleteVolunteerGroups({ payload }) {
  const { id } = payload;

  try {
    const { data } = yield call(deleteVolunteerGroupApi, id);
    if (data) {
      yield put(deleteVolunteerGroupsSuccess({ id }));
      yield put(
        addMessage({ message: messages.deleteSuccess, variant: 'success' }),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(deleteVolunteerGroupsError(errMessage));
    yield put(addMessage({ message: errMessage, variant: 'error' }));
  }
}

function* updateVolunteerGroups({ payload }) {
  const { id } = payload;

  try {
    const { data } = yield call(updateVolunteerGroupApi, id, payload.data);
    if (data) {
      yield put(
        updateVolunteerGroupsSuccess({
          id,
          name: data.name,
          description: data.description,
          updatedAt: data.updatedAt,
        }),
      );
      yield put(
        addMessage({ message: messages.updateSuccess, variant: 'success' }),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(updateVolunteerGroupsError(errMessage));
    yield put(addMessage({ message: errMessage, variant: 'error' }));
  }
}

function* createVolunteerGroups({ payload }) {
  try {
    const { data } = yield call(createVolunteerGroupApi, payload.data);
    if (data) {
      yield put(createVolunteerGroupsSuccess(data));
      yield put(
        addMessage({ message: messages.createSuccess, variant: 'success' }),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(createVolunteerGroupsError(errMessage));
    yield put(addMessage({ message: errMessage, variant: 'error' }));
  }
}

// Individual exports for testing
export default function* volunteerGroupsSaga() {
  //  uncomment this to setp default action
  yield takeLatest(getVolunteerGroupsListAct.toString(), fetchVolunteerGroups);
  yield takeEvery(deleteVolunteerGroupsAct.toString(), deleteVolunteerGroups);
  yield takeEvery(updateVolunteerGroupsAct.toString(), updateVolunteerGroups);
  yield takeEvery(createVolunteerGroupsAct.toString(), createVolunteerGroups);
}
