import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './reducer';
import { REDUCER_KEY } from './constants';

/**
 * Direct selector to the volunteerDetail state domain
 */

const selectVolunteerDetailDomain = state => state[REDUCER_KEY] || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by VolunteerDetail
 */

const makeSelectVolunteerDetail = () =>
  createSelector(selectVolunteerDetailDomain, substate => substate);

const makeSelectVolunteer = createSelector(
  selectVolunteerDetailDomain,
  substate => substate.volunteer,
);

const selectVolunteerEditData = createSelector(
  makeSelectVolunteer,
  volunteerDetail => ({
    firstName: volunteerDetail.firstName,
    lastName: volunteerDetail.lastName,
    dateOfBirth: volunteerDetail.dateOfBirth,
    gender: volunteerDetail.gender,
    telephone: volunteerDetail.telephone,
    address: volunteerDetail.address,
    address1: volunteerDetail.address1,
    city: volunteerDetail.city,
    zip: volunteerDetail.zip,
    emergencyContactName: volunteerDetail.emergencyContactName,
    emergencyContactNumber: volunteerDetail.emergencyContactNumber,
    additionalInformation: volunteerDetail.additionalInformation,
    briefTime: volunteerDetail.briefTime,
    email: volunteerDetail.email,
    id: volunteerDetail.id,
  }),
);

const selectVolunteerContactDetail = createSelector(
  makeSelectVolunteer,
  volunteerDetail => ({
    firstName: volunteerDetail.firstName,
    lastName: volunteerDetail.lastName,
    dateOfBirth: volunteerDetail.dateOfBirth,
    gender: volunteerDetail.gender,
    telephone: volunteerDetail.telephone,
    address: volunteerDetail.address,
    address1: volunteerDetail.address1,
    city: volunteerDetail.city,
    zip: volunteerDetail.zip,
    emergencyContactName: volunteerDetail.emergencyContactName,
    emergencyContactNumber: volunteerDetail.emergencyContactNumber,
    additionalInformation: volunteerDetail.additionalInformation,
    briefTime: volunteerDetail.briefTime,
    email: volunteerDetail.email,
    id: volunteerDetail.id,
    registeredDate: volunteerDetail.registeredDate,
  }),
);

export const selectVolunteerHistory = createSelector(
  makeSelectVolunteer,
  volunteerDetail => ({
    asWalkers: volunteerDetail.asWalkers,
    asVolunteer: volunteerDetail.asVolunteer,
    asSupporter: volunteerDetail.asSupporter,
  }),
);

export const selectVolunteerMission = createSelector(
  makeSelectVolunteer,
  volunteerDetail => ({
    missions: volunteerDetail.missions,
    id: volunteerDetail.id,
  }),
);

export const selectVolunteerTags = createSelector(
  makeSelectVolunteer,
  volunteerDetail => ({
    tags: volunteerDetail.tags,
    id: volunteerDetail.id,
  }),
);

export const selectVolunteerAdditionalInfo = createSelector(
  makeSelectVolunteer,
  volunteerDetail => ({
    assignedUser: volunteerDetail.assignedUser,
    licenseVerification: volunteerDetail.licenseVerification,
    parentalConsentState: volunteerDetail.parentalConsentState,
    dateOfBirth: volunteerDetail.dateOfBirth,
    group: volunteerDetail.group,
    relatedTo: volunteerDetail.relatedTo,
    relatedFrom: volunteerDetail.relatedFrom,
    briefTime: volunteerDetail.briefTime,
    id: volunteerDetail.id,
    canDrive: volunteerDetail.canDrive,
    eventGroupId: volunteerDetail.event?.eventGroup?.id,
    source: volunteerDetail.source,
    hintRelation: volunteerDetail.hintRelation,
    eventId: volunteerDetail.eventId,
  }),
);

export const selectAdminActionInfo = createSelector(
  makeSelectVolunteer,
  volunteerDetail => ({
    id: volunteerDetail.id,
    verificationState: volunteerDetail.verificationState,
    assignedUser: volunteerDetail.assignedUser,
  }),
);

const makeSelectError = () =>
  createSelector(selectVolunteerDetailDomain, substate => substate.error);

const makeSelectMissionState = () =>
  createSelector(
    selectVolunteerDetailDomain,
    substate => substate.missionState,
  );

const makeSelectLinkedState = () =>
  createSelector(selectVolunteerDetailDomain, substate => substate.linkedState);

const makeSelectTagState = () =>
  createSelector(selectVolunteerDetailDomain, substate => substate.tagState);

const makeSelectCanDriveState = createSelector(
  selectVolunteerDetailDomain,
  substate => substate.canDriveState,
);

const makeSelectUserAssign = createSelector(
  selectVolunteerDetailDomain,
  substate => substate.userAssign,
);

const makeSelectGroupAssign = createSelector(
  selectVolunteerDetailDomain,
  substate => substate.groupAssign,
);

const makeSelectVerifyState = createSelector(
  selectVolunteerDetailDomain,
  substate => substate.verifyState,
);

const makeSelectLoadingState = () =>
  createSelector(
    selectVolunteerDetailDomain,
    substate => substate.loadingState,
  );

const makeSelectUpdateState = createSelector(
  selectVolunteerDetailDomain,
  substate => substate.updateState,
);

const makeSelectDeleteState = createSelector(
  selectVolunteerDetailDomain,
  substate => substate.deleteState,
);

const makeSelectParentalConsentState = createSelector(
  selectVolunteerDetailDomain,
  substate => substate.parentalConsentState,
);

const makeSelectLinkedVolunteerState = createSelector(
  selectVolunteerDetailDomain,
  substate => substate.linkVolunteerState,
);

export default makeSelectVolunteerDetail;
export {
  selectVolunteerDetailDomain,
  makeSelectVolunteer,
  makeSelectError,
  makeSelectLoadingState,
  makeSelectMissionState,
  makeSelectLinkedState,
  makeSelectTagState,
  makeSelectCanDriveState,
  makeSelectUserAssign,
  makeSelectGroupAssign,
  makeSelectVerifyState,
  makeSelectUpdateState,
  makeSelectParentalConsentState,
  makeSelectLinkedVolunteerState,
  makeSelectDeleteState,
  selectVolunteerEditData,
  selectVolunteerContactDetail,
};
