/*
 *
 * NotificationList reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';

import {
  getNotificationAction,
  getNotificationSummaryAction,
  ignoreNotificationAction,
} from './actions';
import adapter from './entity-adapter';

export const initialState = adapter.getInitialState({
  loading: 0,
  count: 0,
  page: 0,
  summary: [],
  notificationCount: 0,
  summaryLoading: 0,
  notificationIds: [],
});

/* eslint-disable default-case, no-param-reassign */
const notificationListSlice = createSlice({
  name: 'NotificationList',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getNotificationAction, (state, { payload }) => {
        const { data, loading } = payload;
        switch (loading) {
          case 1:
            state.loading = 1;
            break;
          case 2:
            state.loading = 2;
            adapter.addMany(state, data.rows);
            state.notificationIds = data.rows.map(
              notification => notification.id,
            );
            state.count = data.count;
            break;
          case 3:
            state.loading = 3;
            break;
          default:
            break;
        }
      })
      .addCase(getNotificationSummaryAction, (state, { payload }) => {
        const { data, loading } = payload;
        switch (loading) {
          case 1:
            state.summaryLoading = 1;
            break;
          case 2:
            {
              state.summaryLoading = 2;
              let notificationCount = 0;
              const categories = [];
              // loop through data and calculate sum and also upsert the latest
              data.forEach(category => {
                notificationCount += category.total;
                if (category.total > 0 && category.latest.length > 0) {
                  categories.push({
                    ruleId: category.ruleId,
                    total: category.total,
                    ruleName: category.latest[0].rule.name,
                    latest: category.latest.map(
                      notification => notification.id,
                    ),
                  });
                }
                adapter.addMany(state, category.latest);
              });
              state.notificationCount = notificationCount;
              state.summary = categories;
            }
            break;
          case 3:
            state.summaryLoading = 3;
            break;
          default:
            break;
        }
      })
      .addCase(ignoreNotificationAction, (state, { payload }) => {
        const { id, loading } = payload;
        switch (loading) {
          case 1:
            adapter.updateOne(state, {
              id,
              changes: {
                isIgnoring: true,
              },
            });
            break;
          case 2:
            adapter.updateOne(state, {
              id,
              changes: {
                isIgnoring: false,
                status: 'ignored',
              },
            });
            if (state.notificationCount > 0) {
              state.notificationCount -= 1;
            }
            break;
          case 3:
            adapter.updateOne(state, {
              id,
              changes: {
                isIgnoring: false,
                status: 'active',
              },
            });
            break;
          default:
            break;
        }
      });
  },
});

export default notificationListSlice.reducer;
