import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectNotificationById } from '../../managers/NotificationListManager/selectors';
import {
  ABOVE_90,
  DUPLICATE_EMAIL,
  ONLY_MALE,
  UNDER_12,
  WITHOUT_COUPON,
} from '../../utils/notification.util';
import OnlyMaleNotification from './OnlyMaleNotification';
import DuplicateEmailNotification from './DuplicateEmailNotification';
import AgeAbove90 from './AgeAbove90';
import AgeUnder12 from './AgeUnder12';
import WithoutCoupon from './WithoutCoupon';
import { ignoreNotificationAction } from '../../managers/NotificationListManager/actions';

function NotificationItem({ id }) {
  const dispatch = useDispatch();
  const notification = useSelector(state => selectNotificationById(state, id));

  const handleIgnore = useCallback(() => {
    dispatch(ignoreNotificationAction(1, notification.id));
  }, [dispatch, notification.id]);
  //   based on notification type
  switch (notification.rule.name) {
    case ONLY_MALE:
      return (
        <OnlyMaleNotification
          team={notification.team}
          createdAt={notification.createdAt}
          updatedAt={notification.updatedAt}
          onIgnore={handleIgnore}
          status={notification.status}
          disableIgnore={notification.isIgnoring}
        ></OnlyMaleNotification>
      );
    case ABOVE_90:
      return (
        <AgeAbove90
          member={notification.member}
          createdAt={notification.createdAt}
          updatedAt={notification.updatedAt}
          onIgnore={handleIgnore}
          status={notification.status}
          disableIgnore={notification.isIgnoring}
        ></AgeAbove90>
      );
    case UNDER_12:
      return (
        <AgeUnder12
          member={notification.member}
          createdAt={notification.createdAt}
          updatedAt={notification.updatedAt}
          onIgnore={handleIgnore}
          status={notification.status}
          disableIgnore={notification.isIgnoring}
        ></AgeUnder12>
      );
    case WITHOUT_COUPON:
      return (
        <WithoutCoupon
          member={notification.member}
          createdAt={notification.createdAt}
          updatedAt={notification.updatedAt}
          onIgnore={handleIgnore}
          status={notification.status}
          disableIgnore={notification.isIgnoring}
        ></WithoutCoupon>
      );
    case DUPLICATE_EMAIL:
      return (
        <DuplicateEmailNotification
          email={notification.entityId}
          createdAt={notification.createdAt}
          updatedAt={notification.updatedAt}
          onIgnore={handleIgnore}
          status={notification.status}
          disableIgnore={notification.isIgnoring}
        />
      );
    default:
      return null;
  }
}

export default memo(NotificationItem);
