/*
 *
 * Auth reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';

import {
  setAuthAction,
  getCurrentUserAction,
  performLogoutSuccessAction,
  getCurrentUserFailedAction,
  getCurrentUserSuccessAction,
  setPageTitle,
  sessionExpiredAction,
  resetAuthStateAction,
  updateViewRoleAct,
} from './actions';

export const initialState = {
  token: '',
  expiresIn: '',
  loggedInState: 'verifying',
  user: '',
  error: '',
  pageTitle: 'Welcome to Actively',
  viewRole: 0,
  hasVolunteerModule:
    process.env.REACT_APP_HAS_VOLUNTEER === undefined ||
    process.env.REACT_APP_HAS_VOLUNTEER === '1',
  hasSupporterModule:
    process.env.REACT_APP_HAS_SUPPORTER === undefined ||
    process.env.REACT_APP_HAS_SUPPORTER === '1',
  hasMealModule:
    process.env.REACT_APP_MEAL === undefined ||
    process.env.REACT_APP_MEAL === '1',
  hasHostingModule:
    process.env.REACT_APP_HOSTING === undefined ||
    process.env.REACT_APP_HOSTING === '1',
  hasHubSpotModule:
    process.env.REACT_APP_HUB_SPOT === undefined ||
    process.env.REACT_APP_HUB_SPOT === '1',
  hasTeamGroupModule:
    process.env.REACT_APP_TEAM_GROUP === undefined ||
    process.env.REACT_APP_TEAM_GROUP === '1',
  allowDepartureTime:
    process.env.REACT_APP_ALLOW_DEPARTURE_TIME_UPDATE === undefined ||
    process.env.REACT_APP_ALLOW_DEPARTURE_TIME_UPDATE === '1',
  hasCapModule:
    process.env.REACT_APP_CAP_MODULE === undefined ||
    process.env.REACT_APP_CAP_MODULE === '1',
  hasBikeModule:
    process.env.REACT_APP_BIKE_MODULE === undefined ||
    process.env.REACT_APP_BIKE_MODULE === '1',
  hasFTTRILicenseModule:
    process.env.REACT_APP_HAS_FTTRI_LICENSE_MODULE === undefined ||
    process.env.REACT_APP_HAS_FTTRI_LICENSE_MODULE === '1',
  client: process.env.CLIENT ? process.env.CLIENT : 'actively',
  hasNotification: process.env.REACT_APP_NOTIFICATION === '1',
  hasGlobalDashboard: process.env.REACT_APP_GLOBAL_DASHBOARD === '1',
};

/* eslint-disable default-case, no-param-reassign */
const authSlice = createSlice({
  name: 'Auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(setAuthAction, (state, { payload }) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        state.token = payload.token;
        state.expiresIn = payload.expires_in;
      })
      .addCase(performLogoutSuccessAction, state => {
        state.token = '';
        state.expiresIn = null;
        state.user = '';
        state.error = '';
        state.pageTitle = '';
        state.loggedInState = 'logout';
        state.viewRole = 0;
      })
      .addCase(sessionExpiredAction, state => {
        state.loggedInState = 'expired';
      })
      .addCase(getCurrentUserAction, state => {
        state.loggedInState = 'verifying';
        state.error = '';
        state.user = '';
      })
      .addCase(getCurrentUserSuccessAction, (state, { payload }) => {
        state.loggedInState = 'alive';
        state.user = payload;
        state.viewRole = payload.role.id;
        state.error = '';
      })
      .addCase(getCurrentUserFailedAction, (state, { payload }) => {
        state.loggedInState = 'failed';
        state.user = '';
        state.error = payload;
      })
      .addCase(setPageTitle, (state, { payload }) => {
        state.pageTitle = payload;
      })
      .addCase(updateViewRoleAct, (state, { payload }) => {
        state.viewRole = payload;
      })
      .addCase(resetAuthStateAction, () => initialState);
  },
});

export default authSlice.reducer;
