import React from 'react';
import { Navigate } from 'react-router-dom';
import { PrivateRoute } from '../PrivateRoute';
import Login from '../Login';
import EventsPage from '../EventsPage/Loadable';
import InternalUser from '../InternalUser/Loadable';
import EventsPageManager from '../../managers/EventsPageManager';
import InternalUserManager from '../../managers/InternalUserManager';
import EventDetailManager from '../../managers/EventDetailManager';
import EventDetail from '../EventDetail/Loadable';
import EventPage from '../EventPage/Loadable';
import EventGroup from '../EventGroup/Loadable';
import EventTeamListManager from '../../managers/EventTeamListManager';
import EventVolunteerListManager from '../../managers/EventVolunteerListManager';
import EventTeamDetailManager from '../../managers/EventTeamDetailManager';
import EventTeamDetail from '../EventTeamDetail/Loadable';
import VolunteerDetailManager from '../../managers/VolunteerDetailManager';
import VolunteerDetail from '../VolunteerDetail/Loadable';
import VolunteerTagsManager from '../../managers/VolunteerTagsManager';
import VolunteerTags from '../VolunteerTags/Loadable';
import TeamTags from '../TeamTags/Loadable';
import MemberTags from '../MemberTags/Loadable';
import VolunteerGroupsManager from '../../managers/VolunteerGroupsManager';
import VolunteerGroups from '../VolunteerGroups/Loadable';
import MemberDetailManager from '../../managers/MemberDetailManager';
import TeamTagsManager from '../../managers/TeamTagsManager';
import MemberTagsManager from '../../managers/MemberTagsManager';
import MemberDetail from '../MemberDetail/Loadable';
import EventMemberListManager from '../../managers/EventMemberListManager';
import SupporterListPage from '../SupporterListPage/Loadable';
import SupporterListManager from '../../managers/SupporterListManager';
import SupporterDetailManager from '../../managers/SupporterDetailManager';
import SupporterDetail from '../SupporterDetail/Loadable';
import AdminDashboardManager from '../../managers/AdminDashboardManager';
import AdminDashboard from '../AdminDashboard/Loadable';
import EventGlobalSetting from '../EventGlobalSetting/Loadable';
import RoleBasedRoute from '../RoleBasedRoute';
import ModuleBasedRoute from '../ModuleBasedRoute';
import SyncJobListManager from '../../managers/SyncJobListManager';
import SyncJobList from '../SyncJobList/Loadable';
import EventGroupManager from '../../managers/EventGroupManager';
import EventGroupDetailManager from '../../managers/EventGroupDetailManager';
import EventGroupPage from '../EventGroupPage/Loadable';
import EventGroupStatistic from '../EventGroupStatistic/Loadable';
import MemberListPage from '../MemberListPage/Loadable';
import TeamListPage from '../TeamListPage/Loadable';
import VolunteerListPage from '../VolunteerListPage/Loadable';
import TeamGroupListManager from '../../managers/TeamGroupListManager';
import TeamGroupList from '../TeamGroupList/Loadable';
import HubSpotLogsManager from '../../managers/HubSpotLogsManager';
import HubSpotLogs from '../HubSpotLogs/Loadable';
import NotificationListManager from '../../managers/NotificationListManager';
import NotificationList from '../NotificationList/Loadable';
import AnnualStatsManager from '../../managers/AnnualStatsManager';
import AnnualStats from '../AnnualStats/Loadable';

const VOLUNTEER_MODULE = 'Volunteer';
const SUPPORTER_MODULE = 'Supporter';
const TEAM_GROUP_MODULE = 'TeamGroup';
const NOTIFICATION_MODULE = 'Notification';
const GLOBAL_DASHBOARD = 'GlobalDashboard';

const DEFAULT_ROUTE = process.env.REACT_APP_LANDING_URL || '/events/active';

const SupporterListPageComponent = (
  <SupporterListManager>
    <SupporterListPage></SupporterListPage>
  </SupporterListManager>
);

const SupporterDetailComponent = (
  <SupporterDetailManager>
    <SupporterListManager>
      <SupporterDetail></SupporterDetail>
    </SupporterListManager>
  </SupporterDetailManager>
);

const TeamListElement = (
  <EventTeamListManager>
    <TeamGroupListManager>
      <TeamListPage></TeamListPage>
    </TeamGroupListManager>
  </EventTeamListManager>
);

const TeamDetailElement = (
  <EventTeamDetailManager>
    <InternalUserManager>
      <EventTeamListManager>
        <TeamGroupListManager>
          <EventTeamDetail></EventTeamDetail>
        </TeamGroupListManager>
      </EventTeamListManager>
    </InternalUserManager>
  </EventTeamDetailManager>
);

const VolunteerListElement = (
  <EventVolunteerListManager>
    <VolunteerGroupsManager>
      <VolunteerListPage></VolunteerListPage>
    </VolunteerGroupsManager>
  </EventVolunteerListManager>
);

const VolunteerDetailElement = (
  <VolunteerDetailManager>
    <VolunteerGroupsManager>
      <InternalUserManager>
        <EventVolunteerListManager>
          <VolunteerDetail></VolunteerDetail>
        </EventVolunteerListManager>
      </InternalUserManager>
    </VolunteerGroupsManager>
  </VolunteerDetailManager>
);

const TeamGroupElement = (
  <TeamGroupListManager>
    <TeamGroupList></TeamGroupList>
  </TeamGroupListManager>
);
const TeamTagsElement = (
  <TeamTagsManager>
    <TeamTags></TeamTags>
  </TeamTagsManager>
);
const MemberTagsElement = (
  <MemberTagsManager>
    <MemberTags></MemberTags>
  </MemberTagsManager>
);
const VolunteerTagsElement = (
  <VolunteerTagsManager>
    <VolunteerTags></VolunteerTags>
  </VolunteerTagsManager>
);
const VolunteerGroupsElement = (
  <VolunteerGroupsManager>
    <VolunteerGroups></VolunteerGroups>
  </VolunteerGroupsManager>
);

const MemberListElement = (
  <EventMemberListManager>
    <TeamGroupListManager>
      <MemberListPage></MemberListPage>
    </TeamGroupListManager>
  </EventMemberListManager>
);

const routes = [
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/forgot-password',
    element: <h1>Forgot password</h1>,
  },
  {
    path: '/*',
    element: <PrivateRoute />,
    children: [
      {
        path: 'event-groups/:status',
        element: (
          <EventGroupManager>
            <EventGroup></EventGroup>
          </EventGroupManager>
        ),
      },
      {
        path: 'events/:status',
        element: (
          <EventsPageManager>
            <EventsPage></EventsPage>
          </EventsPageManager>
        ),
      },
      {
        path: 'event-group/:id',
        element: (
          <EventGroupDetailManager>
            <EventGroupPage></EventGroupPage>
          </EventGroupDetailManager>
        ),
        children: [
          {
            path: '',
            element: <RoleBasedRoute roles={[1, 2]}></RoleBasedRoute>,
            children: [
              {
                path: 'detail',
                element: <EventGroupStatistic></EventGroupStatistic>,
              },
              {
                path: 'teams',
                element: TeamListElement,
              },
              {
                path: 'volunteers',
                element: (
                  <ModuleBasedRoute
                    module={VOLUNTEER_MODULE}
                  ></ModuleBasedRoute>
                ),
                children: [
                  {
                    path: '',
                    element: VolunteerListElement,
                  },
                ],
              },
              // routes specific to each event
              {
                path: 'events/:eventId',
                element: (
                  <EventDetailManager>
                    <EventPage></EventPage>
                  </EventDetailManager>
                ),
                children: [
                  {
                    path: 'detail',
                    element: <EventDetail></EventDetail>,
                  },
                  {
                    path: 'teams',
                    element: TeamListElement,
                  },
                  {
                    path: 'volunteers',
                    element: (
                      <ModuleBasedRoute
                        module={VOLUNTEER_MODULE}
                      ></ModuleBasedRoute>
                    ),
                    children: [
                      {
                        path: '',
                        element: VolunteerListElement,
                      },
                    ],
                  },
                  {
                    path: 'members',
                    element: MemberListElement,
                  },
                  {
                    path: 'supporters',
                    element: (
                      <ModuleBasedRoute
                        module={SUPPORTER_MODULE}
                      ></ModuleBasedRoute>
                    ),
                    children: [
                      {
                        path: '',
                        element: SupporterListPageComponent,
                      },
                    ],
                  },
                  {
                    path: 'teams/:teamId',
                    element: TeamDetailElement,
                  },
                  {
                    path: 'volunteers/:volunteerId',
                    element: (
                      <ModuleBasedRoute
                        module={VOLUNTEER_MODULE}
                      ></ModuleBasedRoute>
                    ),
                    children: [
                      {
                        path: '',
                        element: VolunteerDetailElement,
                      },
                    ],
                  },
                  {
                    path: 'supporters/:supporterId',
                    element: (
                      <ModuleBasedRoute
                        module={SUPPORTER_MODULE}
                      ></ModuleBasedRoute>
                    ),
                    children: [
                      {
                        path: '',
                        element: SupporterDetailComponent,
                      },
                    ],
                  },
                  {
                    path: 'members/:memberId',
                    element: (
                      <MemberDetailManager>
                        <EventMemberListManager>
                          <MemberDetail></MemberDetail>
                        </EventMemberListManager>
                      </MemberDetailManager>
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: 'members',
            element: <RoleBasedRoute roles={[1, 2, 3, 4]}></RoleBasedRoute>,
            children: [
              {
                path: '',
                element: MemberListElement,
              },
            ],
          },
          {
            path: 'supporters',
            element: <RoleBasedRoute roles={[1, 2, 3, 5]}></RoleBasedRoute>,
            children: [
              {
                path: '',
                element: (
                  <ModuleBasedRoute
                    module={SUPPORTER_MODULE}
                  ></ModuleBasedRoute>
                ),
                children: [
                  {
                    path: '',
                    element: SupporterListPageComponent,
                  },
                ],
              },
            ],
          },
          {
            path: 'setting',
            element: <RoleBasedRoute roles={[1, 2]}></RoleBasedRoute>,
            children: [
              {
                path: 'team-tags',
                element: TeamTagsElement,
              },
              {
                path: '',
                element: (
                  <ModuleBasedRoute
                    module={TEAM_GROUP_MODULE}
                  ></ModuleBasedRoute>
                ),
                children: [
                  {
                    path: 'team-groups',
                    element: TeamGroupElement,
                  },
                ],
              },
              {
                path: 'member-tags',
                element: MemberTagsElement,
              },
              {
                path: '',
                element: (
                  <ModuleBasedRoute
                    module={VOLUNTEER_MODULE}
                  ></ModuleBasedRoute>
                ),
                children: [
                  {
                    path: 'volunteer-tags',
                    element: VolunteerTagsElement,
                  },
                  {
                    path: 'volunteer-groups',
                    element: VolunteerGroupsElement,
                  },
                ],
              },
              {
                path: 'global-setting',
                element: <EventGlobalSetting></EventGlobalSetting>,
              },
            ],
          },
          {
            path: '*',
            element: <Navigate replace to="/event-groups/active" />,
          },
        ],
      },
      {
        path: 'event/:id',
        element: (
          <EventDetailManager>
            <EventPage></EventPage>
          </EventDetailManager>
        ),
        children: [
          {
            path: '',
            element: <RoleBasedRoute roles={[1, 2]}></RoleBasedRoute>,
            children: [
              {
                path: 'detail',
                element: <EventDetail></EventDetail>,
              },
              {
                path: 'teams',
                element: (
                  <EventTeamListManager>
                    <TeamListPage></TeamListPage>
                  </EventTeamListManager>
                ),
              },
              {
                path: 'volunteers',
                element: (
                  <ModuleBasedRoute
                    module={VOLUNTEER_MODULE}
                  ></ModuleBasedRoute>
                ),
                children: [
                  {
                    path: '',
                    element: (
                      <EventVolunteerListManager>
                        <VolunteerGroupsManager>
                          <VolunteerListPage></VolunteerListPage>
                        </VolunteerGroupsManager>
                      </EventVolunteerListManager>
                    ),
                  },
                ],
              },
              {
                path: 'teams/:teamId',
                element: TeamDetailElement,
              },
              {
                path: 'volunteers/:volunteerId',
                element: (
                  <ModuleBasedRoute
                    module={VOLUNTEER_MODULE}
                  ></ModuleBasedRoute>
                ),
                children: [
                  {
                    path: '',
                    element: VolunteerDetailElement,
                  },
                ],
              },
              {
                path: 'supporters/:supporterId',
                element: (
                  <ModuleBasedRoute
                    module={SUPPORTER_MODULE}
                  ></ModuleBasedRoute>
                ),
                children: [
                  {
                    path: '',
                    element: SupporterDetailComponent,
                  },
                ],
              },
              {
                path: 'members/:memberId',
                element: (
                  <MemberDetailManager>
                    <EventMemberListManager>
                      <MemberDetail></MemberDetail>
                    </EventMemberListManager>
                  </MemberDetailManager>
                ),
              },
            ],
          },
          {
            path: 'members',
            element: <RoleBasedRoute roles={[1, 2, 3, 4]}></RoleBasedRoute>,
            children: [
              {
                path: '',
                element: MemberListElement,
              },
            ],
          },
          {
            path: 'supporters',
            element: <RoleBasedRoute roles={[1, 2, 3, 5]}></RoleBasedRoute>,
            children: [
              {
                path: '',
                element: (
                  <ModuleBasedRoute
                    module={SUPPORTER_MODULE}
                  ></ModuleBasedRoute>
                ),
                children: [
                  {
                    path: '',
                    element: (
                      <SupporterListManager>
                        <SupporterListPage></SupporterListPage>
                      </SupporterListManager>
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: '*',
            element: <Navigate replace to="/events/active" />,
          },
        ],
      },
      {
        path: 'users/:id',
        element: (
          <InternalUserManager>
            <InternalUser></InternalUser>
          </InternalUserManager>
        ),
      },
      {
        path: 'notifications',
        element: <RoleBasedRoute roles={[1]} />,
        children: [
          {
            path: '',
            element: <ModuleBasedRoute module={NOTIFICATION_MODULE} />,
            children: [
              {
                path: '',
                element: (
                  <NotificationListManager>
                    <NotificationList></NotificationList>
                  </NotificationListManager>
                ),
              },
            ],
          },
        ],
      },
      {
        path: 'admin',
        element: <RoleBasedRoute roles={[1]}></RoleBasedRoute>,
        children: [
          {
            path: '',
            element: (
              <AdminDashboardManager>
                <AdminDashboard></AdminDashboard>
              </AdminDashboardManager>
            ),
          },
          {
            path: 'sync-jobs',
            element: (
              <SyncJobListManager>
                <SyncJobList></SyncJobList>
              </SyncJobListManager>
            ),
          },
          {
            path: 'hs-lg',
            element: (
              <HubSpotLogsManager>
                <HubSpotLogs></HubSpotLogs>
              </HubSpotLogsManager>
            ),
          },
        ],
      },
      {
        path: 'annual-stats',
        element: <RoleBasedRoute roles={[1]}></RoleBasedRoute>,
        children: [
          {
            path: '',
            element: <ModuleBasedRoute module={GLOBAL_DASHBOARD} />,
            children: [
              {
                path: '',
                element: (
                  <AnnualStatsManager>
                    <AnnualStats></AnnualStats>
                  </AnnualStatsManager>
                ),
              },
            ],
          },
        ],
      },
      {
        path: '*',
        element: <Navigate replace to={DEFAULT_ROUTE} />,
      },
    ],
  },
];

export default routes;
