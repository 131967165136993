/*
 *
 * AnnualStats reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';

import { getAnnualStatsAct, resetAnnualStatsAct } from './actions';

export const initialState = {
  loading: 0,
  annualStats: null,
};

/* eslint-disable default-case, no-param-reassign */
const annualStatsSlice = createSlice({
  name: 'AnnualStats',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAnnualStatsAct, (state, { payload }) => {
        const { loading, data } = payload;
        state.loading = loading;
        switch (loading) {
          case 1:
          case 3:
            state.annualStats = null;
            break;
          case 2:
            state.annualStats = data;
            break;
          default:
        }
      })
      .addCase(resetAnnualStatsAct, () => initialState);
  },
});

export default annualStatsSlice.reducer;
