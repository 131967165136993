import { takeLatest, call, put } from 'redux-saga/effects';
import {
  activateUserApi,
  createUserApi,
  deactivateUserApi,
  getUsersApi,
  transferOwnerApi,
  updateUserApi,
} from '../../api/admin.api';
import {
  activateUserAct,
  activateUserErrorAct,
  activateUserSuccessAct,
  createUserAct,
  createUserErrorAct,
  createUserSuccessAct,
  deactivateUserAct,
  deactivateUserErrorAct,
  deactivateUserSuccessAct,
  getUsersAct,
  getUsersErrorAct,
  getUsersSuccessAct,
  transferOwnerAct,
  transferOwnerErrorAct,
  transferOwnerSuccessAct,
} from './actions';
import { getErrorMessage } from '../../utils/error.util';
import { addMessage } from '../ToastMessagesManager/actions';

function* getUsers({ payload }) {
  try {
    const { data } = yield call(getUsersApi, payload);
    if (data) {
      yield put(getUsersSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(addMessage({ message: errMessage, variant: 'error' }));
    yield put(getUsersErrorAct());
  }
}

function* createUser({ payload }) {
  try {
    let data;
    if (!payload.id) {
      const { data: createdUser } = yield call(createUserApi, {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        password: payload.password,
        confirmPassword: payload.confirmPassword,
        roleId: payload.roleId,
      });
      data = createdUser;
    } else {
      const { data: updatedUser } = yield call(updateUserApi, payload.id, {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        roleId: payload.roleId,
      });
      data = updatedUser;
    }
    if (data) {
      yield put(createUserSuccessAct(data));
    } else {
      yield put(
        addMessage({ message: 'Something went wrong', variant: 'error' }),
      );
      yield put(createUserErrorAct({ code: 500 }));
    }
  } catch (error) {
    const { response } = error;
    yield put(createUserErrorAct(response.data));
  }
}

function* transferOwner({ payload }) {
  try {
    const { data } = yield call(transferOwnerApi, payload);
    if (data) {
      yield put(transferOwnerSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(addMessage({ message: errMessage, variant: 'error' }));
    yield put(transferOwnerErrorAct());
  }
}

function* deactivateUser({ payload }) {
  try {
    const { data } = yield call(deactivateUserApi, payload);
    if (data) {
      yield put(deactivateUserSuccessAct(data));
    } else {
      yield put(
        addMessage({ message: 'Something went wrong', variant: 'error' }),
      );
      yield put(deactivateUserErrorAct({ code: 500 }));
    }
  } catch (error) {
    const { response } = error;
    yield put(deactivateUserErrorAct(response.data));
  }
}

function* activateUser({ payload }) {
  try {
    const { data } = yield call(activateUserApi, payload);
    if (data) {
      yield put(activateUserSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(addMessage({ message: errMessage, variant: 'error' }));
    yield put(activateUserErrorAct());
  }
}

// Individual exports for testing
export default function* adminDashboardSaga() {
  yield takeLatest(getUsersAct.toString(), getUsers);
  yield takeLatest(createUserAct.toString(), createUser);
  yield takeLatest(deactivateUserAct.toString(), deactivateUser);
  yield takeLatest(activateUserAct.toString(), activateUser);
  yield takeLatest(transferOwnerAct.toString(), transferOwner);
}
