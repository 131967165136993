/*
 *
 * TeamGroupList actions
 *
 */

import { createAction } from '@reduxjs/toolkit';
import {
  CREATE_TEAM_GROUPS,
  CREATE_TEAM_GROUPS_ERROR,
  CREATE_TEAM_GROUPS_SUCCESS,
  DELETE_TEAM_GROUPS,
  DELETE_TEAM_GROUPS_ERROR,
  DELETE_TEAM_GROUPS_SUCCESS,
  GET_TEAM_GROUPS_LIST,
  GET_TEAM_GROUPS_LIST_ERROR,
  GET_TEAM_GROUPS_LIST_SUCCESS,
  RESET_GET_TEAM_GROUPS_LIST,
  UPDATE_ACTION_STATUS,
  UPDATE_TEAM_GROUPS,
  UPDATE_TEAM_GROUPS_ERROR,
  UPDATE_TEAM_GROUPS_SUCCESS,
} from './constants';

export const getTeamGroupsListAct = createAction(GET_TEAM_GROUPS_LIST);
export const getTeamGroupsListError = createAction(GET_TEAM_GROUPS_LIST_ERROR);
export const getTeamGroupsListSuccess = createAction(
  GET_TEAM_GROUPS_LIST_SUCCESS,
);
export const resetGetTeamGroupsList = createAction(RESET_GET_TEAM_GROUPS_LIST);
export const deleteTeamGroupsAct = createAction(DELETE_TEAM_GROUPS);
export const deleteTeamGroupsError = createAction(DELETE_TEAM_GROUPS_ERROR);
export const deleteTeamGroupsSuccess = createAction(DELETE_TEAM_GROUPS_SUCCESS);
export const updateTeamGroupsAct = createAction(UPDATE_TEAM_GROUPS);
export const updateTeamGroupsError = createAction(UPDATE_TEAM_GROUPS_ERROR);
export const updateTeamGroupsSuccess = createAction(UPDATE_TEAM_GROUPS_SUCCESS);
export const updateActionStatusAct = createAction(UPDATE_ACTION_STATUS);
export const createTeamGroupsAct = createAction(CREATE_TEAM_GROUPS);
export const createTeamGroupsError = createAction(CREATE_TEAM_GROUPS_ERROR);
export const createTeamGroupsSuccess = createAction(CREATE_TEAM_GROUPS_SUCCESS);
