/*
 *
 * EventGroup reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';

import {
  createEventGroupAct,
  createEventGroupErrorAct,
  createEventGroupSuccessAct,
  deleteEventGroupAct,
  deleteEventGroupErrorAct,
  deleteEventGroupSuccessAct,
  getEventGroupsAct,
  getEventGroupsErrorAct,
  getEventGroupsSuccessAct,
  getUnlinkedEventsAct,
  getUnlinkedEventsErrorAct,
  getUnlinkedEventsSuccessAct,
  resetEventGroupAct,
  resetUnlinkedEventsStateAct,
  updateEventGroupAct,
  updateEventGroupErrorAct,
  updateEventGroupSuccessAct,
} from './actions';

import eventGroupEntityManager from './entity-adapter';

export const initialState = eventGroupEntityManager.getInitialState({
  loadingState: 0,
  totalPages: 0,
  count: 0,
  activeId: '',
  action: '',
  actionState: 0,
  unlinkedLoadingState: 0,
  unlinkedEvents: [],
});

/* eslint-disable default-case, no-param-reassign */
const eventGroupSlice = createSlice({
  name: 'EventGroup',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getEventGroupsAct, state => {
        state.loadingState = 1;
        eventGroupEntityManager.removeAll(state);
      })
      .addCase(getEventGroupsErrorAct, state => {
        state.loadingState = 3;
      })
      .addCase(getEventGroupsSuccessAct, (state, { payload }) => {
        state.loadingState = 2;
        eventGroupEntityManager.upsertMany(state, payload.rows);
        state.totalPages = payload.totalPages;
        state.count = payload.count;
      })
      .addCase(deleteEventGroupAct, state => {
        state.actionState = 1;
        state.action = 'Delete';
      })
      .addCase(deleteEventGroupErrorAct, state => {
        state.actionState = 3;
      })
      .addCase(deleteEventGroupSuccessAct, (state, { payload }) => {
        const { id } = payload;
        state.actionState = 2;
        eventGroupEntityManager.removeOne(state, id);
      })
      .addCase(updateEventGroupAct, state => {
        state.actionState = 1;
        state.action = 'Update';
      })
      .addCase(updateEventGroupErrorAct, state => {
        state.actionState = 3;
      })
      .addCase(updateEventGroupSuccessAct, state => {
        // const { id, name, updatedAt } = payload;
        state.actionState = 2;
        // if (id && state.entities[id]) {
        //   eventGroupEntityManager.updateOne(state, {
        //     id,
        //     changes: {
        //       name,
        //       updatedAt,
        //     },
        //   });
        // }
      })
      .addCase(createEventGroupAct, state => {
        state.actionState = 1;
        state.action = 'Create';
      })
      .addCase(createEventGroupErrorAct, state => {
        state.actionState = 3;
      })
      .addCase(createEventGroupSuccessAct, state => {
        state.actionState = 2;
      })
      .addCase(getUnlinkedEventsAct, state => {
        state.unlinkedLoadingState = 1;
      })
      .addCase(getUnlinkedEventsErrorAct, state => {
        state.unlinkedLoadingState = 3;
      })
      .addCase(getUnlinkedEventsSuccessAct, (state, { payload }) => {
        state.unlinkedLoadingState = 2;
        state.unlinkedEvents = payload;
      })
      .addCase(resetUnlinkedEventsStateAct, state => {
        state.unlinkedLoadingState = 0;
        state.unlinkedEvents = [];
      })
      .addCase(resetEventGroupAct, () => initialState);
  },
});

export default eventGroupSlice.reducer;
