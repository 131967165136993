/*
 * EventTeamDetail Messages
 *
 * This contains all the text for the EventTeamDetail container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.EventTeamDetail';

export default defineMessages({
  bibSuccess: {
    id: `${scope}.bibSuccess`,
    defaultMessage: 'Bib number is updated succesfully',
  },
  startTimeSuccess: {
    id: `${scope}.startTimeSuccess`,
    defaultMessage: 'Start time is updated succesfully',
  },
  bibTitle: {
    id: `${scope}.bibTitle`,
    defaultMessage: 'Set BIB number',
  },
  bibWarn: {
    id: `${scope}.bibWarn`,
    defaultMessage: 'Please provide the initial number for the BIB',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Save',
  },
  addInfo: {
    id: `${scope}.addInfo`,
    defaultMessage: 'Additional information',
  },
  registration: {
    id: `${scope}.registration`,
    defaultMessage: 'Registration',
  },
  enterprise: {
    id: `${scope}.enterprise`,
    defaultMessage: 'Enterprise',
  },
  responsible: {
    id: `${scope}.responsible`,
    defaultMessage: 'Responsible',
  },
  wcwe: {
    id: `${scope}.wcwe`,
    defaultMessage: 'WC/WE',
  },
  selectUser: {
    id: `${scope}.selectUser`,
    defaultMessage: 'Select a user',
  },
  setRespUser: {
    id: `${scope}.setRespUser`,
    defaultMessage: 'Set the responsible',
  },
  noUsers: {
    id: `${scope}.noUsers`,
    defaultMessage: 'No users',
  },
  searchUser: {
    id: `${scope}.searchUser`,
    defaultMessage: 'Search user',
  },
  userAssignSuc: {
    id: `${scope}.userAssignSuc`,
    defaultMessage: 'A team is assigned to user succesfully',
  },
  wcweUpdateSuc: {
    id: `${scope}.wcweUpdateSuc`,
    defaultMessage: 'WC/WE is updated successfully',
  },
  updateMedSuc: {
    id: `${scope}.updateMedSuc`,
    defaultMessage: 'Medical cerificate state is updated',
  },
  updateParentalConsentSuc: {
    id: `${scope}.updateParentalConsentSuc`,
    defaultMessage: 'Parental consent state is updated',
  },
  tagStateUpdateSuc: {
    id: `${scope}.tagStateUpdateSuc`,
    defaultMessage: "Tag's state is updated",
  },
  notYet: {
    id: `${scope}.notYet`,
    defaultMessage: 'Not yet',
  },
  partial: {
    id: `${scope}.partial`,
    defaultMessage: 'Partially',
  },
  totally: {
    id: `${scope}.totally`,
    defaultMessage: 'Totally',
  },
  updateGrpSuc: {
    id: `${scope}.updateGrpSuc`,
    defaultMessage: 'A team is assigned to group successfully',
  },
  removeGrpSuc: {
    id: `${scope}.removeGrpSuc`,
    defaultMessage: 'A team is removed from group successfully',
  },
});
