import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  GET_TEAM_TAGS,
  GET_UNIQUE_COMPANY_NAMES,
  UPDATE_MEMBER_DETAIL,
  UPDATE_TEAM_BOARDING_STATUS,
  UPDATE_TEAM_NOTE,
} from './constants';
import {
  getEventTeamsApi,
  getTeamUniqueCompaniesApi,
  getUsersAssignedToTeamByEventApi,
} from '../../api/event.api';
import {
  getEventTeamListAct,
  getEventTeamListErrorAct,
  getEventTeamListSuccessAct,
  getResponsibleUsersAct,
  getResponsibleUsersErrorAct,
  getResponsibleUsersSuccessAct,
  getTeamTagsErrorAct,
  getTeamTagsSuccessAct,
  getUniqueCompanyNamesErrorAct,
  getUniqueCompanyNamesSuccessAct,
  updateMemberDetailErrorAct,
  updateMemberDetailSuccessAct,
  updateTeamBoardingStatusErrorAct,
  updateTeamBoardingStatusSuccessAct,
  updateTeamNoteErrorAct,
  updateTeamNoteSuccessAct,
} from './actions';
import { updateBoardingStatusApi, updateNoteApi } from '../../api/team.api';

import { updateMemberApi } from '../../api/member.api';
import { getErrorMessage } from '../../utils/error.util';
import {
  getEvenGroupTeamUniqueCompaniesApi,
  getEventGroupTeamsApi,
  getUsersAssignedToTeamByEventGroupApi,
} from '../../api/event-group.api';
import { getTeamTagsApi } from '../../api/team-tag.api';

function* fetchTeams({ payload }) {
  try {
    // based on type in payload we need to call different api endpoints
    const { type, id, params, isMerge = false } = payload;
    const api =
      type === 'event-group' ? getEventGroupTeamsApi : getEventTeamsApi;
    const { data } = yield call(api, id, params);
    if (data) {
      yield put(
        getEventTeamListSuccessAct(data, params.page, params.pageSize, isMerge),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(getEventTeamListErrorAct(errMessage));
  }
}

function* fetchResponsibleUsers({ payload }) {
  try {
    const { type, id } = payload;
    const api =
      type === 'event-group'
        ? getUsersAssignedToTeamByEventGroupApi
        : getUsersAssignedToTeamByEventApi;
    const { data } = yield call(api, id);
    if (data) {
      yield put(getResponsibleUsersSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(getResponsibleUsersErrorAct(errMessage));
  }
}

function* fetchUniqueCompanyNames({ payload }) {
  try {
    const { type, id } = payload;
    const api =
      type === 'event-group'
        ? getEvenGroupTeamUniqueCompaniesApi
        : getTeamUniqueCompaniesApi;
    const { data } = yield call(api, id);
    if (data) {
      yield put(getUniqueCompanyNamesSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(getUniqueCompanyNamesErrorAct(errMessage));
  }
}

function* fetchTags({ payload }) {
  try {
    const { data } = yield call(getTeamTagsApi, payload.params);
    if (data) {
      yield put(getTeamTagsSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(getTeamTagsErrorAct(errMessage));
  }
}

function* updateTeamBoardingStatus({ payload }) {
  try {
    const { data } = yield call(updateBoardingStatusApi, payload);
    if (data) {
      yield put(updateTeamBoardingStatusSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(
      updateTeamBoardingStatusErrorAct({
        id: payload.id,
        boardingStatus: payload.data.boardingStatus,
        error: errMessage,
      }),
    );
  }
}

function* updateMemberDetail({ payload }) {
  try {
    const { data } = yield call(updateMemberApi, payload);
    if (data) {
      yield put(
        updateMemberDetailSuccessAct({
          ...payload,
          data,
        }),
      );
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(
      updateMemberDetailErrorAct({
        ...payload,
        error: errMessage,
      }),
    );
  }
}

function* updateTeamNote({ payload }) {
  try {
    const { data } = yield call(updateNoteApi, payload);
    if (data) {
      yield put(updateTeamNoteSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(
      updateTeamNoteErrorAct({
        ...payload,
        error: errMessage,
      }),
    );
  }
}

// Individual exports for testing
export default function* eventTeamListSaga() {
  //  uncomment this to setp default action
  yield takeLatest(getEventTeamListAct.toString(), fetchTeams);
  yield takeLatest(getResponsibleUsersAct.toString(), fetchResponsibleUsers);
  yield takeLatest(GET_UNIQUE_COMPANY_NAMES, fetchUniqueCompanyNames);
  yield takeLatest(GET_TEAM_TAGS, fetchTags);
  yield takeEvery(UPDATE_TEAM_BOARDING_STATUS, updateTeamBoardingStatus);
  yield takeEvery(UPDATE_MEMBER_DETAIL, updateMemberDetail);
  yield takeEvery(UPDATE_TEAM_NOTE, updateTeamNote);
}
