/*
 * NotificationList Messages
 *
 * This contains all the text for the NotificationList component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.NotificationList';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Notifications',
  },
  duplicateEmail: {
    id: `${scope}.duplicateEmail`,
    defaultMessage:
      '<emailEle>{email}</emailEle> is used by several participant',
  },
  onlyMale: {
    id: `${scope}.onlyMale`,
    defaultMessage: '<team>{name}</team> has only male participants',
  },
  under12: {
    id: `${scope}.under12`,
    defaultMessage: '<member>{name}</member> is under 12 years old',
  },
  above90: {
    id: `${scope}.above90`,
    defaultMessage: '<member>{name}</member> is over 90 years old',
  },
  withoutCoupon: {
    id: `${scope}.withoutCoupon`,
    defaultMessage:
      '<member>{name}</member> is in a company project and has not used a registration coupon',
  },
  ignoreSuccess: {
    id: `${scope}.ignoreSuccess`,
    defaultMessage: 'Notification is ignored successfully',
  },
  only_male_participants: {
    id: `${scope}.only_male_participants`,
    defaultMessage: 'Only male participants',
  },
  age_under_12: {
    id: `${scope}.age_under_12`,
    defaultMessage: 'Under 12 years old',
  },
  age_above_90: {
    id: `${scope}.age_above_90`,
    defaultMessage: 'Over 90 years old',
  },
  company_project_without_coupon: {
    id: `${scope}.company_project_without_coupon`,
    defaultMessage: 'Company project but without registration coupon',
  },
  participants_with_duplicate_email: {
    id: `${scope}.participants_with_duplicate_email`,
    defaultMessage: 'Duplicate email address',
  },
  confirm_ignore: {
    id: `${scope}.confirm_ignore`,
    defaultMessage:
      'Are you sure you want to mark this notification as ignored?',
  },
  ignore: {
    id: `${scope}.ignore`,
    defaultMessage: 'Ignore',
  },
  ignoreTooltip: {
    id: `${scope}.ignoreTooltip`,
    defaultMessage: 'Mark as ignored',
  },
  noNotification: {
    id: `${scope}.noNotification`,
    defaultMessage: 'No notifications',
  },
  notificationFailed: {
    id: `${scope}.notificationFailed`,
    defaultMessage: 'Failed to load notification',
  },
});
