/*
 *
 * EventTeamList reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';

import {
  getEventTeamListAct,
  getEventTeamListErrorAct,
  getEventTeamListSuccessAct,
  getResponsibleUsersAct,
  getResponsibleUsersErrorAct,
  getResponsibleUsersSuccessAct,
  getTeamTagsAct,
  getTeamTagsErrorAct,
  getTeamTagsSuccessAct,
  getUniqueCompanyNamesAct,
  getUniqueCompanyNamesErrorAct,
  getUniqueCompanyNamesSuccessAct,
  resetGetEventTeamList,
  updateMemberDetailAct,
  updateMemberDetailErrorAct,
  updateMemberDetailSuccessAct,
  updateTeamBoardingStatusAct,
  updateTeamBoardingStatusErrorAct,
  updateTeamBoardingStatusSuccessAct,
  updateTeamNoteAct,
  updateTeamNoteErrorAct,
  updateTeamNoteSuccessAct,
} from './actions';
import memberEntityAdapter from '../entity-adapter/member-entity-adapter';
import teamEntityAdapter from '../entity-adapter/team-entity-adapter';
import teamNoteEntityAdapter from '../entity-adapter/teamnote-entity-adapter';
export const initialState = teamEntityAdapter.getInitialState({
  loadingState: 0,
  error: '',
  totalPages: 0,
  count: 0,
  page: 0,
  pageSize: 0,
  responsibleUsers: [],
  responsibleUsersLoadingState: 0,
  companyNamesLoadingState: 0,
  companyNames: [],
  tags: [],
  tagLoadingState: 0,
  members: memberEntityAdapter.getInitialState(),
  notes: teamNoteEntityAdapter.getInitialState(),
});

/* eslint-disable default-case, no-param-reassign */
const eventTeamListSlice = createSlice({
  name: 'EventTeamList',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getEventTeamListAct, state => {
        state.loadingState = 1;
        state.error = '';
      })
      .addCase(getEventTeamListErrorAct, (state, { payload }) => {
        state.loadingState = 3;
        state.error = payload;
      })
      .addCase(getEventTeamListSuccessAct, (state, { payload }) => {
        state.loadingState = 2;
        state.error = '';
        // need to normalize members
        const teams = [];
        const members = [];
        const notes = [];
        const { data, page, pageSize, isMerge } = payload;
        state.page = page;
        state.pageSize = pageSize;
        const { rows, count, totalPages } = data;
        rows.forEach(team => {
          const hasFemale = team.members.some(
            member => member.gender?.toLowerCase() === 'female',
          );
          teams.push({
            ...team,
            members: team.members.map(member => member.id),
            notes: team.notes.map(note => note.id),
            hasFemale,
          });
          members.push(...team.members);
          notes.push(...team.notes);
        });
        if (isMerge) {
          teamEntityAdapter.setMany(state, teams);
          memberEntityAdapter.setMany(state.members, members);
          teamNoteEntityAdapter.setMany(state.notes, notes);
        } else {
          teamEntityAdapter.setAll(state, teams);
          memberEntityAdapter.setAll(state.members, members);
          teamNoteEntityAdapter.setAll(state.notes, notes);
        }
        state.totalPages = totalPages;
        state.count = count;
      })
      .addCase(getResponsibleUsersAct, state => {
        state.responsibleUsersLoadingState = 1;
        state.error = '';
      })
      .addCase(getResponsibleUsersErrorAct, (state, { payload }) => {
        state.responsibleUsersLoadingState = 3;
        state.error = payload;
      })
      .addCase(getResponsibleUsersSuccessAct, (state, { payload }) => {
        state.responsibleUsersLoadingState = 2;
        state.error = '';
        state.responsibleUsers = payload;
      })
      .addCase(getUniqueCompanyNamesAct, state => {
        state.error = '';
        state.companyNamesLoadingState = 1;
      })
      .addCase(getUniqueCompanyNamesErrorAct, (state, { payload }) => {
        state.error = payload;
        state.companyNamesLoadingState = 3;
      })
      .addCase(getUniqueCompanyNamesSuccessAct, (state, { payload }) => {
        state.companyNamesLoadingState = 2;
        state.error = '';
        state.companyNames = payload;
      })
      .addCase(getTeamTagsAct, state => {
        state.tagLoadingState = 1;
        state.error = '';
      })
      .addCase(getTeamTagsErrorAct, (state, { payload }) => {
        state.tagLoadingState = 3;
        state.error = payload;
      })
      .addCase(getTeamTagsSuccessAct, (state, { payload }) => {
        state.tagLoadingState = 2;
        state.error = '';
        state.tags = payload.rows;
      })
      .addCase(updateTeamBoardingStatusAct, (state, { payload }) => {
        const { id, data } = payload;
        state.error = '';
        teamEntityAdapter.updateOne(state, {
          id,
          changes: { loadingState: 1, ...data },
        });
      })
      .addCase(updateTeamBoardingStatusSuccessAct, (state, { payload }) => {
        const { id, boardingStatus, members } = payload;
        state.error = '';
        teamEntityAdapter.updateOne(state, {
          id,
          changes: {
            loadingState: 2,
            boardingStatus,
          },
        });
        if (members) {
          memberEntityAdapter.upsertMany(state.members, members);
        }
      })
      .addCase(updateTeamBoardingStatusErrorAct, (state, { payload }) => {
        const { id, error, boardingStatus } = payload;
        state.error = error;
        teamEntityAdapter.updateOne(state, {
          id,
          changes: { loadingState: 3, boardingStatus: !boardingStatus },
        });
      })
      .addCase(updateMemberDetailAct, (state, { payload }) => {
        const { id, data, teamId } = payload;
        state.error = '';
        teamEntityAdapter.updateOne(state, {
          id: teamId,
          changes: { loadingState: 1 },
        });
        memberEntityAdapter.updateOne(state.members, {
          id,
          changes: { ...data },
        });
      })
      .addCase(updateMemberDetailSuccessAct, (state, { payload }) => {
        const { id, teamId, data } = payload;
        const { emergencyContactName, emergencyContactNumber, boardingStatus } =
          data;
        state.error = '';
        teamEntityAdapter.updateOne(state, {
          id: teamId,
          changes: {
            loadingState: 2,
            boardingStatus: data.team.boardingStatus,
            isBoardingEditable: data.team.isBoardingEditable,
          },
        });
        memberEntityAdapter.updateOne(state.members, {
          id,
          changes: {
            emergencyContactName,
            emergencyContactNumber,
            boardingStatus,
          },
        });
      })
      .addCase(updateMemberDetailErrorAct, (state, { payload }) => {
        const { id, error, teamId, oldValue } = payload;
        state.error = error;
        teamEntityAdapter.updateOne(state, {
          id: teamId,
          changes: { loadingState: 3 },
        });
        memberEntityAdapter.updateOne(state.members, {
          id,
          changes: oldValue,
        });
      })
      .addCase(updateTeamNoteAct, (state, { payload }) => {
        const { id, data } = payload;
        state.error = '';
        teamNoteEntityAdapter.updateOne(state.notes, {
          id,
          changes: { loadingState: 1, ...data },
        });
      })
      .addCase(updateTeamNoteSuccessAct, (state, { payload }) => {
        const { id, text } = payload;
        state.error = '';
        teamNoteEntityAdapter.updateOne(state.notes, {
          id,
          changes: {
            loadingState: 2,
            text,
          },
        });
      })
      .addCase(updateTeamNoteErrorAct, (state, { payload }) => {
        const { id, error, oldValue } = payload;
        state.error = error;
        teamNoteEntityAdapter.updateOne(state.notes, {
          id,
          changes: { loadingState: 3, ...oldValue },
        });
      })
      .addCase(resetGetEventTeamList, () => initialState);
  },
});

export default eventTeamListSlice.reducer;
