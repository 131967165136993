/*
 *
 * AdminDashboard reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';

import { userAdapters } from './entity-adapter';
import {
  createUserAct,
  createUserErrorAct,
  createUserSuccessAct,
  deactivateUserAct,
  deactivateUserErrorAct,
  deactivateUserSuccessAct,
  activateUserAct,
  activateUserErrorAct,
  activateUserSuccessAct,
  getUsersAct,
  getUsersErrorAct,
  getUsersSuccessAct,
  resetAdmin,
  transferOwnerAct,
  transferOwnerErrorAct,
  transferOwnerSuccessAct,
} from './actions';

export const initialState = {
  users: userAdapters.getInitialState(),
  userLoadingState: 0,
  userCount: 0,
  updateState: 0,
  deactivateState: 0,
  activateState: 0,
  transferState: 0,
  deactivateErrorCode: 0,
  updateErrorCode: 0,
};

/* eslint-disable default-case, no-param-reassign */
const adminDashboardSlice = createSlice({
  name: 'AdminDashboard',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getUsersAct, state => {
        state.userLoadingState = 1;
        userAdapters.removeAll(state.users);
      })
      .addCase(getUsersErrorAct, (state, { payload }) => {
        state.userLoadingState = 3;
        state.error = payload;
      })
      .addCase(getUsersSuccessAct, (state, { payload }) => {
        state.userLoadingState = 2;
        state.userCount = payload.count;
        userAdapters.upsertMany(state.users, payload.rows);
      })
      .addCase(createUserAct, state => {
        state.updateState = 1;
        state.updateErrorCode = 0;
      })
      .addCase(createUserErrorAct, (state, { payload }) => {
        state.updateState = 3;
        state.updateErrorCode = payload.code;
      })
      .addCase(createUserSuccessAct, (state, { payload }) => {
        state.updateState = 2;
        state.updateErrorCode = 0;
        state.userCount += 1;
        userAdapters.upsertOne(state.users, payload);
      })
      .addCase(deactivateUserAct, state => {
        state.deactivateState = 1;
        state.deactivateErrorCode = 0;
      })
      .addCase(deactivateUserErrorAct, (state, { payload }) => {
        state.deactivateState = 3;
        state.deactivateErrorCode = payload.code;
      })
      .addCase(deactivateUserSuccessAct, (state, { payload }) => {
        state.deactivateState = 2;
        state.deactivateErrorCode = 0;
        userAdapters.upsertOne(state.users, payload);
      })
      .addCase(activateUserAct, state => {
        state.activateState = 1;
      })
      .addCase(activateUserErrorAct, state => {
        state.activateState = 3;
      })
      .addCase(activateUserSuccessAct, (state, { payload }) => {
        state.activateState = 2;
        userAdapters.upsertOne(state.users, payload);
      })
      .addCase(transferOwnerAct, state => {
        state.transferState = 1;
      })
      .addCase(transferOwnerErrorAct, state => {
        state.transferState = 3;
      })
      .addCase(transferOwnerSuccessAct, (state, { payload }) => {
        state.transferState = 2;
        userAdapters.upsertOne(state.users, payload);
      })
      .addCase(resetAdmin, () => initialState);
  },
});

export default adminDashboardSlice.reducer;
