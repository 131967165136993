/*
 * VolunteerTags Messages
 *
 * This contains all the text for the VolunteerTags container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.VolunteerTags';

export default defineMessages({
  createSuccess: {
    id: `${scope}.createSuccess`,
    defaultMessage: 'Tag is created successfully',
  },
  updateSuccess: {
    id: `${scope}.updateSuccess`,
    defaultMessage: 'Tag is updated successfully',
  },
  deleteSuccess: {
    id: `${scope}.deleteSuccess`,
    defaultMessage: 'Tag is deleted successfully',
  },
  systemTag: {
    id: `${scope}.systemTag`,
    defaultMessage:
      'System default tags. You are not allowed to perform opertation on it',
  },
});
