import dayjs from 'dayjs';
import LOCALIZED_FORMAT_PLUGIN from 'dayjs/plugin/localizedFormat';
import RelativeTimePlugin from 'dayjs/plugin/relativeTime';

dayjs.extend(LOCALIZED_FORMAT_PLUGIN);
dayjs.extend(RelativeTimePlugin);
export const getFormattedDate = (value, format) => {
  if (value) {
    return dayjs(value).format(format || 'L');
  }
  return null;
};

export const renderRelativeDate = (text, format = 'y') => {
  if (text) {
    return dayjs().diff(text, format);
  }
  return text;
};

export const IsTeenager = value => {
  let yearDifference;
  if (value) {
    yearDifference = dayjs().diff(value, 'Y');
  }
  if (yearDifference === undefined) {
    return yearDifference < 18;
  }
  return false;
};

export function getRelativeDateFromNow(value) {
  if (value) {
    const date = dayjs(value);
    return date.fromNow();
  }
  return null;
}
