/*
 *
 * VolunteerTags actions
 *
 */

import { createAction } from '@reduxjs/toolkit';

import {
  CREATE_VOLUNTEER_TAGS,
  CREATE_VOLUNTEER_TAGS_ERROR,
  CREATE_VOLUNTEER_TAGS_SUCCESS,
  DELETE_VOLUNTEER_TAGS,
  DELETE_VOLUNTEER_TAGS_ERROR,
  DELETE_VOLUNTEER_TAGS_SUCCESS,
  GET_VOLUNTEER_TAGS_LIST,
  GET_VOLUNTEER_TAGS_LIST_ERROR,
  GET_VOLUNTEER_TAGS_LIST_SUCCESS,
  RESET_GET_VOLUNTEER_TAGS_LIST,
  UPDATE_ACTION_STATUS,
  UPDATE_VOLUNTEER_TAGS,
  UPDATE_VOLUNTEER_TAGS_ERROR,
  UPDATE_VOLUNTEER_TAGS_SUCCESS,
} from './constants';

export const getVolunteerTagsList = createAction(GET_VOLUNTEER_TAGS_LIST);
export const getVolunteerTagsListError = createAction(
  GET_VOLUNTEER_TAGS_LIST_ERROR,
);
export const getVolunteerTagsListSuccess = createAction(
  GET_VOLUNTEER_TAGS_LIST_SUCCESS,
);
export const resetGetVolunteerTagsList = createAction(
  RESET_GET_VOLUNTEER_TAGS_LIST,
);
export const deleteVolunteerTagsAct = createAction(DELETE_VOLUNTEER_TAGS);
export const deleteVolunteerTagsError = createAction(
  DELETE_VOLUNTEER_TAGS_ERROR,
);
export const deleteVolunteerTagsSuccess = createAction(
  DELETE_VOLUNTEER_TAGS_SUCCESS,
);
export const updateVolunteerTagsAct = createAction(UPDATE_VOLUNTEER_TAGS);
export const updateVolunteerTagsError = createAction(
  UPDATE_VOLUNTEER_TAGS_ERROR,
);
export const updateVolunteerTagsSuccess = createAction(
  UPDATE_VOLUNTEER_TAGS_SUCCESS,
);
export const updateActionStatusAct = createAction(UPDATE_ACTION_STATUS);
export const createVolunteerTagsAct = createAction(CREATE_VOLUNTEER_TAGS);
export const createVolunteerTagsError = createAction(
  CREATE_VOLUNTEER_TAGS_ERROR,
);
export const createVolunteerTagsSuccess = createAction(
  CREATE_VOLUNTEER_TAGS_SUCCESS,
);
