/*
 * MemberTags Messages
 *
 * This contains all the text for the MemberTags component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.MemberTags';

export default defineMessages({
  createSuccess: {
    id: `${scope}.createSuccess`,
    defaultMessage: 'Tag is created successfully',
  },
  updateSuccess: {
    id: `${scope}.updateSuccess`,
    defaultMessage: 'Tag is updated successfully',
  },
  deleteSuccess: {
    id: `${scope}.deleteSuccess`,
    defaultMessage: 'Tag is deleted successfully',
  },
});
