/*
 *
 * NotificationList actions
 *
 */

import { createAction } from '@reduxjs/toolkit';
import {
  GET_NOTIFICATION_LIST,
  GET_NOTIFICATION_SUMMARY,
  IGNORE_NOTIFICATION_ITEM,
  RESET_NOTIFICATION,
} from './constants';

export const getNotificationAction = createAction(
  GET_NOTIFICATION_LIST,
  (loading, params, data) => ({
    payload: { loading, data, params },
  }),
);
export const getNotificationSummaryAction = createAction(
  GET_NOTIFICATION_SUMMARY,
  (loading, data) => ({
    payload: { loading, data },
  }),
);
export const ignoreNotificationAction = createAction(
  IGNORE_NOTIFICATION_ITEM,
  (loading, id, status) => ({
    payload: { loading, id, status },
  }),
);
export const resetNotificationAction = createAction(RESET_NOTIFICATION);
