/*
 * EventGroup Messages
 *
 * This contains all the text for the EventGroup component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.EventGroup';

export default defineMessages({
  active: {
    id: `${scope}.active`,
    defaultMessage: 'Active',
  },
  past: {
    id: `${scope}.past`,
    defaultMessage: 'Past',
  },
  all: {
    id: `${scope}.all`,
    defaultMessage: 'All',
  },
  noActiveEvent: {
    id: `${scope}.noActiveEvent`,
    defaultMessage: 'No active event group',
  },
  noPastEvent: {
    id: `${scope}.noPastEvent`,
    defaultMessage: 'No past event group',
  },
  team: {
    id: `${scope}.Teams`,
    defaultMessage: 'Teams',
  },
  supporter: {
    id: `${scope}.supporter`,
    defaultMessage: 'Supporters',
  },
  volunteers: {
    id: `${scope}.volunteers`,
    defaultMessage: 'Volunteers',
  },
  funds: {
    id: `${scope}.funds`,
    defaultMessage: 'Funds collected',
  },
  createGroup: {
    id: `${scope}.createGroup`,
    defaultMessage: 'Create group',
  },
  updateGroup: {
    id: `${scope}.updateGroup`,
    defaultMessage: 'Update group',
  },
  selectEvt: {
    id: `${scope}.selectEvt`,
    defaultMessage: 'Select event',
  },
  confirmGroupDelete: {
    id: `${scope}.confirmGroupDelete`,
    defaultMessage: 'Delete an event group',
  },
  deleteSuc: {
    id: `${scope}.deleteSuc`,
    defaultMessage: 'An event group is deleted successfully',
  },
  updateSuc: {
    id: `${scope}.updateSuc`,
    defaultMessage: 'An event group is updated successfully',
  },
  createSuc: {
    id: `${scope}.createSuc`,
    defaultMessage: 'An event group is created successfully',
  },
  walkers: {
    id: `${scope}.walkers`,
    defaultMessage: 'Participants',
  },
});
