/*
 * MemberDetail Messages
 *
 * This contains all the text for the MemberDetail component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.MemberDetail';

export default defineMessages({
  contact: {
    id: `${scope}.contact`,
    defaultMessage: 'Contact',
  },
  addInfo: {
    id: `${scope}.addInfo`,
    defaultMessage: 'Additional information',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email',
  },
  telephone: {
    id: `${scope}.telephone`,
    defaultMessage: 'Telephone',
  },
  age: {
    id: `${scope}.age`,
    defaultMessage: 'Age',
  },
  address: {
    id: `${scope}.address`,
    defaultMessage: 'Address',
  },
  address1: {
    id: `${scope}.address1`,
    defaultMessage: 'Additional address',
  },
  emContact: {
    id: `${scope}.emContact`,
    defaultMessage: 'Emergency contact',
  },
  postalCode: {
    id: `${scope}.postalCode`,
    defaultMessage: 'Postal code',
  },
  city: {
    id: `${scope}.city`,
    defaultMessage: 'City',
  },
  medCert: {
    id: `${scope}.medCert`,
    defaultMessage: 'Medical ceritificate',
  },
  parentConsent: {
    id: `${scope}.parentConsent`,
    defaultMessage: 'Parental consent',
  },
  mailOptin: {
    id: `${scope}.mailOptin`,
    defaultMessage: 'Consented for mail notification?',
  },
  source: {
    id: `${scope}.source`,
    defaultMessage: 'How did you find out about the Trailwalker?',
  },
  updateMedSuc: {
    id: `${scope}.updateMedSuc`,
    defaultMessage: 'Medical cerificate state is updated',
  },
  updateParentalConsentSuc: {
    id: `${scope}.updateParentalConsentSuc`,
    defaultMessage: 'Parental consent state is updated',
  },
  prevParticipation: {
    id: `${scope}.prevParticipation`,
    defaultMessage: 'Have you ever participated in the Trailwalker?',
  },
  tshirt: {
    id: `${scope}.tshirt`,
    defaultMessage: 'T-shirt size',
  },
  meal: {
    id: `${scope}.meal`,
    defaultMessage: 'Meal',
  },
  updateTshirtSuc: {
    id: `${scope}.updateTshirtSuc`,
    defaultMessage: 'Size of t-shirt is updated succefully',
  },
  tags: {
    id: `${scope}.tags`,
    defaultMessage: 'Tags',
  },
  time: {
    id: `${scope}.time`,
    defaultMessage: 'time',
  },
  times: {
    id: `${scope}.times`,
    defaultMessage: 'times',
  },
  bibNumber: {
    id: `${scope}.bibNumber`,
    defaultMessage: 'BIB',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Role',
  },
  registration: {
    id: `${scope}.registration`,
    defaultMessage: 'Registration date',
  },
  hostingForm: {
    id: `${scope}.hostingForm`,
    defaultMessage: 'Has filled hosting form?',
  },
  srchMember: {
    id: `${scope}.srchMember`,
    defaultMessage: 'Search member',
  },
  assignMeal: {
    id: `${scope}.assignMeal`,
    defaultMessage: 'Transfer',
  },
  noOptions: {
    id: `${scope}.noOptions`,
    defaultMessage: 'No member',
  },
  transferMealSuc: {
    id: `${scope}.transferMealSuc`,
    defaultMessage: 'Meal is transferred successfully',
  },
  deleteMealSuc: {
    id: `${scope}.deleteMealSuc`,
    defaultMessage: 'Meal is deleted successfully',
  },
  confirmMealDel: {
    id: `${scope}.confirmMealDel`,
    defaultMessage: 'Are you sure want to delete the meal?',
  },
});
