import { Circle } from '@mui/icons-material';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import React, { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';
import commonMessages from '../common/messages';

function NotificationIgnoreButton({ onClickHandler, loading }) {
  const confirm = useConfirm();
  const intl = useIntl();

  const { ignoreTitleTxt, ignoreTxt, cancelTxt, ignoreTooltipTxt } = useMemo(
    () => ({
      ignoreTitleTxt: intl.formatMessage(messages.confirm_ignore),
      ignoreTxt: intl.formatMessage(messages.ignore),
      cancelTxt: intl.formatMessage(commonMessages.cancel),
      ignoreTooltipTxt: intl.formatMessage(messages.ignoreTooltip),
    }),
    [intl],
  );

  const handleIgnore = async () => {
    try {
      await confirm({
        title: ignoreTitleTxt,
        confirmationText: ignoreTxt,
        cancellationText: cancelTxt,
      });
      onClickHandler();
    } catch (e) {
      console.error('User cancelled the confirmation', e);
    }
  };

  return (
    <Tooltip title={ignoreTooltipTxt}>
      <IconButton
        size="small"
        onClick={handleIgnore}
        disabled={loading}
        sx={{
          '&:hover': {
            borderColor: 'brandDarkGrey.main',
            borderWidth: 1,
            borderStyle: 'solid',
          },
          ml: 'auto',
        }}
      >
        {loading ? (
          <CircularProgress size={12} />
        ) : (
          <Circle color="primary" sx={{ fontSize: 8 }} />
        )}
      </IconButton>
    </Tooltip>
  );
}

export default memo(NotificationIgnoreButton);
