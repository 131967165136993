/*
 *
 * EventTeamDetail actions
 *
 */

import { createAction } from '@reduxjs/toolkit';

import {
  ASSIGN_USER,
  ASSIGN_USER_ERROR,
  ASSIGN_USER_SUCCESS,
  GET_TEAM_DETAIL,
  GET_TEAM_DETAIL_ERROR,
  GET_TEAM_DETAIL_SUCCESS,
  RESET_GET_TEAM_DETAIL,
  SET_TEAM_BIB_NUMBER,
  SET_TEAM_BIB_NUMBER_ERROR,
  SET_TEAM_BIB_NUMBER_SUCCESS,
  UPDATE_START_TIME,
  UPDATE_START_TIME_ERROR,
  UPDATE_START_TIME_SUCCESS,
  UPDATE_TEAM_STATUS,
  UPDATE_TEAM_STATUS_ERROR,
  UPDATE_TEAM_STATUS_SUCCESS,
  UPDATE_TAG_STATE,
  UPDATE_TAG_STATE_ERROR,
  UPDATE_TAG_STATE_SUCCESS,
  UPDATE_MEDICAL_CERT,
  UPDATE_MEDICAL_CERT_ERROR,
  UPDATE_MEDICAL_CERT_SUCCESS,
  UPDATE_PARENTAL_CONSENT,
  UPDATE_PARENTAL_CONSENT_ERROR,
  UPDATE_PARENTAL_CONSENT_SUCCESS,
  UPDATE_WELCOME_STATUS,
  UPDATE_WELCOME_STATUS_ERROR,
  UPDATE_WELCOME_STATUS_SUCCESS,
  ASSIGN_GROUP,
  ASSIGN_GROUP_ERROR,
  ASSIGN_GROUP_SUCCESS,
} from './constants';
export const getTeamDetail = createAction(GET_TEAM_DETAIL);
export const getTeamDetailError = createAction(GET_TEAM_DETAIL_ERROR);
export const getTeamDetailSuccess = createAction(GET_TEAM_DETAIL_SUCCESS);
export const setTeamBibNumber = createAction(SET_TEAM_BIB_NUMBER);
export const setTeamBibNumberError = createAction(SET_TEAM_BIB_NUMBER_ERROR);
export const setTeamBibNumberSuccess = createAction(
  SET_TEAM_BIB_NUMBER_SUCCESS,
);
export const updateTagState = createAction(UPDATE_TAG_STATE);
export const updateTagStateError = createAction(UPDATE_TAG_STATE_ERROR);
export const updateTagStateSuccess = createAction(UPDATE_TAG_STATE_SUCCESS);

export const updateStartTime = createAction(UPDATE_START_TIME);
export const updateStartTimeError = createAction(UPDATE_START_TIME_ERROR);
export const updateStartTimeSuccess = createAction(UPDATE_START_TIME_SUCCESS);

export const updateTeamStatus = createAction(UPDATE_TEAM_STATUS);
export const updateTeamStatusError = createAction(UPDATE_TEAM_STATUS_ERROR);
export const updateTeamStatusSuccess = createAction(UPDATE_TEAM_STATUS_SUCCESS);

export const updateMedCertAct = createAction(UPDATE_MEDICAL_CERT);
export const updateMedCertErrorAct = createAction(UPDATE_MEDICAL_CERT_ERROR);
export const updateMedCertSuccessAct = createAction(
  UPDATE_MEDICAL_CERT_SUCCESS,
);

export const updateParentalConsentAct = createAction(UPDATE_PARENTAL_CONSENT);
export const updateParentalConsentErrorAct = createAction(
  UPDATE_PARENTAL_CONSENT_ERROR,
);
export const updateParentalConsentSuccessAct = createAction(
  UPDATE_PARENTAL_CONSENT_SUCCESS,
);

export const updateWelcomeStatusAct = createAction(UPDATE_WELCOME_STATUS);
export const updateWelcomeStatusErrorAct = createAction(
  UPDATE_WELCOME_STATUS_ERROR,
);
export const updateWelcomeStatusSuccessAct = createAction(
  UPDATE_WELCOME_STATUS_SUCCESS,
);

export const assignUserAct = createAction(ASSIGN_USER);
export const assignUserErrorAct = createAction(ASSIGN_USER_ERROR);
export const assignUserSuccessAct = createAction(ASSIGN_USER_SUCCESS);

export const assignGroupAct = createAction(ASSIGN_GROUP);
export const assignGroupErrorAct = createAction(ASSIGN_GROUP_ERROR);
export const assignGroupSuccessAct = createAction(ASSIGN_GROUP_SUCCESS);

export const resetGetTeamDetail = createAction(RESET_GET_TEAM_DETAIL);
